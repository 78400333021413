@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.ncl-accordion {
  clear: both;
  margin-bottom: 0; }
  .ncl-accordion.__accordion-default {
    margin-bottom: 20px; }
    .ncl-accordion.__accordion-default .ncl-collapsible-content {
      padding: 10px 0px 0px 0px; }
  .ncl-accordion.__expanded svg.svg-inline--fa.fa-chevron-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .ncl-accordion.__form {
    margin-bottom: 20px; }
    .ncl-accordion.__form .ncl-collapsible-content {
      padding: 10px 0px 0px 0px; }
  .ncl-accordion .collapse {
    margin-top: 0; }
  .ncl-accordion-title {
    margin-bottom: 0px;
    font-size: 15px; }
    .ncl-accordion-title-arrow {
      font-size: 16px;
      margin-right: 10px; }
      .ncl-accordion-title-arrow svg.svg-inline--fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0;
        cursor: pointer; }
      .ncl-accordion-title-arrow i.fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0; }
  .ncl-accordion .ncl-accordion-title-with-link {
    -webkit-justify-content: space-between;
            justify-content: space-between;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
  .ncl-accordion .ncl-collapsible-content {
    padding: 10px 0px 20px 0px; }
    @media (max-width: 767px) {
      .ncl-accordion .ncl-collapsible-content {
        padding: 10px 0px; } }

.ncl-panel-accordion {
  clear: both;
  margin-bottom: 0;
  cursor: pointer; }
  .ncl-panel-accordion.__expanded .ncl-accordion-title-arrow svg.svg-inline--fa.fa-chevron-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .ncl-panel-accordion .ncl-accordion-title {
    padding: 10px 15px;
    height: 40px;
    background-color: #ccffff;
    background-color: #d1eae5;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0px;
    font-size: 18px;
    font-weight: 600;
    color: #555; }
    .ncl-panel-accordion .ncl-accordion-title-arrow {
      font-size: 16px;
      margin-right: 10px; }
      .ncl-panel-accordion .ncl-accordion-title-arrow svg.svg-inline--fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0;
        cursor: pointer; }
      .ncl-panel-accordion .ncl-accordion-title-arrow i.fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0;
        cursor: pointer; }
  .ncl-panel-accordion .collapse {
    margin-top: 0; }
  .ncl-panel-accordion .ncl-collapsible-content {
    padding: 20px 40px; }
    @media (max-width: 767px) {
      .ncl-panel-accordion .ncl-collapsible-content {
        padding: 10px 25px; } }

.ncl-filter-accordion .ncl-collapsible-content {
  padding: 0; }

.ncl-bordered-accordion, .ncl-bordered-accordion-sm {
  border-radius: 2px; }
  .ncl-bordered-accordion.__expanded .ncl-accordion-title-arrow svg.svg-inline--fa.fa-chevron-down, .__expanded.ncl-bordered-accordion-sm .ncl-accordion-title-arrow svg.svg-inline--fa.fa-chevron-down {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .ncl-bordered-accordion .ncl-accordion-title, .ncl-bordered-accordion-sm .ncl-accordion-title {
    height: 65px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-bottom: 0;
    padding: 10px 15px;
    background-color: #e9e9e9;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer; }
    .ncl-bordered-accordion .ncl-accordion-title-arrow, .ncl-bordered-accordion-sm .ncl-accordion-title-arrow {
      font-size: 16px;
      margin-right: 15px; }
      .ncl-bordered-accordion .ncl-accordion-title-arrow svg.svg-inline--fa, .ncl-bordered-accordion-sm .ncl-accordion-title-arrow svg.svg-inline--fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0;
        cursor: pointer; }
      .ncl-bordered-accordion .ncl-accordion-title-arrow i.fa, .ncl-bordered-accordion-sm .ncl-accordion-title-arrow i.fa {
        -webkit-transition: -webkit-transform 0.5s;
        transition: -webkit-transform 0.5s;
        transition: transform 0.5s;
        transition: transform 0.5s, -webkit-transform 0.5s;
        -webkit-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
        margin-right: 0; }
    .ncl-bordered-accordion .ncl-accordion-title-icon, .ncl-bordered-accordion-sm .ncl-accordion-title-icon {
      margin-right: 15px; }
  .ncl-bordered-accordion .collapse, .ncl-bordered-accordion-sm .collapse {
    margin-top: 0; }
  .ncl-bordered-accordion .ncl-collapsible-content, .ncl-bordered-accordion-sm .ncl-collapsible-content {
    padding: 20px 15px;
    border: 1px solid #ccc;
    border-top: none; }
    @media (max-width: 767px) {
      .ncl-bordered-accordion .ncl-collapsible-content, .ncl-bordered-accordion-sm .ncl-collapsible-content {
        padding: 10px 25px; } }

.ncl-bordered-accordion-sm .ncl-accordion-title {
  height: 50px;
  padding-left: 85px;
  font-size: 15px; }
  @media (max-width: 767px) {
    .ncl-bordered-accordion-sm .ncl-accordion-title {
      height: 35px;
      padding-left: 15px;
      font-size: 15px; } }

@media (min-width: 992px) {
  .ncl-accordion-group.__accordion-default {
    margin-bottom: 20px; } }

.ncl-accordion-group .ncl-accordion:not(:first-child) {
  margin-top: 5px;
  margin-bottom: 20px; }

.ncl-accordion-group .ncl-filter-accordion:not(:first-child) {
  margin-top: 5px;
  margin-bottom: 0px; }

.ncl-button.disabled-button:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

/* Button Colors*/
/* Font styles*/
/*Button Shape and Size*/
button:focus, button:active, button:hover {
  outline: none;
  box-shadow: none; }

.ncl-button-jump-to {
  font-size: 18px;
  height: 40px;
  width: 40px;
  border: none;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 50%;
  outline: none;
  background-color: #fff;
  color: #009999;
  border: 1px solid #009999;
  cursor: pointer;
  box-shadow: 0px 2px 5px #b5b5b5; }
  .ncl-button-jump-to svg.svg-inline--fa {
    font-size: 18px; }
  .ncl-button-jump-to.\--not-mock {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 99; }
    @media (max-width: 767px) {
      .ncl-button-jump-to.\--not-mock {
        bottom: 10px;
        right: 10px; } }
  .ncl-button-jump-to:hover {
    background-color: #fff;
    color: #009999;
    box-shadow: 0px 2px 5px #b5b5b5; }

.ncl-button {
  border-radius: 3px;
  font-size: 16px;
  /* DEVIATION :  As per markup this is 18px but did not look right */
  font-weight: 400;
  /* DEVIATION :  As per markup this is 600 but did not look right */
  height: 36px;
  line-height: 1;
  padding: 10px 30px;
  text-transform: uppercase;
  white-space: nowrap; }
  .ncl-button > i.fa:first-child {
    margin-right: 5px; }
  .ncl-button > i.fa:last-child {
    margin-left: 5px; }
  .ncl-button > svg.svg-inline--fa:first-child {
    margin-right: 5px; }
  .ncl-button > svg.svg-inline--fa:last-child {
    margin-left: 5px; }
  .ncl-button.__block {
    width: 100%; }
  @media (max-width: 991px) {
    .ncl-button {
      min-height: 44px;
      /* Added on 09 Sept as specified by Rebecca in the Review Video */ }
      .ncl-button.__block-sm {
        width: 100%; } }
  @media (max-width: 767px) {
    .ncl-button.__block-xs {
      width: 100%; } }
  @media (max-width: 767px) {
    .ncl-button.__squared {
      width: 50px;
      padding-left: 0;
      padding-right: 0; }
      .ncl-button.__squared i.fa {
        margin-left: 0;
        margin-right: 0; } }
  .ncl-button.primary-button {
    background-color: #006699;
    border: none;
    color: #ffffff; }
    @media (min-width: 992px) {
      .ncl-button.primary-button:hover {
        background-color: #015782; } }
  .ncl-button.secondary-button {
    background-color: #00cccc;
    border: none;
    color: #ffffff; }
    @media (min-width: 992px) {
      .ncl-button.secondary-button:hover {
        background-color: #00b6b6; } }
  .ncl-button.outline-button-right {
    border-radius: 3px;
    padding: 6px 20px;
    /* DEVIATION :  As per markup this is 12px but did not look right */
    color: #009999;
    border: 1px solid #009999;
    background: #fff; }
    .ncl-button.outline-button-right:hover {
      background-color: #f0eeee; }
  .ncl-button.outline-button {
    color: #009999;
    font-size: 16px;
    border: 1px solid #009999;
    background: #fff;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .ncl-button.outline-button:hover {
        background-color: #f0eeee !important; } }
  .ncl-button.outline-icon-button {
    color: #009999;
    font-size: 16px;
    border: 1px solid #009999;
    background: #fff;
    text-transform: uppercase;
    padding: 0;
    width: 50px; }
    .ncl-button.outline-icon-button i {
      margin-right: 0; }
  .ncl-button.secondary-outline-button {
    color: #009999;
    font-size: 16px;
    border: 1px solid #009999;
    background: #ccffff;
    text-transform: uppercase; }
    @media (min-width: 992px) {
      .ncl-button.secondary-outline-button:hover {
        background-color: #89ecea !important; } }
  .ncl-button.outline-button-in-list {
    color: #009999;
    font-size: 15px;
    border: 1px solid #009999;
    background: #fff;
    text-transform: uppercase;
    height: 30px;
    padding: 5px 15px; }
    @media (min-width: 992px) {
      .ncl-button.outline-button-in-list:hover {
        background-color: #f0eeee !important; } }
    @media (max-width: 991px) {
      .ncl-button.outline-button-in-list {
        min-height: 30px; } }
  .ncl-button.disabled-button {
    color: #ffffff;
    font-size: 16px;
    font-weight: 400;
    border: none;
    background: #e9e9e9; }
    @media (max-width: 767px) {
      .ncl-button.disabled-button {
        height: 44px; } }
    @media (min-width: 992px) {
      .ncl-button.disabled-button:hover {
        background-color: #e0dfdf; } }
  .ncl-button.punch-out-button {
    font-size: 15px;
    border: none;
    color: #555;
    text-transform: uppercase;
    background-color: #bddfbe;
    font-weight: 600; }
  .ncl-button.clear-filter-button {
    width: 100%;
    color: #009999;
    font-size: 15px;
    border: 1px solid #009999;
    background: #fff;
    font-weight: 400;
    font-size: 14px;
    color: #009999;
    height: 25px;
    min-height: 25px;
    padding: 0;
    border-radius: 3px; }

.link-button {
  border: none;
  background: none;
  color: #009999;
  font-weight: 400; }

.secondary-link-button {
  color: #757575;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 0px;
  text-transform: capitalize;
  height: inherit;
  min-height: inherit; }
  @media (max-width: 767px) {
    .secondary-link-button {
      padding: 0px; } }
  @media (min-width: 992px) {
    .secondary-link-button:hover {
      color: #333333; } }

.disabled-link {
  color: #757575;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 10px 0px 10px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .disabled-link {
      padding: 0px; } }
  .disabled-link:hover {
    cursor: no-drop; }

.primary-link-button {
  color: #009999;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 0px;
  text-transform: capitalize;
  height: inherit;
  min-height: inherit; }
  @media (max-width: 767px) {
    .primary-link-button {
      padding: 0; } }
  @media (min-width: 992px) {
    .primary-link-button:hover {
      color: #0c4f48; } }

.special-outline-button {
  line-height: normal;
  line-height: initial;
  border-radius: 3px;
  height: 30px;
  padding-top: 6px 20px;
  /* DEVIATION :  As per markup this is 12px but did not look right */
  color: #009999;
  font-size: 14px;
  /* DEVIATION : As per markup this is 18px but that looked too big */
  font-weight: 400;
  border: 1px solid #009999;
  background: none; }
  .special-outline-button :hover {
    background-color: #f0eeee; }

.outline-fa-button {
  border-radius: 3px;
  padding: 6px 15px;
  /* DEVIATION :  As per markup this is 12px but did not look right */
  color: #009999;
  font-size: 16px;
  /* DEVIATION : As per markup this is 18px but that looked too big */
  font-weight: 400;
  border: 1px solid #009999;
  background: none; }
  .outline-fa-button .fa {
    margin-right: 0; }
  @media (max-width: 991px) {
    .outline-fa-button {
      min-height: 44px;
      /* Added on 09 Sept as specified by Rebecca in the Review Video */ } }
  .outline-fa-button:hover {
    background-color: #f0eeee; }

.inpage-back-list {
  background-color: #fff;
  border: none;
  color: #009999;
  padding: 20px 0px 0px 0px;
  min-width: 247px;
  font-size: 15px;
  font-weight: 400;
  text-align: left; }
  @media (max-width: 767px) {
    .inpage-back-list {
      padding-bottom: 10px;
      /* DEVIATION : To make this look uniform with other elements */ } }
  @media (max-width: 991px) {
    .inpage-back-list {
      height: 44px; } }
  @media (min-width: 992px) {
    .inpage-back-list {
      padding: 0; } }
  .inpage-back-list:hover {
    color: #0c4f48; }

.inpage-back-list-ah {
  background-color: #fff;
  border: none;
  color: #009999;
  padding: 0px 0px 0px 0px;
  font-size: 15px;
  font-weight: 400;
  text-align: left; }
  @media (max-width: 767px) {
    .inpage-back-list-ah {
      padding-bottom: 10px;
      /* DEVIATION : To make this look uniform with other elements */ } }
  @media (min-width: 992px) {
    .inpage-back-list-ah {
      padding: 0; } }
  .inpage-back-list-ah:hover {
    color: #0c4f48; }

@media (max-width: 991px) {
  .ncl-navigation-button-family .primary-group {
    float: left; } }

@media (max-width: 991px) {
  .ncl-navigation-button-family .secondary-group {
    float: right; } }

@media (min-width: 992px) {
  .ncl-navigation-button-family .secondary-group button:last-child {
    margin-right: 0;
    border-right: 0;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0; }
  .ncl-navigation-button-family .secondary-group button:first-child {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0; } }

.ncl-navigation-button-family button {
  float: right;
  margin-right: 10px; }
  .ncl-navigation-button-family button:first-child {
    margin-right: 0; }

.ncl-button-group button {
  margin-right: 15px; }
  @media (max-width: 767px) {
    .ncl-button-group button {
      margin-right: 0;
      margin-bottom: 20px; } }
  .ncl-button-group button:last-child {
    margin-right: 0; }

.ncl-link-group button {
  margin-right: 25px; }
  @media (max-width: 767px) {
    .ncl-link-group button {
      margin-right: 0;
      margin-bottom: 20px; } }
  .ncl-link-group button:last-child {
    margin-right: 0; }

.ncl-button-stack button {
  margin-bottom: 10px; }
  .ncl-button-stack button:last-child {
    margin-bottom: 0; }

.ncl-btn-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-btn-group button.ncl-button {
    border-left: 1px solid;
    border-right: none;
    border-radius: 0;
    margin-left: 0; }
    .ncl-btn-group button.ncl-button:first-child {
      border-left: 1px solid;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      border-right: none; }
    .ncl-btn-group button.ncl-button:last-child {
      border-right: 1px solid;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px; }
  .ncl-btn-group.__btn-grp-right {
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }

.ncl-button-toolbar-link-group {
  display: -webkit-flex;
  display: flex; }
  @media (min-width: 768px) {
    .ncl-button-toolbar-link-group button.ncl-button {
      margin-left: 25px; } }
  @media (max-width: 767px) {
    .ncl-button-toolbar-link-group button.ncl-button:not(:first-child) {
      margin-top: 0px;
      margin-left: 25px; } }

.ncl-button-toolbar {
  display: -webkit-flex;
  display: flex; }
  .ncl-button-toolbar._hr {
    padding-top: 20px;
    border-top: 1px solid #ccc; }
  @media (max-width: 991px) {
    .ncl-button-toolbar._spaced {
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
  .ncl-button-toolbar._centered {
    -webkit-justify-content: center;
            justify-content: center; }
  @media (max-width: 767px) {
    .ncl-button-toolbar._column {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .ncl-button-toolbar._column > button:not(:first-child),
      .ncl-button-toolbar._column > .ncl-btn-group:not(:first-child) {
        margin-top: 10px;
        margin-left: 0px; }
      .ncl-button-toolbar._column > .ncl-button-toolbar-link-group {
        -webkit-justify-content: center;
                justify-content: center; }
        .ncl-button-toolbar._column > .ncl-button-toolbar-link-group:not(:first-child) {
          margin-top: 20px;
          margin-left: 0px; } }
  .ncl-button-toolbar > button:not(:first-child),
  .ncl-button-toolbar > .ncl-btn-group:not(:first-child) {
    margin-left: 15px; }

.ncl-tag, .ncl-tag-intable {
  border-radius: 15px;
  height: 30px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px 15px;
  color: #fff;
  font-size: 14px;
  min-width: 160px; }
  .ncl-tag-green {
    background-color: #339966; }
  .ncl-tag-teal {
    background-color: #009999; }
  .ncl-tag-light-green {
    background-color: #eff7e8;
    color: #555; }
  .ncl-tag-orange {
    background-color: #ed6b4e; }
  .ncl-tag-red {
    background-color: #d44646; }
  .ncl-tag-completed {
    background-color: #0c4f48; }
  .ncl-tag-warning {
    background-color: #0c4f48; }
  .ncl-tag-good {
    background-color: #0c4f48; }
  .ncl-tag-bad {
    background-color: #0c4f48; }

.ncl-tag-intable {
  height: 25px;
  min-width: inherit; }

.ncl-badge {
  display: -webkit-inline-flex;
  display: inline-flex;
  min-width: 20px;
  height: 20px;
  padding: 0px 6px;
  font-size: 14px;
  line-height: 1;
  color: #fff;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  white-space: nowrap;
  vertical-align: middle;
  border-radius: 10px; }
  .ncl-badge.teal-badge {
    background-color: #009999; }
  .ncl-badge.orange-badge {
    background-color: #fb6b5b; }
    .ncl-badge.orange-badge-sm {
      background-color: #fb6b5b;
      min-width: 16px;
      height: 16px;
      padding: 0;
      font-size: 12px; }
  .ncl-badge.status-badge {
    color: #555;
    font-size: 14px;
    padding: 7px 10px;
    border-radius: 3px;
    height: 28px;
    text-align: center; }
    .ncl-badge.status-badge.active-badge {
      background-color: #ccffcc; }
    .ncl-badge.status-badge.inactive-badge, .ncl-badge.status-badge.onleave-badge {
      background-color: #ccc; }
    .ncl-badge.status-badge.inprogress-badge, .ncl-badge.status-badge.transferred-badge {
      background-color: #ffc; }
    .ncl-badge.status-badge.terminated-badge, .ncl-badge.status-badge.retired-badge, .ncl-badge.status-badge.deceased-badge {
      background-color: #fdb5ad; }
    .ncl-badge.status-badge.high-badge {
      background-color: #d44646;
      color: #fff; }
    .ncl-badge.status-badge.low-badge {
      background-color: #ffe44d;
      color: #807226; }
    .ncl-badge.status-badge.medium-badge {
      background-color: #ff9a36;
      color: #fff; }

.ncl-overlay-trigger .ncl-overlay-trigger-target {
  display: -webkit-inline-flex;
  display: inline-flex; }
  .ncl-overlay-trigger .ncl-overlay-trigger-target svg.svg-inline--fa {
    font-size: 15px; }
    @media (max-width: 991px) {
      .ncl-overlay-trigger .ncl-overlay-trigger-target svg.svg-inline--fa {
        font-size: 18px; } }

.ncl-tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  line-height: 1.2;
  text-align: left;
  opacity: 0;
  color: #fff;
  padding: 5px 0; }
  .ncl-tooltip .ncl-tooltip-in {
    opacity: 1; }
  .ncl-tooltip .ncl-tooltip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  .ncl-tooltip .ncl-tooltip-inner {
    background-color: #555;
    box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.35);
    font-size: 14px;
    text-align: inherit;
    border-radius: 2.5px;
    padding: 10px;
    line-height: inherit;
    max-width: 200px;
    text-align: center; }
  .ncl-tooltip .ncl-tooltip-close-icon {
    position: absolute;
    top: 15px;
    right: 10px; }
    .ncl-tooltip .ncl-tooltip-close-icon:hover {
      cursor: pointer; }
  .ncl-tooltip.bottom {
    margin-top: 3px; }
    .ncl-tooltip.bottom .ncl-tooltip-arrow {
      top: 0;
      border-width: 0 5px 5px;
      border-bottom-color: #555; }
  .ncl-tooltip.top {
    margin-top: -3px; }
    @media (max-width: 991px) {
      .ncl-tooltip.top.hover {
        cursor: none; } }
    .ncl-tooltip.top .ncl-tooltip-arrow {
      bottom: 0;
      border-width: 5px 5px 0;
      border-top-color: #555; }

.ncl-tooltip.bottom .ncl-tooltip-arrow, .ncl-tooltip.top .ncl-tooltip-arrow {
  left: 50%;
  margin-left: -5px; }

.ncl-inlist-section:last-child {
  border-bottom: 1px solid #ccc; }

.ncl-inlist-section .ncl-inlist-section-title {
  padding: 20px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  border-top: 1px solid #ccc; }
  .ncl-inlist-section .ncl-inlist-section-title .ncl-collapsable-icons {
    padding-right: 20px;
    display: -webkit-flex;
    display: flex; }
    .ncl-inlist-section .ncl-inlist-section-title .ncl-collapsable-icons .ncl-collapsible-icon:not(:first-child) {
      margin-left: 35px; }
    .ncl-inlist-section .ncl-inlist-section-title .ncl-collapsable-icons a.ncl-collapsible-icon {
      position: relative; }
      .ncl-inlist-section .ncl-inlist-section-title .ncl-collapsable-icons a.ncl-collapsible-icon .list-page-edit-section a .svg-inline--fa {
        color: #7b7b7b;
        font-size: 15px; }
    .ncl-inlist-section .ncl-inlist-section-title .ncl-collapsable-icons .ncl-list-section-arrow {
      position: absolute;
      margin-left: -5px;
      bottom: -29px;
      left: 0px;
      margin-left: -5px;
      border-style: solid;
      border-width: 10px 10px 0;
      border-top-color: #fff;
      /* color: #FFF; */
      background-color: transparent;
      color: transparent; }

.ncl-inlist-section .ncl-collapsible-content {
  padding: 20px;
  background-color: #e9e9e9;
  box-shadow: inset 0 3px 4px #dcdcdc; }
  @media (max-width: 767px) {
    .ncl-inlist-section .ncl-collapsible-content {
      padding: 15px; } }
  .ncl-inlist-section .ncl-collapsible-content .ncl-form-tooltip {
    margin-bottom: 20px; }
    .ncl-inlist-section .ncl-collapsible-content .ncl-form-tooltip .ncl-well {
      background-color: #fff; }

.ncl-inlist-section.__expanded {
  border-bottom: none; }
  .ncl-inlist-section.__expanded .ncl-collapsable-icons a.ncl-collapsible-icon .list-page-edit-section a .svg-inline--fa.fa-pencil-alt {
    color: #ccc; }

.ncl-dropdown-options-mobile .ncl-options-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-dropdown-options-mobile .ncl-options-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-dropdown-options-mobile .ncl-options-header .ncl-close-icon {
        left: 10px; } }
  .ncl-dropdown-options-mobile .ncl-options-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-dropdown-options-mobile .ncl-options-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-dropdown-options-mobile .ncl-options-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-dropdown-options-mobile .ncl-options-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-dropdown-options-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  min-height: 100vh;
  z-index: 2000; }
  .ncl-dropdown-options-mobile .ncl-dual-dropdown-back-link {
    padding: 0 20px;
    padding-top: 20px;
    font-size: 18px; }
    @media (max-width: 991px) {
      .ncl-dropdown-options-mobile .ncl-dual-dropdown-back-link {
        padding: 0 20px;
        padding-top: 20px; } }
    @media (max-width: 767px) {
      .ncl-dropdown-options-mobile .ncl-dual-dropdown-back-link {
        padding: 0 10px;
        padding-top: 15px; } }
  .ncl-dropdown-options-mobile .ncl-dual-dropdown-inputname {
    font-size: 18px;
    color: #555;
    padding: 20px; }
    @media (max-width: 991px) {
      .ncl-dropdown-options-mobile .ncl-dual-dropdown-inputname {
        padding: 20px; } }
    @media (max-width: 767px) {
      .ncl-dropdown-options-mobile .ncl-dual-dropdown-inputname {
        padding: 20px 10px; } }
  .ncl-dropdown-options-mobile .ncl-dropdown-search-field {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 10px; }
    .ncl-dropdown-options-mobile .ncl-dropdown-search-field input {
      height: 44px;
      font-weight: 400;
      width: 100%; }
      .ncl-dropdown-options-mobile .ncl-dropdown-search-field input:focus {
        box-shadow: none !important;
        border-color: #999; }
    @media (max-width: 767px) {
      .ncl-dropdown-options-mobile .ncl-dropdown-search-field {
        padding-left: 10px;
        padding-right: 10px; } }
  .ncl-dropdown-options-mobile .ncl-dropdown-mobile-options-footer {
    position: fixed;
    padding: 15px 10px;
    height: 82px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    box-shadow: 0 -2px 20px 2px #e9e9e9;
    bottom: 0;
    left: 0;
    right: 0; }
    .ncl-dropdown-options-mobile .ncl-dropdown-mobile-options-footer .ncl-button {
      min-height: 40px; }
      @media (max-width: 767px) {
        .ncl-dropdown-options-mobile .ncl-dropdown-mobile-options-footer .ncl-button {
          width: 100%; } }

.ncl-select-container > div.ncl-dropdown-selected-button.ncl-dropdown-disabled:hover, .ncl-dropdown-overlay .ncl-dropdown-search-field.disabled-field {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-select-container > div.ncl-dropdown-selected-button.ncl-dropdown-disabled:hover, .ncl-dropdown-overlay .ncl-dropdown-search-field.disabled-field {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-select-container.__custom-field > button.ncl-dropdown-selected-button, .ncl-select-container > div.ncl-dropdown-selected-button, .ncl-select-container > div.ncl-dropdown-global-nav-company {
  background-color: #fff;
  border: 1px solid #999;
  color: #555;
  font-size: 15px;
  padding: 0px 12px;
  border-radius: 0px;
  height: 32px;
  width: 100%;
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .ncl-select-container.__custom-field > button.ncl-dropdown-selected-button .fa.fa-angle-up, .ncl-select-container > div.ncl-dropdown-selected-button .fa.fa-angle-up, .ncl-select-container > div.ncl-dropdown-global-nav-company .fa.fa-angle-up, .ncl-select-container.__custom-field > button.ncl-dropdown-selected-button .fa.fa-angle-down, .ncl-select-container > div.ncl-dropdown-selected-button .fa.fa-angle-down, .ncl-select-container > div.ncl-dropdown-global-nav-company .fa.fa-angle-down {
    font-size: 25px; }
  .ncl-select-container.__custom-field > button.ncl-dropdown-selected-button:hover, .ncl-select-container > div.ncl-dropdown-selected-button:hover, .ncl-select-container > div.ncl-dropdown-global-nav-company:hover {
    cursor: pointer; }

.ncl-select-container > div.ncl-dropdown-selected-button.ncl-dropdown-disabled {
  background-color: #e9e9e9;
  color: #999;
  border: 1px solid #ccc; }

.ncl-dropdown-options .ncl-dropdown-option .ncl-custom-field-icon, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-custom-field-icon, .ncl-custom-field-dropdown .ncl-custom-field-icon {
  background-color: #e9e9e9;
  padding: 5px;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  margin-right: 12px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  font-weight: 600;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  .ncl-dropdown-options .ncl-dropdown-option .ncl-custom-field-icon svg.svg-inline--fa, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-custom-field-icon svg.svg-inline--fa, .ncl-custom-field-dropdown .ncl-custom-field-icon svg.svg-inline--fa {
    margin-right: 0;
    color: #fff; }
  .ncl-dropdown-options .ncl-dropdown-option .__inactive.ncl-custom-field-icon, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .__inactive.ncl-custom-field-icon, .ncl-custom-field-dropdown .__inactive.ncl-custom-field-icon {
    background-color: #ccc; }

.ncl-select-container {
  position: relative;
  background-color: #fff; }
  .ncl-select-container.__default {
    margin-bottom: 20px; }
  .ncl-select-container .ncl-dropdown-no-data-section {
    background-color: #e9e9e9;
    font-style: italic;
    color: #777;
    font-size: 15px;
    height: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px 12px;
    width: 300px;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (max-width: 991px) {
      .ncl-select-container .ncl-dropdown-no-data-section {
        font-size: 16px;
        height: 44px; } }
    @media (max-width: 767px) {
      .ncl-select-container .ncl-dropdown-no-data-section {
        height: 44px; } }
  .ncl-select-container.__filter {
    width: 100%; }
  .ncl-select-container.__standard {
    width: 300px; }
    @media (max-width: 767px) {
      .ncl-select-container.__standard {
        width: 100%; } }
  .ncl-select-container.__tiny {
    width: 50px; }
  .ncl-select-container.__x-small {
    width: 75px; }
  .ncl-select-container.__small {
    width: 140px; }
    @media (max-width: 767px) {
      .ncl-select-container.__small {
        width: 100%; } }
  .ncl-select-container.__medium {
    width: 215px; }
    @media (max-width: 767px) {
      .ncl-select-container.__medium {
        width: 100%; } }
  .ncl-select-container.__large {
    width: 350px; }
    @media (max-width: 767px) {
      .ncl-select-container.__large {
        width: 100%; } }
  .ncl-select-container.__custom-field > button.ncl-dropdown-selected-button {
    height: 50px; }
  @media (max-width: 991px) {
    .ncl-select-container > div.ncl-dropdown-selected-button {
      padding: 0px 10px;
      height: 44px; } }
  .ncl-select-container > div.ncl-dropdown-selected-button.ncl-dropdown-selected-description-button {
    height: 50px; }
  .ncl-select-container > div.ncl-dropdown-global-nav-company {
    height: 50px;
    border: none; }
  @media (min-width: 992px) {
    .ncl-select-container .ncl-dropdown-selected-value .ncl-dropdown-selected-value-description {
      font-size: 12px;
      color: #999;
      text-transform: uppercase; }
    .ncl-select-container .ncl-dropdown-selected-badge-arrow {
      margin-left: 10px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-select-container .ncl-dropdown-selected-badge-arrow svg.svg-inline--fa {
        font-size: 16px; }
      .ncl-select-container .ncl-dropdown-selected-badge-arrow .ncl-badge {
        margin-right: 10px; } }
  @media (max-width: 991px) {
    .ncl-select-container > div.ncl-dropdown-selected-button {
      background-color: #fff;
      text-align: left; }
      .ncl-select-container > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value {
        font-size: 18px; }
        .ncl-select-container > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value .ncl-dropdown-selected-value-description {
          font-size: 16px;
          color: #999;
          text-transform: uppercase; }
      .ncl-select-container > div.ncl-dropdown-selected-button .ncl-dropdown-selected-badge-arrow {
        margin-left: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .ncl-select-container > div.ncl-dropdown-selected-button .ncl-dropdown-selected-badge-arrow svg.svg-inline--fa {
          font-size: 16px; }
        .ncl-select-container > div.ncl-dropdown-selected-button .ncl-dropdown-selected-badge-arrow .ncl-badge {
          margin-right: 10px; } }

/* Written for single select and contains hover*/
.ncl-dropdown-options, .ncl-dropdown-navigation-multiselect {
  color: #555;
  list-style-type: none;
  padding: 0; }
  @media (max-width: 991px) {
    .ncl-dropdown-options, .ncl-dropdown-navigation-multiselect {
      padding-top: 0px; } }
  .ncl-dropdown-options .ncl-dropdown-option, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option {
    font-size: 15px;
    padding: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-dropdown-options .ncl-dropdown-option .svg-inline--fa.fa-check-square, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.fa-check-square {
      color: #009999; }
    .ncl-dropdown-options .ncl-dropdown-option .svg-inline--fa.fa-square, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.fa-square {
      color: #7b7b7b; }
    .ncl-dropdown-options .ncl-dropdown-option .svg-inline--fa, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa {
      margin-right: 10px; }
      .ncl-dropdown-options .ncl-dropdown-option .svg-inline--fa.__disabled, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.__disabled {
        color: #ccc; }
      @media (max-width: 991px) {
        .ncl-dropdown-options .ncl-dropdown-option .svg-inline--fa, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa {
          font-size: 18px; } }
    @media (max-width: 991px) {
      .ncl-dropdown-options .ncl-dropdown-option, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option {
        padding: 10px 20px;
        min-height: 44px;
        font-size: 16px; }
        .ncl-dropdown-options .ncl-dropdown-option:not(:last-child), .ncl-dropdown-navigation-multiselect .ncl-dropdown-option:not(:last-child) {
          border-bottom: 1px solid #e9e9e9; } }
    @media (max-width: 767px) {
      .ncl-dropdown-options .ncl-dropdown-option, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option {
        padding: 10px; } }
    @media (max-width: 991px) {
      .ncl-dropdown-options .ncl-dropdown-option.selected, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option.selected {
        background-color: #ccffff;
        font-weight: 600; } }
    .ncl-dropdown-options .ncl-dropdown-option .ncl-dropdown-option-label, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label {
      display: -webkit-flex;
      display: flex;
      margin: 0px;
      font-weight: inherit; }
      .ncl-dropdown-options .ncl-dropdown-option .ncl-dropdown-option-label i.fa, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label i.fa {
        margin-right: 5px; }
      .ncl-dropdown-options .ncl-dropdown-option .ncl-dropdown-option-label:hover, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label:hover {
        cursor: pointer; }
    .ncl-dropdown-options .ncl-dropdown-option.description-option .ncl-dropdown-option-label, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option.description-option .ncl-dropdown-option-label {
      -webkit-flex-direction: column;
              flex-direction: column; }
      .ncl-dropdown-options .ncl-dropdown-option.description-option .ncl-dropdown-option-label .ncl-dropdown-option-description, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option.description-option .ncl-dropdown-option-label .ncl-dropdown-option-description {
        color: #999;
        font-size: 12px;
        text-transform: uppercase; }
    @media (min-width: 992px) {
      .ncl-dropdown-options .ncl-dropdown-option:hover, .ncl-dropdown-navigation-multiselect .ncl-dropdown-option:hover {
        background-color: #f1f1f1;
        cursor: pointer; } }

/* Written for multi-select and without hover*/
.ncl-dropdown-navigation-multiselect {
  margin-bottom: 0; }
  .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.fa-check-square {
    color: #009999; }
  .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.fa-square {
    color: #7b7b7b; }
  .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa {
    margin-right: 10px; }
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .svg-inline--fa.__disabled {
      color: #ccc; }
  @media (min-width: 992px) {
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option.selected {
      background-color: #fff; }
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option:hover {
      background-color: #f1f1f1;
      cursor: pointer; } }
  @media (max-width: 991px) {
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option.selected {
      background-color: #ccffff;
      font-weight: 600; } }
  .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label {
    display: -webkit-flex;
    display: flex;
    margin: 0px;
    font-weight: inherit;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label i.fa {
      color: #7b7b7b; }
    .ncl-dropdown-navigation-multiselect .ncl-dropdown-option .ncl-dropdown-option-label:hover {
      cursor: pointer; }

.ncl-dropdown-overlay {
  position: absolute;
  background-color: #fff;
  box-shadow: 0 3px 13px 0px rgba(0, 0, 0, 0.25);
  border: 1px solid #999;
  border-radius: 0;
  margin-left: 0;
  margin-top: 5px;
  z-index: 10;
  width: 100%; }
  .ncl-dropdown-overlay .ncl-dropdown-search-field {
    padding: 10px; }
    .ncl-dropdown-overlay .ncl-dropdown-search-field .ncl-form-control {
      width: 100%; }
    .ncl-dropdown-overlay .ncl-dropdown-search-field input:focus {
      border-color: #999; }

.ncl-custom-field-dropdown button {
  border-radius: 3px;
  height: inherit;
  padding: 5px;
  padding-right: 12px; }

.ncl-global-navigation {
  position: relative; }
  .ncl-global-navigation .ncl-global-navigation-top-level {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    @media (max-width: 767px) {
      .ncl-global-navigation .ncl-global-navigation-top-level {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-wrap: nowrap;
              flex-wrap: nowrap;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        @media (min-width: 768px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons {
            margin-left: 12.5px; } }
        @media (max-width: 767px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons {
            border-top: 1px solid #ccc;
            -webkit-justify-content: space-between;
                    justify-content: space-between;
            -webkit-flex-basis: 100%;
                    flex-basis: 100%;
            padding-left: 15px;
            height: 50px; } }
        .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons .ncl-global-navigation-icon {
          font-size: 25px;
          margin-right: 25px;
          position: relative; }
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons .ncl-global-navigation-icon:last-child {
            margin-right: 12px; }
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons .ncl-global-navigation-icon.__user-icon {
            border-radius: 50%;
            border: 1px solid grey; }
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons .ncl-global-navigation-icon .ncl-badge {
            position: absolute;
            left: 8px;
            top: -2px; }
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-icons .ncl-global-navigation-icon:hover {
            cursor: pointer; }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item:first-child {
        -webkit-flex-grow: 1;
                flex-grow: 1; }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-company {
        height: 50px;
        border-right: 1px solid #ccc;
        -webkit-flex-grow: 1;
                flex-grow: 1; }
        @media (max-width: 767px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-company {
            -webkit-flex-basis: 50%;
                    flex-basis: 50%;
            -webkit-flex-shrink: 0;
                    flex-shrink: 0; } }
        @media (min-width: 768px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-company {
            max-width: 300px;
            width: inherit; } }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-role {
        -webkit-flex-grow: 1;
                flex-grow: 1; }
        @media (min-width: 768px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-role {
            border-right: 1px solid #ccc;
            max-width: 300px;
            width: inherit; } }
        @media (max-width: 767px) {
          .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-role {
            -webkit-flex-basis: 50%;
                    flex-basis: 50%;
            -webkit-flex-shrink: 0;
                    flex-shrink: 0; } }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-role {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        height: 50px; }
        .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-global-navigation-role .ncl-global-navigation-previous-login {
          color: #999;
          font-size: 12px;
          padding: 0 15px; }
      .ncl-global-navigation .ncl-global-navigation-top-level .ncl-global-navigation-top-level-item .ncl-navbar-list .ncl-navbar-item {
        white-space: nowrap; }
  .ncl-global-navigation .ncl-global-navigation-main {
    position: relative;
    background-color: #0c4f48;
    color: white;
    height: 93px; }
    @media (max-width: 767px) {
      .ncl-global-navigation .ncl-global-navigation-main {
        height: 50px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        padding-left: 60px;
        padding-right: 48px; } }
    @media (min-width: 768px) {
      .ncl-global-navigation .ncl-global-navigation-main {
        padding-left: 65px;
        padding-top: 6px; } }
    @media (min-width: 992px) {
      .ncl-global-navigation .ncl-global-navigation-main {
        padding-left: 102px; } }
    .ncl-global-navigation .ncl-global-navigation-main .ncl-brand-logo {
      position: absolute;
      left: 0;
      width: 87px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center;
      height: 51px;
      -webkit-justify-content: center;
              justify-content: center; }
      @media (min-width: 768px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-brand-logo {
          border-right: 1px solid rgba(255, 255, 255, 0.25); } }
      @media (max-width: 991px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-brand-logo {
          width: 50px; } }
      @media (max-width: 767px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-brand-logo {
          width: 60px; } }
    .ncl-global-navigation .ncl-global-navigation-main .ncl-globalnav-page-title {
      font-size: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      @media (max-width: 767px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-globalnav-page-title {
          font-size: 20px; } }
      @media (min-width: 768px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-globalnav-page-title {
          height: 51px; } }
    .ncl-global-navigation .ncl-global-navigation-main .ncl-global-navigation-help {
      color: #71d769;
      position: absolute;
      right: 15px;
      font-size: 15px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-global-navigation .ncl-global-navigation-main .ncl-global-navigation-help:hover {
        cursor: pointer; }
      @media (max-width: 767px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-global-navigation-help {
          font-size: 18px;
          top: 0;
          bottom: 0; } }
      @media (min-width: 768px) {
        .ncl-global-navigation .ncl-global-navigation-main .ncl-global-navigation-help {
          top: 6px;
          height: 51px; } }
    .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links {
      display: -webkit-flex;
      display: flex;
      position: absolute;
      bottom: 0; }
      .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link {
        margin-right: 40px;
        border-bottom: 4px solid #0c4f48; }
        .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link:not(.__more):hover {
          cursor: pointer;
          color: #9c9;
          border-bottom: 4px solid #9c9; }
        .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__more {
          color: #fff;
          padding: 6px 12px;
          border-bottom: 0; }
          .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__more:hover {
            color: #9c9; }
        .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__tablet-select-target {
          position: relative;
          width: 245px;
          color: #fff;
          border-bottom: 5px solid #0c4f48; }
          .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__tablet-select-target:hover {
            color: #fff;
            border-bottom: 5px solid #0c4f48; }
          .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__tablet-select-target svg.svg-inline--fa.fa-chevron-down, .ncl-global-navigation .ncl-global-navigation-main .nc-global-nav-links .nc-global-nav-link.__tablet-select-target svg.svg-inline--fa.fa-chevron-up {
            position: absolute;
            right: 12px; }

.ncl-global-nav-drawer-opener {
  width: 87px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  background-color: #e9e9e9;
  font-size: 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  padding-top: 35px;
  font-size: 36px;
  color: #777; }
  @media (max-width: 991px) {
    .ncl-global-nav-drawer-opener {
      width: 51px; } }

.ncl-global-nav-menu-overlay {
  background-color: #0c4f48;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px; }

.ncl-global-nav-drawer .drawer-content {
  background-color: #e9e9e9 !important; }

.ncl-global-nav-drawer-container .ncl-global-nav-drawer {
  top: 194px; }
  .ncl-global-nav-drawer-container .ncl-global-nav-drawer .drawer-mask {
    top: 194px; }

.ncl-drawer-content {
  width: 356px; }
  .ncl-drawer-content .ncl-drawer-title {
    border-bottom: 1px solid #ccc;
    padding: 15px;
    padding-left: 56px;
    font-size: 18px;
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
  .ncl-drawer-content .ncl-global-nav-drawer-list .ncl-global-nav-drawer-list-item {
    padding-left: 56px;
    height: 50px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-drawer-content .ncl-global-nav-drawer-list .ncl-global-nav-drawer-list-item:hover {
      background-color: #ccc; }
    .ncl-drawer-content .ncl-global-nav-drawer-list .ncl-global-nav-drawer-list-item.__active {
      background-color: #99cc99;
      font-weight: 600; }

@media (min-width: 992px) {
  .ncl-app-body.__show-drawer-opener {
    padding-left: 87px; } }

@media (min-width: 768px) and (max-width: 991px) {
  .ncl-app-body.__show-drawer-opener {
    padding-left: 50px; } }

.ncl-default-checkbox.__disabled:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

@-webkit-keyframes nc-anim-checkbox {
  from {
    opacity: 1;
    height: 15px;
    width: 15px; }
  to {
    opacity: 0;
    height: 25px;
    width: 25px; } }

@keyframes nc-anim-checkbox {
  from {
    opacity: 1;
    height: 15px;
    width: 15px; }
  to {
    opacity: 0;
    height: 25px;
    width: 25px; } }

.ncl-default-checkbox {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  font-size: 15px;
  line-height: 15px; }
  .ncl-default-checkbox.__default {
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-default-checkbox {
      font-size: 18px;
      line-height: 18px; } }
  .ncl-default-checkbox .svg-inline--fa.fa-check-square {
    color: #009999; }
    @media (max-width: 991px) {
      .ncl-default-checkbox .svg-inline--fa.fa-check-square {
        width: 18px;
        height: 18px; } }
  .ncl-default-checkbox .ncl-check-anim-wrapper {
    position: absolute;
    height: 25px;
    width: 25px;
    left: -6px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-default-checkbox .ncl-check-anim-wrapper .ncl-check-anim {
      background-color: #009999;
      -webkit-animation: nc-anim-checkbox 0.3s forwards;
              animation: nc-anim-checkbox 0.3s forwards; }
  .ncl-default-checkbox .svg-inline--fa.fa-square {
    color: #7b7b7b; }
    @media (max-width: 991px) {
      .ncl-default-checkbox .svg-inline--fa.fa-square {
        width: 18px;
        height: 18px; } }
  .ncl-default-checkbox .svg-inline--fa {
    margin-right: 10px; }
    .ncl-default-checkbox .svg-inline--fa.__disabled {
      color: #ccc; }
  .ncl-default-checkbox:hover {
    cursor: pointer; }

.ncl-button-checkbox, .ncl-button-checkbox-squared {
  margin-bottom: 5px; }
  @media (max-width: 991px) {
    .ncl-button-checkbox, .ncl-button-checkbox-squared {
      font-size: 15px;
      margin-bottom: 15px; } }
  @media (min-width: 992px) {
    .ncl-button-checkbox .__checkbox-default, .ncl-button-checkbox-squared .__checkbox-default {
      margin-bottom: 20px; } }
  .ncl-button-checkbox input[type="checkbox"] + label, .ncl-button-checkbox-squared input[type="checkbox"] + label {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    padding: 0px 15px;
    border: 1px solid #999;
    border-radius: 2px;
    display: inline-flex;
    align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 44px; }
    .ncl-button-checkbox input[type="checkbox"] + label:before, .ncl-button-checkbox-squared input[type="checkbox"] + label:before {
      content: "";
      display: none;
      width: 0; }
    .ncl-button-checkbox input[type="checkbox"] + label i.fa:first-child, .ncl-button-checkbox-squared input[type="checkbox"] + label i.fa:first-child {
      margin-right: 5px; }
    @media (min-width: 992px) {
      .ncl-button-checkbox input[type="checkbox"] + label:hover, .ncl-button-checkbox-squared input[type="checkbox"] + label:hover {
        background-color: #e9e9e9; } }
    @media (max-width: 991px) {
      .ncl-button-checkbox input[type="checkbox"] + label, .ncl-button-checkbox-squared input[type="checkbox"] + label {
        padding: 0px 10px; } }
  .ncl-button-checkbox input[type="checkbox"]:checked + label, .ncl-button-checkbox-squared input[type="checkbox"]:checked + label {
    background-color: #009999;
    color: #fff; }

.ncl-button-checkbox-tealed {
  margin-bottom: 5px; }
  @media (max-width: 991px) {
    .ncl-button-checkbox-tealed {
      font-size: 15px;
      margin-bottom: 15px; } }
  .ncl-button-checkbox-tealed.__default {
    margin-bottom: 20px; }
  .ncl-button-checkbox-tealed input[type="checkbox"] + label {
    padding: 0px 15px;
    border: 1px solid #999;
    background-color: #fff;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    height: 32px; }
    .ncl-button-checkbox-tealed input[type="checkbox"] + label:before {
      content: "";
      display: none;
      width: 0; }
    .ncl-button-checkbox-tealed input[type="checkbox"] + label i.fa:first-child {
      margin-right: 5px; }
    @media (min-width: 992px) {
      .ncl-button-checkbox-tealed input[type="checkbox"] + label:hover {
        background-color: #e9e9e9; } }
    @media (max-width: 991px) {
      .ncl-button-checkbox-tealed input[type="checkbox"] + label {
        height: 44px; } }
  .ncl-button-checkbox-tealed input[type="checkbox"]:checked + label {
    background-color: #009999;
    border-top-color: #009999;
    border-bottom-color: #009999;
    color: #fff; }

.ncl-button-larger-checkbox {
  font-size: 18px;
  cursor: pointer; }
  .ncl-button-larger-checkbox.__default {
    margin-bottom: 20px; }
  .ncl-button-larger-checkbox .svg-inline--fa {
    margin-right: 10px; }
  .ncl-button-larger-checkbox .svg-inline--fa.fa-check-square {
    color: #009999; }
  .ncl-button-larger-checkbox .svg-inline--fa.fa-square {
    color: #7b7b7b; }
  .ncl-button-larger-checkbox input[type="checkbox"] + label {
    border-radius: 1px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
    .ncl-button-larger-checkbox input[type="checkbox"] + label:before {
      width: 20px;
      margin-right: 20px;
      font-size: inherit; }
    .ncl-button-larger-checkbox input[type="checkbox"] + label i.fa:first-child {
      margin-right: 5px; }

.ncl-button-checkbox-squared {
  padding: 0px; }
  .ncl-button-checkbox-squared.__default {
    margin-bottom: 20px; }
  .ncl-button-checkbox-squared input[type="checkbox"] + label {
    height: 32px;
    width: 32px;
    border-left: 1px solid #999;
    border-right: none;
    padding: 0px 15px;
    border-radius: 0px;
    background-color: #fff; }
    @media (max-width: 991px) {
      .ncl-button-checkbox-squared input[type="checkbox"] + label {
        height: 44px;
        width: 40px; } }
    @media (max-width: 767px) {
      .ncl-button-checkbox-squared input[type="checkbox"] + label {
        padding: 0px 10px; } }
  .ncl-button-checkbox-squared:first-child input[type="checkbox"] + label {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px; }
    @media (max-width: 991px) {
      .ncl-button-checkbox-squared:first-child input[type="checkbox"] + label {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px; } }
  .ncl-button-checkbox-squared:last-child input[type="checkbox"] + label {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-right: 1px solid #999; }
    @media (max-width: 991px) {
      .ncl-button-checkbox-squared:last-child input[type="checkbox"] + label {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px; } }

.ncl-circular-checkbox {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer; }
  @media (max-width: 991px) {
    .ncl-circular-checkbox {
      font-size: 18px; } }
  .ncl-circular-checkbox.__default {
    margin-bottom: 20px; }
  .ncl-circular-checkbox .__checkbox-circle {
    border-radius: 100%;
    height: 25px;
    width: 25px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 10px;
    font-size: 15px; }
    .ncl-circular-checkbox .__checkbox-circle.__unchecked {
      border: 1px solid #ccc;
      color: #fff; }
    .ncl-circular-checkbox .__checkbox-circle.__checked {
      border: 1px solid #339966;
      color: #339966; }
  .ncl-circular-checkbox input[type="checkbox"] + label {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-circular-checkbox input[type="checkbox"] + label i {
      margin-right: 0; }
    .ncl-circular-checkbox input[type="checkbox"] + label:before {
      display: none;
      width: 0; }
  .ncl-circular-checkbox input[type="checkbox"]:checked + label:before {
    display: none;
    width: 0; }

@media (min-width: 992px) {
  .default .__checkbox-default {
    margin-bottom: 20px; } }


.ncl-checkbox-group {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .ncl-checkbox-group .ncl-checkbox-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    margin-bottom: 20px;
    padding-right: 20px; }
    @media (max-width: 991px) {
      .ncl-checkbox-group .ncl-checkbox-wrapper:not(:last-child) {
        margin-bottom: 20px; } }
  .ncl-checkbox-group .ncl-checkbox-wrapper-lg {
    padding: 10px; }
  .ncl-checkbox-group.ncl-circular-checkbox-group {
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 768px) {
      .ncl-checkbox-group.ncl-circular-checkbox-group {
        -webkit-flex-direction: row;
                flex-direction: row; } }
    .ncl-checkbox-group.ncl-circular-checkbox-group .ncl-checkbox-wrapper {
      margin-bottom: 0px; }
      @media (min-width: 768px) {
        .ncl-checkbox-group.ncl-circular-checkbox-group .ncl-checkbox-wrapper:not(:first-child) {
          margin-left: 30px; } }
      @media (max-width: 767px) {
        .ncl-checkbox-group.ncl-circular-checkbox-group .ncl-checkbox-wrapper:not(:first-child) {
          margin-top: 15px; } }

.ncl-switch.disabled, .disabled.ncl-switch-sm {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

@-webkit-keyframes nc-anim-toggle-circle {
  from {
    opacity: 1;
    height: 27px;
    width: 27px; }
  to {
    opacity: 0;
    height: 50px;
    width: 50px; } }

@keyframes nc-anim-toggle-circle {
  from {
    opacity: 1;
    height: 27px;
    width: 27px; }
  to {
    opacity: 0;
    height: 50px;
    width: 50px; } }

@-webkit-keyframes nc-anim-toggle-circle-sm {
  from {
    opacity: 1;
    height: 18px;
    width: 18px; }
  to {
    opacity: 0;
    height: 40px;
    width: 40px; } }

@keyframes nc-anim-toggle-circle-sm {
  from {
    opacity: 1;
    height: 18px;
    width: 18px; }
  to {
    opacity: 0;
    height: 40px;
    width: 40px; } }

.ncl-switch, .ncl-switch-sm {
  width: 55px;
  height: 30px;
  border-radius: 15px;
  cursor: pointer;
  display: -webkit-inline-flex;
  display: inline-flex;
  background-color: #e9e9e9;
  border: 1px solid #ccc;
  font-size: 15px;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  .ncl-switch .ncl-switch-toggle, .ncl-switch-sm .ncl-switch-toggle {
    border: 1px solid #ccc;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    width: 27px;
    height: 27px;
    left: 0;
    border-radius: 15px;
    background: #fff;
    position: relative;
    -webkit-transition: left 0.2s ease-in-out;
    transition: left 0.2s ease-in-out; }
    .ncl-switch .ncl-switch-toggle svg, .ncl-switch-sm .ncl-switch-toggle svg {
      color: #999;
      margin-right: 0;
      font-size: 15px; }
  .ncl-switch.on, .on.ncl-switch-sm {
    background: #339966;
    border-color: #339966; }
    .ncl-switch.on svg, .on.ncl-switch-sm svg {
      color: #339966; }
    .ncl-switch.on .ncl-switch-toggle, .on.ncl-switch-sm .ncl-switch-toggle {
      left: 25px;
      border: 1px solid #339966;
      position: relative; }
      .ncl-switch.on .ncl-switch-toggle .ncl-toggle-circle, .on.ncl-switch-sm .ncl-switch-toggle .ncl-toggle-circle {
        position: absolute;
        height: 27px;
        width: 27px;
        border-radius: 50%;
        z-index: -1;
        opacity: 1; }
        .ncl-switch.on .ncl-switch-toggle .ncl-toggle-circle.__on, .on.ncl-switch-sm .ncl-switch-toggle .ncl-toggle-circle.__on {
          background-color: #339966;
          -webkit-animation: nc-anim-toggle-circle 0.3s forwards;
                  animation: nc-anim-toggle-circle 0.3s forwards;
          -webkit-animation-delay: 0.1s;
                  animation-delay: 0.1s; }

.ncl-switch-sm {
  border-radius: 10px;
  width: 38px;
  height: 20px; }
  .ncl-switch-sm .ncl-switch-toggle {
    width: 18px;
    height: 18px; }
    .ncl-switch-sm .ncl-switch-toggle svg {
      font-size: 10px; }
  .ncl-switch-sm.on .ncl-switch-toggle {
    left: 18px; }
    .ncl-switch-sm.on .ncl-switch-toggle .ncl-toggle-circle {
      position: absolute;
      height: 18px;
      width: 18px;
      border-radius: 50%;
      z-index: -1;
      opacity: 1; }
      .ncl-switch-sm.on .ncl-switch-toggle .ncl-toggle-circle.__on {
        background-color: #339966;
        -webkit-animation: nc-anim-toggle-circle-sm 0.3s forwards;
                animation: nc-anim-toggle-circle-sm 0.3s forwards;
        -webkit-animation-delay: 0.1s;
                animation-delay: 0.1s; }

.ncl-button-checkbox-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-button-checkbox-group .ncl-button-checkbox-tealed label {
    border-radius: 0px; }
  .ncl-button-checkbox-group .ncl-button-checkbox-tealed:first-child label {
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px; }
  .ncl-button-checkbox-group .ncl-button-checkbox-tealed:last-child label {
    border-top-right-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    border-right: 1px solid #999; }
  .ncl-button-checkbox-group .ncl-button-checkbox-tealed:not(:first-child) label {
    border-left: none; }

.ncl-button-checkbox-toolbar.__email-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-button-checkbox-toolbar.__email-group .ncl-button-checkbox:not(:first-child) {
    margin-left: 20px; }
  @media (max-width: 767px) {
    .ncl-button-checkbox-toolbar.__email-group {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.ncl-button-checkbox-toolbar .ncl-button-checkbox:not(:first-child) {
  margin-left: 20px; }

.ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  @media (max-width: 767px) {
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email {
      -webkit-flex-direction: row;
              flex-direction: row; } }
  @media (min-width: 768px) {
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox {
      margin-bottom: 0; }
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email:not(:first-child) {
      margin-left: 20px; } }
  .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox-group .ncl-button-checkbox-tealed {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox-group .ncl-button-checkbox-tealed label {
      width: 100%; }
  @media (max-width: 767px) {
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox-group {
      margin-left: 10px; } }
  @media (min-width: 768px) {
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox-group {
      margin-top: 5px; } }
  .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox label {
    width: 151.6px;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
    .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox label svg.svg-inline--fa {
      margin-right: 5px; }
    @media (max-width: 767px) {
      .ncl-button-checkbox-toolbar .ncl-button-checkbox-group-email .ncl-button-checkbox label {
        width: 126.59px;
        -webkit-flex-basis: 126.59px;
                flex-basis: 126.59px; } }

@media (min-width: 768px) {
  .ncl-form-field-group.__default {
    margin-bottom: 20px; } }

@media (max-width: 767px) {
  .ncl-form-field-group.__default {
    margin-bottom: 20px; } }

.ncl-form-field-group .ncl-form-field-group-body {
  display: block; }
  @media (min-width: 768px) {
    .ncl-form-field-group .ncl-form-field-group-body {
      padding-top: 0px;
      display: -webkit-flex;
      display: flex; }
      .ncl-form-field-group .ncl-form-field-group-body .ncl-form-field {
        padding-top: 0 !important;
        margin-right: 15px; } }
    @media (min-width: 768px) and (min-width: 768px) {
      .ncl-form-field-group .ncl-form-field-group-body .ncl-form-field.__default {
        margin-bottom: 0px; } }
    @media (min-width: 768px) and (max-width: 767px) {
      .ncl-form-field-group .ncl-form-field-group-body .ncl-form-field.__default {
        margin-bottom: 0px; } }

.ncl-form-field-group .ncl-form-field-group-footer {
  margin-top: -5px; }
  @media (max-width: 767px) {
    .ncl-form-field-group .ncl-form-field-group-footer {
      margin-top: 15px; } }
  .ncl-form-field-group .ncl-form-field-group-footer i.fa {
    margin-right: 5px; }

.ncl-form-field-group .ncl-form-field-link {
  margin-top: 34px; }
  .ncl-form-field-group .ncl-form-field-link a {
    line-height: 90%; }
  .ncl-form-field-group .ncl-form-field-link i.fa {
    margin-right: 5px; }
  @media (max-width: 991px) {
    .ncl-form-field-group .ncl-form-field-link {
      margin-top: 38px;
      font-size: 18px; } }
  @media (max-width: 767px) {
    .ncl-form-field-group .ncl-form-field-link {
      margin-top: -5px; } }

.ncl-form-tooltip.__tooltip-default {
  margin-bottom: 20px; }

@media (max-width: 991px) {
  .ncl-form-tooltip .ncl-well {
    font-size: 15px; } }

.ncl-form-tooltip-close-icon {
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-inline-flex;
  display: inline-flex;
  color: #fff;
  background-color: #009999;
  border: none;
  border-radius: 50px;
  float: right;
  padding: 0;
  text-align: center;
  height: 23px;
  width: 23px;
  position: relative;
  top: -8px;
  right: -10px; }
  .ncl-form-tooltip-close-icon:hover {
    cursor: pointer;
    box-shadow: 2px 0px 4px 0px rgba(0, 0, 0, 0.35) !important;
    color: #fff;
    /** TODO (Ashish) : Remove this important after button styles are fixed*/ }
  .ncl-form-tooltip-close-icon i {
    font-size: 18px;
    margin-right: 0px; }

.ncl-form-field {
  font-size: 15px; }
  .ncl-form-field .ncl-form-control.__default {
    margin-bottom: 0px; }
  .ncl-form-field.__default {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-form-field.__with-icon {
      display: -webkit-flex;
      display: flex; } }
  .ncl-form-field .ncl-form-field-icon {
    padding-right: 10px; }
  .ncl-form-field .ncl-form-field-sub-wrapper-for-icon {
    -webkit-flex-grow: 1;
            flex-grow: 1; }
  .ncl-form-field .ncl-form-field-name {
    margin-bottom: 10px; }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-name {
        font-size: 18px;
        line-height: 90%; } }
    @media (max-width: 767px) {
      .ncl-form-field .ncl-form-field-name {
        line-height: 1.2; } }
    .ncl-form-field .ncl-form-field-name.input-error {
      color: #e53935;
      font-weight: 600; }
      @media (max-width: 991px) {
        .ncl-form-field .ncl-form-field-name.input-error {
          font-size: 18px; } }
    .ncl-form-field .ncl-form-field-name-optional-label {
      color: #999;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      padding-left: 5px; }
      @media (max-width: 991px) {
        .ncl-form-field .ncl-form-field-name-optional-label {
          font-size: 18px; } }
    .ncl-form-field .ncl-form-field-name-tooltip-icon i {
      padding-left: 5px; }
    .ncl-form-field .ncl-form-field-name-tooltip-icon svg.svg-inline--fa {
      margin-left: 5px; }
  @media (max-width: 991px) {
    .ncl-form-field .ncl-form-field-show-hide {
      font-size: 18px; } }
  .ncl-form-field .ncl-form-field-show-hide a svg.svg-inline--fa {
    margin-right: 5px; }
  .ncl-form-field .ncl-form-field-show-hide .ncl-form-field-show-hide-value-wrapper {
    margin-bottom: 15px; }
  .ncl-form-field .ncl-form-field-help-text {
    color: #999;
    margin-left: 5px; }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-help-text {
        font-size: 18px; } }
  .ncl-form-field .ncl-form-field-information {
    margin-bottom: 15px;
    margin-top: -5px; }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-information {
        font-size: 16px; } }
  .ncl-form-field .ncl-form-field-description {
    color: #999;
    font-size: 14px;
    font-weight: 400; }
    @media (max-width: 767px) {
      .ncl-form-field .ncl-form-field-description {
        margin-top: 10px; } }
    @media (min-width: 768px) {
      .ncl-form-field .ncl-form-field-description {
        margin-left: 15px; } }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-description {
        font-size: 18px; } }
  @media (min-width: 768px) {
    .ncl-form-field .ncl-form-field-component-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-form-field .ncl-form-field-component-wrapper .ncl-form-field-template .ncl-form-control {
        margin-right: 0; } }
  .ncl-form-field .ncl-form-field-component-wrapper .inline-field-description {
    color: #999;
    font-size: 14px;
    font-style: italic;
    padding-left: 15px; }
  .ncl-form-field .ncl-form-field-input-error {
    color: #e53935;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px; }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-input-error {
        font-size: 18px; } }
  @media (max-width: 991px) {
    .ncl-form-field .ncl-form-field-value-read-only {
      font-size: 18px; } }
  .ncl-form-field .ncl-form-field-footer {
    margin-top: 15px; }
    .ncl-form-field .ncl-form-field-footer i.fa {
      margin-right: 5px; }
  .ncl-form-field .ncl-form-field-enabler {
    margin-top: 15px; }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-enabler {
        font-size: 18px; } }
    @media (max-width: 991px) {
      .ncl-form-field .ncl-form-field-enabler .ncl-default-checkbox label:before {
        font-size: 18px; } }
  .ncl-form-field .ncl-form-tooltip {
    margin-bottom: 15px; }

.ncl-inline-form-field {
  font-size: 15px; }
  .ncl-inline-form-field .ncl-form-control.__default {
    margin-bottom: 0px; }
  .ncl-inline-form-field.__default {
    margin-bottom: 20px; }
  .ncl-inline-form-field .ncl-form-field-enabler {
    padding-left: 20px;
    color: #999;
    font-style: italic; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-inline-form-field .ncl-form-field-enabler {
        font-size: 18px; } }
    .ncl-inline-form-field .ncl-form-field-enabler label:before {
      font-style: normal; }
  .ncl-inline-form-field .ncl-inline-form-subwrapper {
    clear: both;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name {
      padding-right: 15px; }
      @media (max-width: 991px) {
        .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name {
          font-size: 18px; } }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name.input-error {
        color: #e53935;
        font-weight: 600; }
        @media (min-width: 768px) and (max-width: 991px) {
          .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name.input-error {
            font-size: 18px; } }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name-optional-label {
        color: #999;
        font-size: 14px;
        font-style: italic;
        position: absolute; }
        @media (min-width: 768px) and (max-width: 991px) {
          .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name-optional-label {
            font-size: 18px; } }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name-tooltip-icon i {
        padding-left: 5px; }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name-tooltip-icon svg.svg-inline--fa {
        margin-left: 5px; }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-name-icon {
        margin-right: 15px; }
    .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-show-hide {
      display: -webkit-flex;
      display: flex; }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-show-hide {
          font-size: 18px; } }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-show-hide a svg.svg-inline--fa {
        margin-right: 5px; }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-show-hide .ncl-form-field-show-hide-value-wrapper {
        margin-right: 10px; }
    .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-description {
      color: #999;
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
      margin-top: 5px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-description {
          font-size: 18px; } }
    .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-component-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-component-wrapper .inline-field-description {
        color: #999;
        font-size: 14px;
        font-style: italic;
        padding-left: 15px; }
        @media (min-width: 768px) and (max-width: 991px) {
          .ncl-inline-form-field .ncl-inline-form-subwrapper .ncl-form-field-component-wrapper .inline-field-description {
            font-size: 18px; } }
  .ncl-inline-form-field .ncl-form-tooltip {
    margin-top: 15px; }
  .ncl-inline-form-field .ncl-form-field-input-error {
    color: #e53935;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-inline-form-field .ncl-form-field-input-error {
        font-size: 18px; } }

.ncl-form-field-detail.__default {
  margin-bottom: 20px; }

@media (min-width: 768px) and (max-width: 991px) {
  .ncl-form-field-detail {
    font-size: 18px; } }

.ncl-form-field-detail .ncl-form-field-input-error {
  color: #e53935;
  font-size: 15px;
  font-weight: 600;
  margin-top: 10px; }

.ncl-form-control.disabled-field:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"] + label {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0; }
  input[type="checkbox"]:checked + label {
    font-weight: 400; }

input[type="text"] {
  border-radius: 0px;
  border: 1px solid #999;
  font-size: 15px;
  color: #555;
  font-family: FontAwesome, "Source Sans Pro", sans-serif; }
  input[type="text"]::-webkit-input-placeholder {
    font-style: normal; }
  input[type="text"]:-ms-input-placeholder {
    font-style: normal; }
  input[type="text"]::-ms-input-placeholder {
    font-style: normal; }
  input[type="text"]::placeholder {
    font-style: normal; }
  input[type="text"]:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px #ccffff, 0 0 8px #ccffff; }
  @media (max-width: 991px) {
    input[type="text"] {
      font-size: 18px; } }

input[type="number"] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.ncl-multi-input-field {
  display: -webkit-flex;
  display: flex; }
  .ncl-multi-input-field.__default {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-multi-input-field .ncl-multi-input-field-item {
      -webkit-flex-basis: 0%;
              flex-basis: 0%;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; } }
  .ncl-multi-input-field .ncl-multi-input-field-item .ncl-form-control {
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .ncl-multi-input-field .ncl-multi-input-field-item .ncl-form-control.__tiny {
        width: 100%; } }
  .ncl-multi-input-field .ncl-multi-input-field-item:first-child .ncl-form-control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .ncl-multi-input-field .ncl-multi-input-field-item:not(:first-child) .ncl-form-control {
    border-left: none; }
  .ncl-multi-input-field .ncl-multi-input-field-item:last-child .ncl-form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .ncl-multi-input-field .ncl-multi-input-field-item .ncl-multi-input-field-item-label {
    font-style: italic;
    text-align: center;
    color: #999;
    font-size: 14px; }

.ncl-form-control {
  border-radius: 0px;
  border: 1px solid #999;
  color: #555;
  display: block;
  max-width: 100%;
  height: 32px;
  padding: 6px 10px;
  font-size: 15px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none; }
  .ncl-form-control.__default {
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-form-control {
      height: 44px;
      font-size: 18px; } }
  .ncl-form-control.inactive-field {
    background-color: #f4f4f4;
    color: #999; }
    .ncl-form-control.inactive-field:hover {
      cursor: default; }
  .ncl-form-control.disabled-field {
    background-color: #e9e9e9;
    border: 1px solid #ccc;
    color: #999;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    line-height: 1.2; }
  .ncl-form-control:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(204, 255, 255, 0.8), 0 0 8px rgba(204, 255, 255, 0.8); }
  .ncl-form-control.__tiny {
    width: 50px; }
    @media (max-width: 767px) {
      .ncl-form-control.__tiny {
        width: 100%; } }
  .ncl-form-control.__x-small {
    width: 75px; }
    @media (max-width: 767px) {
      .ncl-form-control.__x-small {
        width: 100%; } }
  .ncl-form-control.__small {
    width: 140px; }
    @media (max-width: 767px) {
      .ncl-form-control.__small {
        width: 100%; } }
  .ncl-form-control.__medium {
    width: 215px; }
    @media (max-width: 767px) {
      .ncl-form-control.__medium {
        width: 100%; } }
  .ncl-form-control.__large {
    width: 350px; }
    @media (max-width: 767px) {
      .ncl-form-control.__large {
        width: 100%; } }
  .ncl-form-control.__standard {
    width: 300px; }
    @media (max-width: 767px) {
      .ncl-form-control.__standard {
        width: 100%; } }

textarea {
  border-radius: 0;
  padding: 10px;
  border: 1px solid #999;
  -webkit-appearance: none;
  resize: none; }
  textarea.__default {
    margin-bottom: 20px; }
  textarea:focus {
    box-shadow: inset 0 1px 1px rgba(204, 255, 255, 0.8), 0 0 8px rgba(204, 255, 255, 0.8);
    outline: 0; }
  @media (min-width: 768px) {
    textarea {
      width: 600px; } }
  @media (max-width: 767px) {
    textarea {
      width: 100%; } }

.ncl-input-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-input-group .ncl-input-add-on {
    background-color: #e9e9e9;
    border: 1px solid #999;
    color: #555;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 400;
    height: 32px;
    width: 32px; }
    @media (max-width: 991px) {
      .ncl-input-group .ncl-input-add-on {
        height: 44px;
        width: 44px;
        -webkit-flex-basis: 44px;
                flex-basis: 44px;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        font-size: 18px; } }
    .ncl-input-group .ncl-input-add-on:first-child {
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .ncl-input-group .ncl-input-add-on:last-child {
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

.ncl-radio input[type="radio"]:checked + span > .checked {
  background-color: #555;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }

.ncl-radio input[type="radio"] {
  display: none; }
  .ncl-radio input[type="radio"] + span {
    background-color: #fff;
    border: 1px solid #999;
    font-size: 14px;
    width: 12px;
    height: 12px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 50%; }
    .ncl-radio input[type="radio"] + span > .checked {
      width: 6px;
      height: 6px;
      position: relative;
      color: #fff;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      background-color: #fff; }

.ncl-form-control.disabled-field:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-form-control.disabled-field:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

input[type="checkbox"] {
  display: none; }
  input[type="checkbox"] + label {
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0; }
  input[type="checkbox"]:checked + label {
    font-weight: 400; }

input[type="text"] {
  border-radius: 0px;
  border: 1px solid #999;
  font-size: 15px;
  color: #555;
  font-family: FontAwesome, "Source Sans Pro", sans-serif; }
  input[type="text"]::-webkit-input-placeholder {
    font-style: normal; }
  input[type="text"]:-ms-input-placeholder {
    font-style: normal; }
  input[type="text"]::-ms-input-placeholder {
    font-style: normal; }
  input[type="text"]::placeholder {
    font-style: normal; }
  input[type="text"]:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px #ccffff, 0 0 8px #ccffff; }
  @media (max-width: 991px) {
    input[type="text"] {
      font-size: 18px; } }

input[type="number"] {
  -moz-appearance: textfield; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

.ncl-multi-input-field {
  display: -webkit-flex;
  display: flex; }
  .ncl-multi-input-field.__default {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-multi-input-field .ncl-multi-input-field-item {
      -webkit-flex-basis: 0%;
              flex-basis: 0%;
      -webkit-flex-grow: 1;
              flex-grow: 1;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; } }
  .ncl-multi-input-field .ncl-multi-input-field-item .ncl-form-control {
    margin-bottom: 10px; }
    @media (max-width: 767px) {
      .ncl-multi-input-field .ncl-multi-input-field-item .ncl-form-control.__tiny {
        width: 100%; } }
  .ncl-multi-input-field .ncl-multi-input-field-item:first-child .ncl-form-control {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px; }
  .ncl-multi-input-field .ncl-multi-input-field-item:not(:first-child) .ncl-form-control {
    border-left: none; }
  .ncl-multi-input-field .ncl-multi-input-field-item:last-child .ncl-form-control {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px; }
  .ncl-multi-input-field .ncl-multi-input-field-item .ncl-multi-input-field-item-label {
    font-style: italic;
    text-align: center;
    color: #999;
    font-size: 14px; }

.ncl-form-control {
  border-radius: 0px;
  border: 1px solid #999;
  color: #555;
  display: block;
  max-width: 100%;
  height: 32px;
  padding: 6px 10px;
  font-size: 15px;
  line-height: 1.3;
  background-color: #fff;
  background-image: none;
  box-shadow: none;
  -webkit-appearance: none; }
  .ncl-form-control.__default {
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-form-control {
      height: 44px;
      font-size: 18px; } }
  .ncl-form-control.inactive-field {
    background-color: #f4f4f4;
    color: #999; }
    .ncl-form-control.inactive-field:hover {
      cursor: default; }
  .ncl-form-control.disabled-field {
    background-color: #e9e9e9;
    border: 1px solid #ccc;
    color: #999;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    line-height: 1.2; }
  .ncl-form-control:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(204, 255, 255, 0.8), 0 0 8px rgba(204, 255, 255, 0.8); }
  .ncl-form-control.__tiny {
    width: 50px; }
    @media (max-width: 767px) {
      .ncl-form-control.__tiny {
        width: 100%; } }
  .ncl-form-control.__x-small {
    width: 75px; }
    @media (max-width: 767px) {
      .ncl-form-control.__x-small {
        width: 100%; } }
  .ncl-form-control.__small {
    width: 140px; }
    @media (max-width: 767px) {
      .ncl-form-control.__small {
        width: 100%; } }
  .ncl-form-control.__medium {
    width: 215px; }
    @media (max-width: 767px) {
      .ncl-form-control.__medium {
        width: 100%; } }
  .ncl-form-control.__large {
    width: 350px; }
    @media (max-width: 767px) {
      .ncl-form-control.__large {
        width: 100%; } }
  .ncl-form-control.__standard {
    width: 300px; }
    @media (max-width: 767px) {
      .ncl-form-control.__standard {
        width: 100%; } }

textarea {
  border-radius: 0;
  padding: 10px;
  border: 1px solid #999;
  -webkit-appearance: none;
  resize: none; }
  textarea.__default {
    margin-bottom: 20px; }
  textarea:focus {
    box-shadow: inset 0 1px 1px rgba(204, 255, 255, 0.8), 0 0 8px rgba(204, 255, 255, 0.8);
    outline: 0; }
  @media (min-width: 768px) {
    textarea {
      width: 600px; } }
  @media (max-width: 767px) {
    textarea {
      width: 100%; } }

.ncl-input-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-input-group .ncl-input-add-on {
    background-color: #e9e9e9;
    border: 1px solid #999;
    color: #555;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 400;
    height: 32px;
    width: 32px; }
    @media (max-width: 991px) {
      .ncl-input-group .ncl-input-add-on {
        height: 44px;
        width: 44px;
        -webkit-flex-basis: 44px;
                flex-basis: 44px;
        -webkit-flex-shrink: 0;
                flex-shrink: 0;
        font-size: 18px; } }
    .ncl-input-group .ncl-input-add-on:first-child {
      border-right: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px; }
    .ncl-input-group .ncl-input-add-on:last-child {
      border-left: 0;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px; }

.ncl-radio input[type="radio"]:checked + span > .checked, .ncl-radio-button input[type="radio"]:checked + span > .checked, .ncl-radio-inline-button input[type="radio"]:checked + span > .checked, .ncl-radio-title-button input[type="radio"]:checked + span > .checked, .ncl-radio-description-button input[type="radio"]:checked + span > .checked, .ncl-radio-long-description-button input[type="radio"]:checked + span > .checked, .ncl-radio-description-long-title-button input[type="radio"]:checked + span > .checked, .ncl-radio-content-heavy-button input[type="radio"]:checked + span > .checked {
  background-color: #555;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }

.ncl-radio input[type="radio"], .ncl-radio-button input[type="radio"], .ncl-radio-inline-button input[type="radio"], .ncl-radio-title-button input[type="radio"], .ncl-radio-description-button input[type="radio"], .ncl-radio-long-description-button input[type="radio"], .ncl-radio-description-long-title-button input[type="radio"], .ncl-radio-content-heavy-button input[type="radio"] {
  display: none; }
  .ncl-radio input[type="radio"] + span, .ncl-radio-button input[type="radio"] + span, .ncl-radio-inline-button input[type="radio"] + span, .ncl-radio-title-button input[type="radio"] + span, .ncl-radio-description-button input[type="radio"] + span, .ncl-radio-long-description-button input[type="radio"] + span, .ncl-radio-description-long-title-button input[type="radio"] + span, .ncl-radio-content-heavy-button input[type="radio"] + span {
    background-color: #fff;
    border: 1px solid #999;
    font-size: 14px;
    width: 12px;
    height: 12px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    cursor: pointer;
    border-radius: 50%; }
    .ncl-radio input[type="radio"] + span > .checked, .ncl-radio-button input[type="radio"] + span > .checked, .ncl-radio-inline-button input[type="radio"] + span > .checked, .ncl-radio-title-button input[type="radio"] + span > .checked, .ncl-radio-description-button input[type="radio"] + span > .checked, .ncl-radio-long-description-button input[type="radio"] + span > .checked, .ncl-radio-description-long-title-button input[type="radio"] + span > .checked, .ncl-radio-content-heavy-button input[type="radio"] + span > .checked {
      width: 6px;
      height: 6px;
      position: relative;
      color: #fff;
      vertical-align: middle;
      cursor: pointer;
      border-radius: 50%;
      background-color: #fff; }

.ncl-radio-button, .ncl-radio-inline-button, .ncl-radio-title-button, .ncl-radio-description-button, .ncl-radio-long-description-button, .ncl-radio-description-long-title-button, .ncl-radio-content-heavy-button {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  border-radius: 3px;
  cursor: default;
  min-height: 40px;
  min-width: 125px;
  margin: 0px;
  background-color: #fff;
  border: 1px solid #999;
  padding: 10px; }
  .ncl-radio-button-input {
    margin-right: 10px;
    display: -webkit-inline-flex;
    display: inline-flex; }
  @media (max-width: 767px) {
    .ncl-radio-button, .ncl-radio-inline-button, .ncl-radio-title-button, .ncl-radio-description-button, .ncl-radio-long-description-button, .ncl-radio-description-long-title-button, .ncl-radio-content-heavy-button {
      -webkit-justify-content: center;
              justify-content: center;
      margin-right: -10px;
      width: 100%; } }
  @media (max-width: 991px) {
    .ncl-radio-button, .ncl-radio-inline-button, .ncl-radio-title-button, .ncl-radio-description-button, .ncl-radio-long-description-button, .ncl-radio-description-long-title-button, .ncl-radio-content-heavy-button {
      height: 44px; } }
  .ncl-radio-button.__disabled, .__disabled.ncl-radio-inline-button, .__disabled.ncl-radio-title-button, .__disabled.ncl-radio-description-button, .__disabled.ncl-radio-long-description-button, .__disabled.ncl-radio-description-long-title-button, .__disabled.ncl-radio-content-heavy-button {
    border: 1px solid #ccc;
    color: #999; }
    .ncl-radio-button.__disabled .ncl-radio-button-input, .__disabled.ncl-radio-inline-button .ncl-radio-button-input, .__disabled.ncl-radio-title-button .ncl-radio-button-input, .__disabled.ncl-radio-description-button .ncl-radio-button-input, .__disabled.ncl-radio-long-description-button .ncl-radio-button-input, .__disabled.ncl-radio-description-long-title-button .ncl-radio-button-input, .__disabled.ncl-radio-content-heavy-button .ncl-radio-button-input {
      cursor: default; }
      .ncl-radio-button.__disabled .ncl-radio-button-input span, .__disabled.ncl-radio-inline-button .ncl-radio-button-input span, .__disabled.ncl-radio-title-button .ncl-radio-button-input span, .__disabled.ncl-radio-description-button .ncl-radio-button-input span, .__disabled.ncl-radio-long-description-button .ncl-radio-button-input span, .__disabled.ncl-radio-description-long-title-button .ncl-radio-button-input span, .__disabled.ncl-radio-content-heavy-button .ncl-radio-button-input span {
        cursor: default; }
        .ncl-radio-button.__disabled .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-inline-button .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-title-button .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-description-button .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-long-description-button .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-description-long-title-button .ncl-radio-button-input span span.checked, .__disabled.ncl-radio-content-heavy-button .ncl-radio-button-input span span.checked {
          cursor: default; }
    .ncl-radio-button.__disabled:hover, .__disabled.ncl-radio-inline-button:hover, .__disabled.ncl-radio-title-button:hover, .__disabled.ncl-radio-description-button:hover, .__disabled.ncl-radio-long-description-button:hover, .__disabled.ncl-radio-description-long-title-button:hover, .__disabled.ncl-radio-content-heavy-button:hover {
      background-color: inherit;
      border: 1px solid #ccc;
      cursor: default; }
    .ncl-radio-button.__disabled.selected, .__disabled.selected.ncl-radio-inline-button, .__disabled.selected.ncl-radio-title-button, .__disabled.selected.ncl-radio-description-button, .__disabled.selected.ncl-radio-long-description-button, .__disabled.selected.ncl-radio-description-long-title-button, .__disabled.selected.ncl-radio-content-heavy-button {
      background-color: #e9e9e9;
      border: 1px solid #ccc;
      color: #999; }
      .ncl-radio-button.__disabled.selected .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-inline-button .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-title-button .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-description-button .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-long-description-button .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-description-long-title-button .ncl-radio-button-input span span.checked, .__disabled.selected.ncl-radio-content-heavy-button .ncl-radio-button-input span span.checked {
        background-color: #999; }
      .ncl-radio-button.__disabled.selected .title, .__disabled.selected.ncl-radio-inline-button .title, .__disabled.selected.ncl-radio-title-button .title, .__disabled.selected.ncl-radio-description-button .title, .__disabled.selected.ncl-radio-long-description-button .title, .__disabled.selected.ncl-radio-description-long-title-button .title, .__disabled.selected.ncl-radio-content-heavy-button .title {
        font-weight: 600; }
  .ncl-radio-button.selected, .selected.ncl-radio-inline-button, .selected.ncl-radio-title-button, .selected.ncl-radio-description-button, .selected.ncl-radio-long-description-button, .selected.ncl-radio-description-long-title-button, .selected.ncl-radio-content-heavy-button {
    background-color: #ccffff;
    border: 1px solid #00cccc; }
    .ncl-radio-button.selected .title, .selected.ncl-radio-inline-button .title, .selected.ncl-radio-title-button .title, .selected.ncl-radio-description-button .title, .selected.ncl-radio-long-description-button .title, .selected.ncl-radio-description-long-title-button .title, .selected.ncl-radio-content-heavy-button .title {
      font-weight: 600; }
  .ncl-radio-button:hover, .ncl-radio-inline-button:hover, .ncl-radio-title-button:hover, .ncl-radio-description-button:hover, .ncl-radio-long-description-button:hover, .ncl-radio-description-long-title-button:hover, .ncl-radio-content-heavy-button:hover {
    background-color: #ccffff;
    border: 1px solid #00cccc;
    cursor: pointer; }

.ncl-non-eyed-radio {
  height: 32px;
  width: 32px;
  min-height: inherit;
  min-width: inherit;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border: 1px solid #999;
  border-radius: 4px; }
  .ncl-non-eyed-radio:hover {
    cursor: pointer;
    background-color: #ccffff;
    border: 1px solid #00cccc; }
  .ncl-non-eyed-radio.selected {
    background-color: #ccffff;
    border: 1px solid #00cccc; }
  .ncl-non-eyed-radio .input[type="radio"] {
    display: none; }
  .ncl-non-eyed-radio label {
    margin-bottom: 0;
    cursor: inherit; }
  @media (max-width: 991px) {
    .ncl-non-eyed-radio {
      height: 44px;
      width: 44px; } }

.ncl-radio-inline-button {
  min-height: 32px;
  height: 32px; }
  @media (max-width: 991px) {
    .ncl-radio-inline-button {
      height: 44px; } }

.ncl-radio-title-button {
  height: 60px;
  width: 185px; }
  @media (max-width: 767px) {
    .ncl-radio-title-button {
      width: 100% !important; } }

.ncl-radio-description-button {
  min-width: 185px;
  height: 50px; }
  @media (max-width: 767px) {
    .ncl-radio-description-button {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      width: 100% !important;
      height: 44px; } }
  .ncl-radio-description-button .ncl-radio-button-content {
    text-align: center; }
    @media (max-width: 767px) {
      .ncl-radio-description-button .ncl-radio-button-content {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }
    .ncl-radio-description-button .ncl-radio-button-content .title {
      text-transform: uppercase; }
      @media (max-width: 767px) {
        .ncl-radio-description-button .ncl-radio-button-content .title {
          text-align: left;
          font-size: 15px; } }
    .ncl-radio-description-button .ncl-radio-button-content .description {
      font-size: 14px;
      font-style: italic; }
      @media (max-width: 767px) {
        .ncl-radio-description-button .ncl-radio-button-content .description {
          padding-left: 20px; } }

.ncl-radio-long-description-button {
  height: 75px; }
  @media (max-width: 767px) {
    .ncl-radio-long-description-button {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      width: 100% !important; } }
  .ncl-radio-long-description-button .ncl-radio-button-content {
    text-align: center; }

.ncl-radio-description-long-title-button {
  width: 185px;
  height: 75px; }
  @media (max-width: 767px) {
    .ncl-radio-description-long-title-button {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      width: 100% !important;
      height: 60px; } }
  .ncl-radio-description-long-title-button .ncl-radio-button-content {
    width: 100%; }
    @media (min-width: 768px) {
      .ncl-radio-description-long-title-button .ncl-radio-button-content {
        text-align: center; } }
    .ncl-radio-description-long-title-button .ncl-radio-button-content .title {
      text-transform: uppercase;
      font-size: 15px; }
      @media (max-width: 767px) {
        .ncl-radio-description-long-title-button .ncl-radio-button-content .title {
          -webkit-flex-basis: 35%;
                  flex-basis: 35%;
          text-align: left; } }
    .ncl-radio-description-long-title-button .ncl-radio-button-content .description {
      font-size: 14px;
      font-style: italic; }

.ncl-radio-content-heavy-button {
  min-height: 135px;
  width: 350px; }
  @media (max-width: 767px) {
    .ncl-radio-content-heavy-button {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      width: 100% !important; } }
  .ncl-radio-content-heavy-button .description {
    font-size: 15px; }
    .ncl-radio-content-heavy-button .description .ncl-tpp-address-format div:not(:first-child) {
      margin-top: 2px; }
    .ncl-radio-content-heavy-button .description .ncl-tpp-address-format div:last-child {
      margin-top: 10px; }
    @media (max-width: 767px) {
      .ncl-radio-content-heavy-button .description {
        text-align: left; } }
  .ncl-radio-content-heavy-button.selected .description {
    font-weight: 600; }

.ncl-radio-text-heavy-button {
  display: -webkit-flex;
  display: flex;
  line-height: 1.4; }
  .ncl-radio-text-heavy-button:hover {
    cursor: pointer; }
  @media (max-width: 767px) {
    .ncl-radio-text-heavy-button {
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      width: 100% !important; } }
  .ncl-radio-text-heavy-button .ncl-radio-button-input input[type="radio"] {
    display: none; }
    .ncl-radio-text-heavy-button .ncl-radio-button-input input[type="radio"] + span {
      border: 1px solid #999;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-radio-text-heavy-button .ncl-radio-button-input input[type="radio"] + span > span.checked {
        border-radius: 50%;
        width: 10px;
        height: 10px;
        top: 0;
        left: 0;
        -webkit-transition: background-color 0.2s;
        transition: background-color 0.2s; }
  .ncl-radio-text-heavy-button .ncl-radio-button-content {
    font-size: 15px; }
  .ncl-radio-text-heavy-button.selected .ncl-radio-button-input input[type="radio"] {
    display: none; }
    .ncl-radio-text-heavy-button.selected .ncl-radio-button-input input[type="radio"] + span > span.checked {
      background-color: #555;
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }

.ncl-radio-group-wrapper.__default {
  margin-bottom: 20px; }

.ncl-radio-group-wrapper .ncl-default-radio-group, .ncl-radio-group-wrapper .ncl-radio-group-noneyed, .ncl-radio-group-wrapper .ncl-default-radio-group-basic {
  margin-right: -10px;
  margin-bottom: -10px;
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 767px) {
    .ncl-radio-group-wrapper .ncl-default-radio-group, .ncl-radio-group-wrapper .ncl-radio-group-noneyed, .ncl-radio-group-wrapper .ncl-default-radio-group-basic {
      margin-right: -10px;
      -webkit-flex-direction: column;
              flex-direction: column; } }
  @media (min-width: 768px) {
    .ncl-radio-group-wrapper .ncl-default-radio-group, .ncl-radio-group-wrapper .ncl-radio-group-noneyed, .ncl-radio-group-wrapper .ncl-default-radio-group-basic {
      -webkit-flex-wrap: wrap;
              flex-wrap: wrap; } }
  .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-non-eyed-radio:not(:first-child), .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-non-eyed-radio:not(:first-child), .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-non-eyed-radio:not(:first-child) {
    margin-left: 5px; }
  .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-radio-button-wrapper, .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-radio-button-wrapper, .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-radio-button-wrapper {
    padding-right: 10px;
    padding-bottom: 10px; }
    @media (max-width: 767px) {
      .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-radio-button-wrapper, .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-radio-button-wrapper, .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-radio-button-wrapper {
        -webkit-flex-grow: 1;
                flex-grow: 1;
        -webkit-flex-basis: 50%;
                flex-basis: 50%; } }
  .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-radio-group-footer-link, .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-radio-group-footer-link, .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-radio-group-footer-link {
    margin-top: 10px; }
  .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-radio-custom, .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-radio-custom, .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-radio-custom {
    margin-bottom: 10px;
    margin-right: 10px; }
    @media (max-width: 767px) {
      .ncl-radio-group-wrapper .ncl-default-radio-group .ncl-radio-custom, .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-radio-custom, .ncl-radio-group-wrapper .ncl-default-radio-group-basic .ncl-radio-custom {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }

@media (max-width: 767px) {
  .ncl-radio-group-wrapper .ncl-radio-group-noneyed {
    -webkit-flex-direction: row;
            flex-direction: row; }
    .ncl-radio-group-wrapper .ncl-radio-group-noneyed .ncl-non-eyed-radio {
      margin-right: 0; } }

.ncl-radio-group-wrapper .ncl-default-radio-group-basic {
  padding-top: 0px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 767px) {
    .ncl-radio-group-wrapper .ncl-default-radio-group-basic {
      -webkit-flex-direction: row;
              flex-direction: row; } }
  @media (min-width: 768px) {
    .ncl-radio-group-wrapper .ncl-default-radio-group-basic {
      display: -webkit-flex;
      display: flex; } }

.ncl-radio-group-wrapper .ncl-radio-group-footer-link {
  margin-top: 10px; }
  @media (max-width: 767px) {
    .ncl-radio-group-wrapper .ncl-radio-group-footer-link {
      margin-top: 20px; } }

.ncl-radio-group-heavy .ncl-radio-button-wrapper:not(:first-child) {
  margin-top: 20px; }

.ncl-radio-custom .ncl-custom-field-icon {
  background-color: #e9e9e9;
  padding: 5px;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  margin-right: 12px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  color: #fff;
  font-weight: 600;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  .ncl-radio-custom .ncl-custom-field-icon svg.svg-inline--fa {
    margin-right: 0;
    color: #fff; }
  .ncl-radio-custom .__inactive.ncl-custom-field-icon {
    background-color: #ccc; }

.ncl-radio-custom {
  display: -webkit-inline-flex;
  display: inline-flex;
  background-color: #fff;
  border-radius: 3px;
  padding: 5px;
  -webkit-align-items: center;
          align-items: center;
  border: 1px solid #999;
  -webkit-transition: background-color 0.2s;
  transition: background-color 0.2s; }
  @media (min-width: 768px) {
    .ncl-radio-custom {
      width: 260px; } }
  .ncl-radio-custom:hover {
    cursor: pointer; }
  .ncl-radio-custom.selected {
    background-color: #fafafa;
    font-weight: 600; }

.ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field.disabled-field .ncl-form-control {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field.disabled-field .ncl-form-control {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content > div.ncl-dropdown-selected-button {
  background-color: #fff;
  border: 1px solid #999;
  color: #555;
  font-size: 15px;
  padding: 0px 12px;
  border-radius: 0px;
  height: 32px;
  width: 100%;
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content > div.ncl-dropdown-selected-button .fa.fa-angle-up, .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content > div.ncl-dropdown-selected-button .fa.fa-angle-down {
    font-size: 25px; }
  .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content > div.ncl-dropdown-selected-button:hover {
    cursor: pointer; }

.ncl-select-container.ncl-dual-dropdown {
  position: relative; }
  .ncl-select-container.ncl-dual-dropdown.__default {
    margin-bottom: 20px;
    width: 300px; }
    @media (max-width: 767px) {
      .ncl-select-container.ncl-dual-dropdown.__default {
        width: 100%; } }
  .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button {
    height: 34px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      @media (max-width: 991px) {
        .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value {
          display: -webkit-flex;
          display: flex; } }
      .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value .ncl-dropdown-selected-button-icon {
        font-size: 18px;
        color: #999; }
      .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value .ncl-dropdown-selected-button-label {
        margin-left: 10px; }
      .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .ncl-dropdown-selected-value .ncl-dropdown-selected-button-description {
        margin-left: 10px;
        color: #999; }
    .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button .secondary-value {
      font-size: 12px;
      color: #999;
      font-weight: 400; }
    @media (max-width: 991px) {
      .ncl-select-container.ncl-dual-dropdown > div.ncl-dropdown-selected-button {
        height: 44px; } }
  .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover {
    width: 100%;
    z-index: 1060;
    display: none;
    margin-top: 10px;
    position: absolute;
    border: 1px solid #999;
    background-color: #fff;
    padding: 15px 10px;
    padding-right: 28px;
    box-shadow: 0 2px 9px 0px rgba(0, 0, 0, 0.35);
    border-radius: 0;
    margin-left: 0;
    margin-top: 15px;
    z-index: 10; }
    .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover > .arrow {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 11px; }
      .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover > .arrow::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: "";
        border-width: 10px; }
    .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover.bottom > .arrow {
      top: -11px;
      left: 50%;
      margin-left: -11px;
      border-top-width: 0;
      border-bottom-color: #999;
      border-bottom-color: rgba(0, 0, 0, 0.25); }
      .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover.bottom > .arrow::after {
        top: 1px;
        margin-left: -10px;
        content: " ";
        border-top-width: 0;
        border-bottom-color: #fff; }
    .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content {
      padding: 0;
      font-family: inherit; }
      .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content .ncl-dual-dropdown-inputname {
        font-size: 15px;
        color: #555;
        margin-bottom: 10px;
        font-weight: 600; }
      .ncl-select-container.ncl-dual-dropdown .ncl-dual-dropdown-popover .ncl-dual-dropdown-popover-content .ncl-select-container .ncl-dropdown-overlay {
        margin-top: 10px; }

@media (min-width: 992px) {
  .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-scrollbar-wrapper {
    margin-right: -18px;
    margin-top: 10px; }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-scrollbar-wrapper .ncl-dual-dropdown-options-scroll-view {
      padding-right: 18px; }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-scrollbar-wrapper hr {
      margin: 0;
      margin-right: 18px;
      border-top: 1px solid #999; } }

@media (min-width: 992px) {
  .ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field {
    margin-top: 10px;
    margin-bottom: 10px; } }

@media (max-width: 991px) {
  .ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field {
    padding-top: 0px; } }

.ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field.disabled-field .ncl-form-control {
  background-color: #f4f4f4;
  color: #999;
  border: 1px solid #ccc; }

.ncl-dual-dropdown-secondary-options-container .ncl-dropdown-search-field .ncl-form-control {
  width: 100%; }

.ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list {
  margin-top: 0px;
  margin-bottom: 0px;
  border-top: none;
  list-style-type: none;
  padding: 0; }
  @media (min-width: 992px) {
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list {
      border-right: 1px solid #999;
      border-left: 1px solid #999; } }
  @media (max-width: 991px) {
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list {
      margin: 0px;
      padding-top: 0px; } }
  .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option {
    font-size: 15px;
    padding: 5px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option .ncl-dropdown-option-description {
      color: #999;
      font-size: 14px; }
    @media (max-width: 991px) {
      .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option {
        padding: 10px 20px;
        min-height: 44px;
        border-bottom: 1px solid #e9e9e9;
        font-size: 16px; } }
    @media (min-width: 992px) {
      .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option:hover {
        background-color: #f1f1f1;
        cursor: pointer; } }
    @media (max-width: 767px) {
      .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option {
        padding: 10px; } }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option.selected {
      background-color: #ccffff;
      font-weight: 600; }
      .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option.selected .ncl-dropdown-option-description {
        font-weight: 600; }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option .ncl-dropdown-option-label {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      margin: 0px;
      font-weight: inherit; }
      .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option .ncl-dropdown-option-label:hover {
        cursor: pointer; }
    .ncl-dual-dropdown-secondary-options-container .ncl-dual-dropdown-list .ncl-dropdown-option .ncl-dropdown-option-desctiption {
      color: #999;
      font-size: 14px; }

.ncl-dropdown-other {
  display: -webkit-flex;
  display: flex; }
  .ncl-dropdown-other.__default {
    margin-bottom: 20px; }
  @media (min-width: 768px) {
    .ncl-dropdown-other {
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 767px) {
    .ncl-dropdown-other {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-dropdown-other .ncl-select-container {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    min-width: 300px;
    margin-right: 15px; }
    @media (max-width: 767px) {
      .ncl-dropdown-other .ncl-select-container {
        margin-bottom: 15px; } }
  .ncl-dropdown-other .ncl-form-control::-webkit-input-placeholder {
    font-style: italic;
    color: #999; }
  .ncl-dropdown-other .ncl-form-control:-ms-input-placeholder {
    font-style: italic;
    color: #999; }
  .ncl-dropdown-other .ncl-form-control::-ms-input-placeholder {
    font-style: italic;
    color: #999; }
  .ncl-dropdown-other .ncl-form-control::placeholder {
    font-style: italic;
    color: #999; }

/* DayPicker styles */
.ncl-date-select-container > button {
  background-color: #fff;
  border: 1px solid #999;
  color: #555;
  font-size: 15px;
  padding: 0px 12px;
  border-radius: 0px;
  height: 32px;
  width: 100%;
  text-align: left;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .ncl-date-select-container > button .fa.fa-angle-up, .ncl-date-select-container > button .fa.fa-angle-down {
    font-size: 25px; }
  .ncl-date-select-container > button:hover {
    cursor: pointer; }

.ncl-date-picker-icon {
  color: #7b7b7b; }
  .ncl-date-picker-icon svg.svg-inline--fa.fa-pencil-alt {
    font-size: 15px; }
    @media (max-width: 991px) {
      .ncl-date-picker-icon svg.svg-inline--fa.fa-pencil-alt {
        font-size: 20px; } }
  .ncl-date-picker-icon:hover {
    color: #555; }

.ncl-daypicker-no-end-date {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-size: 15px; }
  @media (min-width: 992px) {
    .ncl-daypicker-no-end-date {
      border-top: 1px solid #ccc;
      padding: 15px 40px; } }
  @media (max-width: 991px) {
    .ncl-daypicker-no-end-date {
      margin-bottom: 30px;
      font-size: 18px; }
      .ncl-daypicker-no-end-date input[type="checkbox"]:checked + label:before {
        font-size: inherit;
        width: 18px; } }
  @media (max-width: 767px) {
    .ncl-daypicker-no-end-date {
      margin-bottom: 25px; } }

@media (min-width: 992px) {
  .ncl-date-select-container.__default {
    margin-bottom: 20px; } }

@media (max-width: 991px) {
  .ncl-date-select-container.__default {
    margin-bottom: 20px; } }

.ncl-date-select-container.__date-range-button {
  width: 215px; }
  @media (max-width: 991px) {
    .ncl-date-select-container.__date-range-button {
      width: 300px; } }
  @media (max-width: 767px) {
    .ncl-date-select-container.__date-range-button {
      width: 100%; } }

.ncl-date-select-container.__date-picker-button {
  width: 140px; }
  @media (max-width: 767px) {
    .ncl-date-select-container.__date-picker-button {
      width: 100%; } }

@media (max-width: 991px) {
  .ncl-date-select-container > button {
    height: 44px;
    font-size: 18px; } }

.ncl-date-select-legends .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--today {
  background-color: #006699;
  color: #fff; }

.ncl-date-select-legends .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--availableWire {
  background-color: #009999;
  color: #fff; }

.ncl-date-select-legends .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--available {
  background-color: #fff;
  color: #555;
  border: 1px solid #555; }

.ncl-date-select-legends .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--unavailable {
  background-color: #ccc;
  color: #555; }

.ncl-date-select-legends .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--holiday {
  background-color: #00cccc;
  color: #fff; }

.modal.calendar {
  overflow-y: hidden; }
  .modal.calendar .modal-dialog {
    width: auto; }
    @media (max-width: 991px) {
      .modal.calendar .modal-dialog {
        margin: 0px; } }
    .modal.calendar .modal-dialog .modal-content {
      border: none;
      border-radius: 0px;
      box-shadow: none;
      height: 100vh;
      padding: 0px; }
      .modal.calendar .modal-dialog .modal-content .modal-header {
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal.calendar .modal-dialog .modal-content .modal-header {
            padding: 10px 20px; } }
        @media (max-width: 991px) {
          .modal.calendar .modal-dialog .modal-content .modal-header {
            height: 44px; } }
        .modal.calendar .modal-dialog .modal-content .modal-header .date-range-label {
          font-size: 14px;
          text-align: center; }
        .modal.calendar .modal-dialog .modal-content .modal-header .ncl-close-icon {
          position: absolute;
          left: 25px;
          font-size: 22px; }
          @media (max-width: 767px) {
            .modal.calendar .modal-dialog .modal-content .modal-header .ncl-close-icon {
              left: 10px;
              font-size: 20px; } }
          .modal.calendar .modal-dialog .modal-content .modal-header .ncl-close-icon i {
            cursor: pointer;
            margin-right: 0; }
        .modal.calendar .modal-dialog .modal-content .modal-header .date-picker-header {
          font-size: 16px; }
        .modal.calendar .modal-dialog .modal-content .modal-header .date-picker-clear-link-sm {
          position: absolute;
          right: 10px; }
      .modal.calendar .modal-dialog .modal-content .modal-mobile-body {
        padding: 0px; }
        .modal.calendar .modal-dialog .modal-content .modal-mobile-body .header-container {
          border-bottom: 1px solid #ccc;
          height: 40px;
          padding-bottom: 15px;
          padding: 10px; }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .header-container .sort-label {
            font-size: 15px;
            font-weight: 400;
            text-align: center; }
          .modal.calendar .modal-dialog .modal-content .modal-mobile-body .header-container .clear-all-link {
            text-align: right; }
          @media (min-width: 768px) and (max-width: 991px) {
            .modal.calendar .modal-dialog .modal-content .modal-mobile-body .header-container {
              padding-left: 20px;
              padding-right: 20px; } }
      .modal.calendar .modal-dialog .modal-content .modal-footer {
        text-align: center;
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: fixed;
        padding: 15px 13px 15px 13px;
        background-color: #fff; }
        .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group {
          max-width: 320px;
          margin-left: auto;
          margin-right: auto; }
          @media (min-width: 992px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .primary-button {
              width: 75% !important;
              margin-right: 20px; } }
          @media (max-width: 991px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .primary-button {
              width: 100%;
              margin-bottom: 10px; } }
          @media (max-width: 991px) {
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .secondary-link-button {
              width: 100%; } }
          .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display {
            display: -webkit-flex;
            display: flex;
            padding-top: 5px;
            padding-bottom: 20px; }
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .start-date {
              font-size: 22px;
              font-weight: 600;
              margin-left: 5px; }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .start-date {
                  font-size: 18px;
                  font-weight: 600; } }
            .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .end-date {
              font-size: 22px;
              font-weight: 600;
              margin-left: 5px; }
              @media (min-width: 320px) and (max-width: 767px) {
                .modal.calendar .modal-dialog .modal-content .modal-footer .calendar-button-group-wrapper .calendar-button-group .set-display .end-date {
                  font-size: 18px;
                  font-weight: 600; } }
        @media (min-width: 768px) and (max-width: 991px) {
          .modal.calendar .modal-dialog .modal-content .modal-footer {
            padding-left: 20px;
            padding-right: 20px; } }

.calendar-icon {
  margin-right: 10px; }

.date-seperator {
  padding-left: 5px;
  padding-right: 5px; }

@media (max-width: 991px) {
  .ncl-daypicker-button-group-wrapper {
    padding: 30px 20px; } }
  @media (max-width: 991px) and (max-width: 767px) {
    .ncl-daypicker-button-group-wrapper {
      padding: 25px 10px; } }

@media (max-width: 991px) {
    .ncl-daypicker-button-group-wrapper .ncl-daypicker-display {
      display: -webkit-flex;
      display: flex;
      padding-bottom: 20px;
      -webkit-justify-content: space-between;
              justify-content: space-between; } }
    @media (max-width: 991px) and (min-width: 320px) and (max-width: 767px) {
      .ncl-daypicker-button-group-wrapper .ncl-daypicker-display {
        -webkit-justify-content: center;
                justify-content: center; } }

@media (max-width: 991px) {
      .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-sdate {
        font-size: 20px;
        font-weight: 600;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-sdate i,
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-sdate span {
          font-size: 20px;
          font-weight: 400;
          margin-right: 0;
          margin-left: 5px; } }
      @media (max-width: 991px) and (min-width: 320px) and (max-width: 991px) {
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-sdate {
          margin-right: 25px; } }
      @media (max-width: 991px) and (min-width: 320px) and (max-width: 767px) {
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-sdate {
          font-size: 18px; } }

@media (max-width: 991px) {
      .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-edate {
        font-size: 20px;
        font-weight: 600;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-edate i,
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-edate span {
          font-size: 20px;
          font-weight: 400;
          margin-right: 0;
          margin-left: 5px; } }
      @media (max-width: 991px) and (min-width: 320px) and (max-width: 767px) {
        .ncl-daypicker-button-group-wrapper .ncl-daypicker-display .ncl-daypicker-display-edate {
          font-size: 18px;
          font-weight: 600; } }

@media (min-width: 992px) {
  .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group {
    padding: 0px 15px 15px 15px; } }

@media (max-width: 767px) {
  .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group {
    width: 100%;
    text-align: center; } }

@media (min-width: 768px) and (max-width: 991px) {
  .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; } }

.ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group .secondary-button {
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group .secondary-button {
      width: 100%; } }

@media (max-width: 991px) {
  .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group .secondary-link-button {
    width: 100%;
    color: #999;
    background-color: #fff; } }

@media (min-width: 992px) {
  .ncl-daypicker-button-group-wrapper .ncl-daypicker-button-group .secondary-link-button {
    padding: 10px 10px; } }

.ncl-daypicker-overlay-wrapper {
  width: 308px;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0 3px 13px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0;
  margin-left: 0;
  margin-top: 5px;
  z-index: 10;
  /* DayPickerInput */ }
  @media (max-width: 991px) {
    .ncl-daypicker-overlay-wrapper {
      margin-top: 0px;
      width: 100%;
      height: 100%;
      outline: none;
      box-shadow: none; } }
  .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover {
    z-index: 1060;
    display: none;
    margin-top: 10px; }
    .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover > .arrow {
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
      border-width: 11px; }
      .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover > .arrow::after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        content: "";
        border-width: 10px; }
    .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover.bottom > .arrow {
      top: -11px;
      left: 50%;
      margin-left: -11px;
      border-top-width: 0;
      border-bottom-color: #999;
      border-bottom-color: rgba(0, 0, 0, 0.02); }
      .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover.bottom > .arrow::after {
        top: 1px;
        margin-left: -10px;
        content: " ";
        border-top-width: 0;
        border-bottom-color: #fff; }
    .ncl-daypicker-overlay-wrapper.ncl-daypicker-popover .ncl-daypicker-popover-content {
      padding: 0;
      font-family: inherit; }
  .ncl-daypicker-overlay-wrapper .ncl-daypicker-NavButton--interactionDisabled {
    display: none; }
  .ncl-daypicker-overlay-wrapper .ncl-daypicker-Caption {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 20px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-daypicker-overlay-wrapper .ncl-daypicker-Caption {
        margin: 0px auto;
        width: 40%; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .ncl-daypicker-overlay-wrapper .ncl-daypicker-Caption {
        margin: 0px auto;
        width: 70%; } }
    .ncl-daypicker-overlay-wrapper .ncl-daypicker-Caption:focus {
      outline: none; }
    .ncl-daypicker-overlay-wrapper .ncl-daypicker-Caption .Select {
      height: 23px; }
  .ncl-daypicker-overlay-wrapper .DayPickerInput {
    display: inline-block; }
  .ncl-daypicker-overlay-wrapper .DayPickerInput-OverlayWrapper {
    position: relative; }
  .ncl-daypicker-overlay-wrapper .DayPickerInput-Overlay {
    left: 0;
    position: absolute;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15); }

.ncl-daypicker {
  display: block;
  /* Default modifiers */
  /* Example modifiers */ }
  .ncl-daypicker .ncl-daypicker-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    position: relative;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    padding: 25px 0px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-daypicker .ncl-daypicker-wrapper {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0px; } }
    @media (min-width: 320px) and (max-width: 767px) {
      .ncl-daypicker .ncl-daypicker-wrapper {
        -webkit-flex-direction: column;
                flex-direction: column;
        padding: 0px; } }
    .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      margin-bottom: 30px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption {
          width: 388px;
          margin-left: auto;
          margin-right: auto;
          padding-top: 50px;
          margin-bottom: 15px; } }
      @media (max-width: 767px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption {
          padding-top: 30px;
          margin-bottom: 16.5px;
          max-width: 300px;
          margin-left: auto;
          margin-right: auto; } }
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-caption-month-name {
        font-weight: 600;
        color: #555;
        font-size: 18px; }
        @media (max-width: 991px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-caption-month-name {
            font-size: 25px; } }
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector {
        padding-left: 5px; }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector div.ncl-dropdown-selected-button {
          border: none !important;
          color: #999;
          display: -webkit-inline-flex;
          display: inline-flex;
          -webkit-align-items: center;
                  align-items: center;
          font-size: 18px;
          font-weight: 600;
          height: inherit;
          padding: 0; }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector div.ncl-dropdown-selected-button .ncl-dropdown-selected-badge-arrow {
            margin-left: 5px; }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector div.ncl-dropdown-selected-button .ncl-dropdown-selected-value {
            height: inherit; }
            @media (max-width: 991px) {
              .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector div.ncl-dropdown-selected-button .ncl-dropdown-selected-value {
                font-size: 25px !important; } }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-caption .ncl-daypicker-year-selector div.ncl-dropdown-selected-button i {
            font-size: 20px; }
    .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar {
      width: inherit;
      margin-left: auto;
      margin-right: auto;
      position: absolute;
      left: 40px;
      right: 40px;
      padding: 0;
      top: 23px;
      height: 20px;
      margin-top: 0px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar {
          position: fixed;
          top: 160px;
          width: 388px;
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: space-between;
                  justify-content: space-between; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar {
          position: fixed;
          -webkit-justify-content: space-between;
                  justify-content: space-between;
          display: -webkit-flex;
          display: flex;
          top: 137px;
          left: 30px;
          right: 30px; } }
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton {
        font-size: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        cursor: pointer; }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton:focus {
          outline: none; }
        @media (min-width: 992px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton.ncl-daypicker-NavButton--prev {
            margin-left: 0px;
            width: inherit;
            position: absolute; } }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton.ncl-daypicker-NavButton--prev:focus {
          outline: none; }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton.ncl-daypicker-NavButton--next {
          margin-right: 0px; }
          @media (min-width: 992px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-NavBar .ncl-daypicker-NavButton.ncl-daypicker-NavButton--next {
              right: 0;
              width: inherit;
              position: absolute; } }
    .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays {
      border-bottom: 1px solid #ccc;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      font-weight: 600; }
      @media (min-width: 992px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays {
          width: 308px; } }
      @media (max-width: 991px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays {
          padding: 27px 0px 10px 0px;
          font-size: 22px;
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          background-color: #fff; } }
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow {
        padding: 0;
        font-size: 15px;
        font-weight: 600;
        text-align: center;
        color: #555;
        border-bottom: none;
        padding: 0px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
        @media (min-width: 992px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow {
            width: 266px; } }
        @media (min-width: 992px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow {
            margin-bottom: 10px; } }
        @media (max-width: 991px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow {
            font-size: 22px; } }
        @media (max-width: 767px) {
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow {
            font-size: 18px; } }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow .ncl-daypicker-Weekday {
          padding: 0;
          width: 32px; }
          @media (max-width: 991px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow .ncl-daypicker-Weekday {
              width: 60px; } }
          @media (max-width: 767px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow .ncl-daypicker-Weekday {
              width: 45px; } }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-Weekdays .ncl-daypicker-WeekdaysRow .ncl-daypicker-Weekday:not(:first-child) {
            margin-left: 2.5px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar:not(:first-child) {
        margin-top: 20px; } }
    @media (max-width: 767px) {
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar {
        padding: 30px;
        margin-top: 0;
        padding-top: 0; } }
    .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month {
      display: block;
      margin-bottom: 0px;
      width: unset; }
      @media (min-width: 992px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month {
          margin-top: 10px; } }
      @media (min-width: 320px) and (max-width: 991px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month {
          margin-top: 20px; } }
      @media (max-width: 991px) {
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body {
          overflow-y: auto; } }
      .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center; }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week:not(:first-child) {
          margin-top: 7px; }
          @media (min-width: 768px) and (max-width: 991px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week:not(:first-child) {
              margin-top: 15px; } }
        .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day {
          display: -webkit-flex;
          display: flex;
          -webkit-justify-content: center;
                  justify-content: center;
          -webkit-align-items: center;
                  align-items: center;
          padding: 0px;
          font-size: 15px;
          font-weight: 400;
          color: #555;
          text-align: center;
          width: 32px;
          height: 32px;
          cursor: pointer;
          border-bottom: 0px solid white;
          border-right: 0 solid white;
          border-radius: 2.5px; }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day:not(:first-child) {
            margin-left: 2.5px; }
          @media (min-width: 768px) and (max-width: 991px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day {
              width: 60px;
              height: 60px;
              font-size: 22px; } }
          @media (min-width: 320px) and (max-width: 767px) {
            .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day {
              width: 45px;
              height: 45px;
              font-size: 18px; } }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--today {
            color: #009999;
            font-weight: 700; }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--disabled {
            color: #ccc;
            cursor: default;
            background-color: #eff1f1; }
          .ncl-daypicker .ncl-daypicker-wrapper .ncl-daypicker-wrapper-without-navbar .ncl-daypicker-Month .ncl-daypicker-Body .ncl-daypicker-Week .ncl-daypicker-Day.ncl-daypicker-Day--outside {
            cursor: default;
            color: #ccc; }
  .ncl-daypicker .ncl-daypicker-WeekNumber {
    display: table-cell;
    padding: 0.5rem;
    text-align: right;
    vertical-align: middle;
    min-width: 1rem;
    font-size: 0.75em;
    cursor: pointer;
    color: #8b9898; }
  .ncl-daypicker .ncl-daypicker--interactionDisabled .ncl-daypicker-Day {
    cursor: default; }
  .ncl-daypicker .ncl-daypicker-Footer {
    display: table-caption;
    caption-side: bottom;
    padding-top: 0.5rem; }
  .ncl-daypicker .ncl-daypicker-TodayButton {
    border: none;
    background-image: none;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    color: #4a90e2;
    font-size: 0.875em; }
  .ncl-daypicker .ncl-daypicker-Day--sunday {
    background-color: #f7f8f8; }
  .ncl-daypicker .ncl-daypicker-Day--sunday:not(.ncl-daypicker-Day--today) {
    color: #dce0e0; }
  .ncl-daypicker .ncl-daypicker-Day--selected:not(.ncl-daypicker-Day--disabled):not(.ncl-daypicker-Day--outside) {
    color: #555;
    background-color: #e9e9e9; }
  .ncl-daypicker .ncl-daypicker-Day--start,
  .ncl-daypicker .ncl-daypicker-Day--end {
    background-color: #009999 !important;
    color: #fff !important; }

@media (min-width: 992px) {
  .ncl-daypicker-dashboard-legend-container {
    border-top: 1px solid #ccc;
    padding: 15px 40px 15px 40px; } }

@media (max-width: 991px) {
  .ncl-daypicker-dashboard-legend-container {
    margin-bottom: 30px;
    width: 435px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: left; } }

@media (max-width: 767px) {
  .ncl-daypicker-dashboard-legend-container {
    width: 330px; } }

.ncl-daypicker-dashboard-legend-container .ncl-accordion .ncl-accordion-title a {
  color: #555;
  font-size: 18px;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-daypicker-dashboard-legend-container .ncl-accordion .ncl-accordion-title a i.fa {
    font-size: 20px; }
    @media (max-width: 991px) {
      .ncl-daypicker-dashboard-legend-container .ncl-accordion .ncl-accordion-title a i.fa {
        font-size: 25px; } }
  @media (max-width: 991px) {
    .ncl-daypicker-dashboard-legend-container .ncl-accordion .ncl-accordion-title a {
      -webkit-justify-content: center;
              justify-content: center; } }

.ncl-daypicker-dashboard-legend-container .ncl-accordion .ncl-collapsible-content {
  padding-bottom: 0px; }

.ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends {
  list-style-type: none;
  padding-left: 0px;
  margin-top: 5px; }
  .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item {
    margin-bottom: 15px; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item:last-child {
      margin-bottom: 0; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-legend-box {
      border-radius: 2.5px;
      padding: 5px;
      margin-right: 15px;
      width: 25px;
      height: 25px;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-today-legend {
      background-color: #006699;
      color: #fff; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-available-wire-legend {
      background-color: #009999;
      color: #fff; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-available-legend {
      background-color: #fff;
      color: #555;
      border: 1px solid #555; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-unavailable-legend {
      background-color: #ccc;
      color: #555; }
    .ncl-daypicker-dashboard-legend-container .ncl-daypicker-legends .ncl-daypicker-legend-item .ncl-daypicker-holiday-legend {
      background-color: #00cccc;
      color: #fff; }

.ncl-daypicker-mobile .ncl-daypicker-mobile-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-daypicker-mobile .ncl-daypicker-mobile-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-daypicker-mobile .ncl-daypicker-mobile-header .ncl-close-icon {
        left: 10px; } }
  .ncl-daypicker-mobile .ncl-daypicker-mobile-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-daypicker-mobile .ncl-daypicker-mobile-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-daypicker-mobile .ncl-daypicker-mobile-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-daypicker-mobile .ncl-daypicker-mobile-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-daypicker-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  min-height: 100vh;
  z-index: 2000; }
  .ncl-daypicker-mobile .ncl-daypicker-mobile-links {
    position: absolute;
    right: 20px;
    display: -webkit-flex;
    display: flex; }
    @media (max-width: 767px) {
      .ncl-daypicker-mobile .ncl-daypicker-mobile-links {
        right: 10px; } }
    .ncl-daypicker-mobile .ncl-daypicker-mobile-links .ncl-daypicker-mobile-link {
      font-size: 16px; }
      .ncl-daypicker-mobile .ncl-daypicker-mobile-links .ncl-daypicker-mobile-link:not(:first-child) {
        margin-left: 20px; }
  .ncl-daypicker-mobile .ncl-daypicker-mobile-footer {
    position: fixed;
    box-shadow: 0 -2px 20px 2px #e9e9e9;
    bottom: 0px;
    left: 0;
    right: 0; }
    @media (min-width: 768px) {
      .ncl-daypicker-mobile .ncl-daypicker-mobile-footer {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center; } }

.ncl-day-picker-addon {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 992px) {
    .ncl-day-picker-addon.__default {
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .ncl-day-picker-addon.__default {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .ncl-day-picker-addon {
      -webkit-align-items: stretch;
              align-items: stretch;
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-day-picker-addon .ncl-day-picker-addon-date {
    margin-right: 15px; }
    @media (max-width: 767px) {
      .ncl-day-picker-addon .ncl-day-picker-addon-date {
        margin-bottom: 10px;
        margin-right: 0px; } }
  .ncl-day-picker-addon .ncl-day-picker-addon-link {
    font-size: 15px; }

.ncl-datetime-picker {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 992px) {
    .ncl-datetime-picker.__default {
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .ncl-datetime-picker.__default {
      margin-bottom: 20px; } }
  @media (max-width: 767px) {
    .ncl-datetime-picker {
      -webkit-align-items: stretch;
              align-items: stretch;
      -webkit-flex-direction: column;
              flex-direction: column; } }
  @media (max-width: 767px) {
    .ncl-datetime-picker .ncl-date-picker-wrapper {
      margin-bottom: 20px; } }
  .ncl-datetime-picker .ncl-time-picker-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-datetime-picker .ncl-time-picker-wrapper .ncl-date-time-seperator {
      margin-left: 15px;
      margin-right: 15px; }
      @media (max-width: 767px) {
        .ncl-datetime-picker .ncl-time-picker-wrapper .ncl-date-time-seperator {
          margin-left: 0px; } }

.ncl-time-picker {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 992px) {
    .ncl-time-picker.__default {
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .ncl-time-picker.__default {
      margin-bottom: 20px; } }

@media (min-width: 992px) {
  .ncl-day-month-picker.__default {
    margin-bottom: 20px; } }

@media (max-width: 991px) {
  .ncl-day-month-picker.__default {
    margin-bottom: 20px; } }

.ncl-day-month-picker .ncl-form-field {
  margin-right: 15px; }
  @media (max-width: 767px) {
    .ncl-day-month-picker .ncl-form-field {
      margin-bottom: 20px; } }

.ncl-form-field-template.ncl-form-field-ratio-fraction {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-form-field-template.ncl-form-field-ratio-fraction .ncl-form-field-ratio-fraction-seperator {
    padding: 0px 15px; }

.ncl-form-field-template.__default {
  margin-bottom: 20px; }

.ncl-num-format-selector {
  display: -webkit-inline-flex;
  display: inline-flex; }
  .ncl-num-format-selector.__default {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-num-format-selector {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .ncl-num-format-selector .ncl-form-control.input-field {
        margin-bottom: 10px;
        width: 100%; } }
  .ncl-num-format-selector .ncl-num-format-selector-options {
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 768px) {
      .ncl-num-format-selector .ncl-num-format-selector-options {
        margin-left: 10px; } }
    .ncl-num-format-selector .ncl-num-format-selector-options .ncl-non-eyed-radio {
      margin-right: 0px; }
      @media (max-width: 767px) {
        .ncl-num-format-selector .ncl-num-format-selector-options .ncl-non-eyed-radio {
          width: 50%; } }
      .ncl-num-format-selector .ncl-num-format-selector-options .ncl-non-eyed-radio:not(:first-child) {
        margin-left: 5px; }

.ncl-no-data-input {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-no-data-input.__default {
    margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-no-data-input {
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: stretch;
              align-items: stretch; } }
  .ncl-no-data-input .ncl-no-data-input-body {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    margin-right: 15px;
    padding: 0px 15px;
    background-color: #f4f4f4;
    font-style: italic;
    color: #777;
    font-size: 15px;
    height: 34px;
    text-align: center; }
    @media (max-width: 991px) {
      .ncl-no-data-input .ncl-no-data-input-body {
        height: 44px; } }
    @media (max-width: 767px) {
      .ncl-no-data-input .ncl-no-data-input-body {
        margin-bottom: 15px;
        margin-right: 0px; } }

.ncl-tealed-radio-group .ncl-teal-radio {
  border: 1px solid #999;
  font-size: 15px;
  background-color: #fff;
  display: -webkit-inline-flex;
  display: inline-flex;
  padding: 10px 15px;
  height: 32px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: center;
  border-right: none; }
  .ncl-tealed-radio-group .ncl-teal-radio:hover {
    background-color: #e9e9e9;
    cursor: pointer; }
  @media (max-width: 991px) {
    .ncl-tealed-radio-group .ncl-teal-radio {
      height: 44px; } }
  @media (max-width: 767px) {
    .ncl-tealed-radio-group .ncl-teal-radio {
      padding: 10px; } }
  .ncl-tealed-radio-group .ncl-teal-radio .ncl-radio-button-input {
    margin-right: 0px; }
  .ncl-tealed-radio-group .selected.ncl-teal-radio {
    background-color: #009999;
    border: none;
    color: #fff; }
  .ncl-tealed-radio-group .__disabled.ncl-teal-radio {
    border: 1px solid #ccc;
    color: #999;
    cursor: default;
    border-right: none; }
    .ncl-tealed-radio-group .__disabled.ncl-teal-radio:hover {
      background-color: #fff; }
    .ncl-tealed-radio-group .__disabled.selected.ncl-teal-radio {
      background-color: #e9e9e9; }

.ncl-tealed-radio-group {
  display: -webkit-flex;
  display: flex; }
  .ncl-tealed-radio-group.__default {
    margin-bottom: 20px; }
  .ncl-tealed-radio-group input[type="radio"] {
    display: none; }
  .ncl-tealed-radio-group .ncl-teal-radio:first-child {
    border-top-left-radius: 2.5px;
    border-bottom-left-radius: 2.5px; }
  .ncl-tealed-radio-group .ncl-teal-radio:last-child {
    border-top-right-radius: 2.5px;
    border-bottom-right-radius: 2.5px;
    border-right: 1px solid #999; }
  .ncl-tealed-radio-group._custom .ncl-teal-radio {
    height: 40px;
    width: 40px;
    border-radius: 2.5px;
    -webkit-justify-content: center;
            justify-content: center;
    border-right: 1px solid #ccc;
    font-weight: 600;
    -webkit-transition: background-color 0.2s;
    transition: background-color 0.2s; }
    .ncl-tealed-radio-group._custom .ncl-teal-radio._unselected {
      background-color: #ccc;
      color: #fff; }
    .ncl-tealed-radio-group._custom .ncl-teal-radio._selected {
      background-color: #009999;
      color: #fff; }
      .ncl-tealed-radio-group._custom .ncl-teal-radio._selected:not(:first-child) {
        border-left: none; }
    .ncl-tealed-radio-group._custom .ncl-teal-radio.__custom-field {
      border: none;
      background-color: #e9e9e9;
      color: #fff; }
      .ncl-tealed-radio-group._custom .ncl-teal-radio.__custom-field.unselected {
        background-color: #e9e9e9;
        color: #fff; }
      .ncl-tealed-radio-group._custom .ncl-teal-radio.__custom-field.selected {
        background-color: #009999;
        color: #fff; }
        .ncl-tealed-radio-group._custom .ncl-teal-radio.__custom-field.selected:not(:first-child) {
          border-left: none; }
    .ncl-tealed-radio-group._custom .ncl-teal-radio:not(:first-child) {
      margin-left: 10px; }

.ncl-dropdown-with-checkbox {
  display: -webkit-flex;
  display: flex; }
  @media (min-width: 768px) {
    .ncl-dropdown-with-checkbox {
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 767px) {
    .ncl-dropdown-with-checkbox {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-dropdown-with-checkbox .ncl-select-container {
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
    @media (max-width: 767px) {
      .ncl-dropdown-with-checkbox .ncl-select-container {
        margin-bottom: 15px; } }
    @media (min-width: 768px) {
      .ncl-dropdown-with-checkbox .ncl-select-container {
        min-width: 300px; } }
  .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    white-space: nowrap; }
    @media (max-width: 991px) {
      .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox .ncl-default-checkbox {
        font-size: 18px; } }
    .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox .ncl-default-checkbox label:before {
      font-size: 18px;
      width: 18px; }
    @media (max-width: 767px) {
      .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    @media (min-width: 768px) {
      .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox .ncl-dropdown-with-checkbox-seperator {
        margin-left: 20px;
        margin-right: 20px; } }
    @media (max-width: 991px) {
      .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox .ncl-dropdown-with-checkbox-seperator {
        font-size: 18px;
        line-height: 1; } }
    @media (max-width: 767px) {
      .ncl-dropdown-with-checkbox .ncl-dropdown-checkbox .ncl-dropdown-with-checkbox-seperator {
        padding-bottom: 15px; } }

.ncl-form-section-secondary .ncl-form-section-title {
  font-weight: 600;
  color: #339966;
  font-size: 18px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-form-section, .ncl-form-section-secondary, .ncl-form-section-primary {
  clear: both; }

.ncl-form-section-secondary .seperator hr {
  border-top: 1px solid #ccc; }

.ncl-form-section-secondary .ncl-form-section-title {
  padding-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-form-section-secondary .ncl-form-section-title-collapsible:hover {
  cursor: pointer; }

.ncl-form-section-secondary .ncl-form-section-title-collapsible svg.svg-inline--fa {
  -webkit-transition: -webkit-transform 0.5s;
  transition: -webkit-transform 0.5s;
  transition: transform 0.5s;
  transition: transform 0.5s, -webkit-transform 0.5s;
  font-weight: 400;
  font-size: 15px; }
  .ncl-form-section-secondary .ncl-form-section-title-collapsible svg.svg-inline--fa.fa-chevron-down {
    margin-right: 10px; }

.ncl-form-section-secondary .collapse .content {
  border-bottom: 1px solid #ccc;
  margin-bottom: 20px;
  padding-left: 15px; }

.rangeslider {
  margin: 20px 0;
  margin-bottom: 30px;
  position: relative;
  background: #e9e9e9;
  touch-action: none; }

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block; }

.rangeslider .rangeslider__handle {
  background: #339966;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4); }

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1; }

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  -webkit-transition: all 100ms ease-in;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: #fff;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%; }

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0; }

/**
* Rangeslider - Horizontal slider
*/
.rangeslider-horizontal {
  height: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  width: 440px; }
  @media (max-width: 767px) {
    .rangeslider-horizontal {
      height: 15px; } }
  @media (max-width: 450px) {
    .rangeslider-horizontal {
      width: 100%; } }

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background: -webkit-linear-gradient(left, #ccffcc, #339966);
  background: linear-gradient(90deg, #ccffcc, #339966);
  border-radius: 10px;
  top: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly; }
  .rangeslider-horizontal .rangeslider__fill .rangeslider__seperator {
    width: 1px;
    background-color: #fff; }

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0);
  -webkit-transition: all 0.2s;
  transition: all 0.2s; }

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px; }

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  -webkit-transform: translate3d(-50%, 0, 0);
          transform: translate3d(-50%, 0, 0); }

/**
* Rangeslider - Vertical slider
*/
.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent; }

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute; }

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0; }

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none; }

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  -webkit-transform: translate3d(-50%, -50%, 0);
          transform: translate3d(-50%, -50%, 0); }

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  top: 12px; }

/**
* Rangeslider - Reverse
*/
.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0; }

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit; }

/**
* Rangeslider - Labels
*/
.rangeslider__labels {
  position: relative; }
  @media (max-width: 767px) {
    .rangeslider__labels {
      padding-left: 0px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      margin-top: 5px; } }

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px; }

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  -webkit-transform: translate3d(0, -50%, 0);
          transform: translate3d(0, -50%, 0); }

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: #000000;
  position: absolute;
  left: -14px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  z-index: -1; }

@media (min-width: 768px) {
  .rangeslider__labels .rangeslider__label-item {
    position: absolute;
    font-size: 14px;
    cursor: pointer;
    display: inline-block;
    top: 15px;
    -webkit-transform: translate3d(-50%, 0, 0);
            transform: translate3d(-50%, 0, 0); } }

@media (max-width: 767px) {
  .rangeslider__labels .rangeslider__label-item {
    font-size: 12px;
    list-style-type: none;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    margin-top: 15px;
    text-align: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    -webkit-flex-basis: 25%;
            flex-basis: 25%; } }

.rangeslider__handle-label {
  display: none; }

.ncl-list-page-container .ncl-toaster-list-page {
  margin: 0px 0px 20px 0px; }

.ncl-list-page-table {
  display: block;
  font-size: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .ncl-list-page-table .ncl-list-table-employee-options {
    margin-bottom: 20px; }
    .ncl-list-page-table .ncl-list-table-employee-options .ncl-list-table-employee-option:not(:first-child) {
      border-left: 1px solid #ccc;
      padding-left: 10px;
      margin-left: 10px; }
  .ncl-list-page-table .ncl-list-page-rowcount-results {
    clear: both; }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-rowcount-results {
        padding-bottom: 15px; } }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-rowcount-results {
        padding-bottom: 0px; } }
  .ncl-list-page-table .ncl-list-page-reactivate-mobile-button {
    margin-top: 10px; }
  .ncl-list-page-table .ncl-list-page-table-header {
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end; }
    .ncl-list-page-table .ncl-list-page-table-header .ncl-payroll-dashboard-content-header-row {
      padding: 0px 15px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: flex-end;
              align-items: flex-end; }
      @media (min-width: 992px) {
        .ncl-list-page-table .ncl-list-page-table-header .ncl-payroll-dashboard-content-header-row .ncl-list-page-table-header-item {
          width: 14.2%; } }
      .ncl-list-page-table .ncl-list-page-table-header .ncl-payroll-dashboard-content-header-row .ncl-list-page-table-header-item:last-child {
        padding-right: 0; }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-header.ncl-other-scheduled-table-header .ncl-list-page-table-header-item {
        -webkit-flex-basis: 25%;
                flex-basis: 25%; } }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-header.ncl-holiday-table-header .ncl-list-page-table-header-item {
        -webkit-flex-basis: 50%;
                flex-basis: 50%; } }
    .ncl-list-page-table .ncl-list-page-table-header.ncl-timecard-table-header .timecard-row-header-first-column {
      -webkit-flex-basis: 15%;
              flex-basis: 15%; }
    .ncl-list-page-table .ncl-list-page-table-header.ncl-timecard-table-header .ncl-payroll-dashboard-content-header-row {
      -webkit-flex-basis: 80%;
              flex-basis: 80%;
      padding-right: 60px; }
      .ncl-list-page-table .ncl-list-page-table-header.ncl-timecard-table-header .ncl-payroll-dashboard-content-header-row .ncl-list-page-table-header-item {
        -webkit-flex-basis: 16.66%;
                flex-basis: 16.66%; }
        .ncl-list-page-table .ncl-list-page-table-header.ncl-timecard-table-header .ncl-payroll-dashboard-content-header-row .ncl-list-page-table-header-item:not(:last-child) {
          padding-right: 10px; }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-header.ncl-timecard-table-header .ncl-list-page-table-header-item {
        -webkit-flex-basis: 25%;
                flex-basis: 25%; } }
    .ncl-list-page-table .ncl-list-page-table-header.ncl-employee-list-table-header .ncl-list-page-table-header-item:not(:last-child) {
      padding-right: 16px; }
  @media (max-width: 991px) {
    .ncl-list-page-table {
      -webkit-align-items: flex-start;
              align-items: flex-start; }
      .ncl-list-page-table .ncl-list-page-field-header-sm {
        font-weight: 600;
        margin-right: 5px;
        white-space: nowrap; } }
  .ncl-list-page-table .ncl-list-page-table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    padding-top: 20px;
    padding-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-row {
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-table-row-content {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; } }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions {
      font-size: 15px;
      cursor: pointer; }
      @media (min-width: 992px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; } }
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions .list-page-edit-section .list-page-edit-icon,
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions .list-page-edit-section .list-page-delete-icon {
        color: #7b7b7b !important; }
      @media (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions .list-page-edit-section {
          font-size: 18px; } }
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions .list-page-edit-section:not(:last-child) {
        margin-right: 35px; }
        @media (max-width: 767px) {
          .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-grid-table-cell-actions .list-page-edit-section:not(:last-child) {
            margin-right: 30px; } }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-degree-column .ncl-list-page-degree-column-value {
      color: #009999;
      cursor: pointer;
      font-size: 18px !important; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-degree-column .ncl-list-page-degree-description {
      margin-top: 5px;
      font-size: 14px;
      color: #999; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-degree-column .ncl-list-page-degree-verified {
      margin-top: 5px;
      font-size: 14px;
      font-style: italic;
      color: #999; }
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-degree-column .ncl-list-page-degree-verified .fa-check-circle-o {
        margin-right: 5px;
        color: #339966; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item {
      font-size: 15px; }
      @media (min-width: 992px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item {
          padding-right: 10px; } }
      @media (min-width: 320px) and (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item:not(:last-child) {
          padding-bottom: 5px; } }
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item.__no-wrap {
        white-space: nowrap; }
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item.ncl-list-page-name-column {
        color: #009999;
        cursor: pointer; }
        @media (min-width: 320px) and (max-width: 991px) {
          .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item.ncl-list-page-name-column {
            font-size: 18px;
            padding-bottom: 10px; } }
        @media (min-width: 992px) {
          .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item.ncl-list-page-name-column {
            padding-right: 50px !important;
            font-size: 18px; } }
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-item.ncl-list-page-name-column-icon {
          font-size: 18px;
          margin-right: 15px; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-copy-icon {
      -webkit-flex-basis: 4%;
              flex-basis: 4%;
      padding-right: 0;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end; }
      @media (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-copy-icon {
          display: none; } }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-category-container {
        padding-right: 50px !important; } }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-name-column,
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-name-column-icon,
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-category-container {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-name-column-icon .ncl-list-page-column-icon {
      margin-right: 15px;
      display: -webkit-inline-flex;
      display: inline-flex;
      cursor: default; }
      @media (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-name-column-icon .ncl-list-page-column-icon {
          margin-right: 10px; } }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-name-column-icon .ncl-list-page-column-name {
      font-size: 18px;
      cursor: default; }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-status-container {
        margin-bottom: 0px;
        padding-bottom: 0px !important;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-list-page-status-container {
        margin-bottom: -5px; } }
    .ncl-list-page-table .ncl-list-page-table-row:last-child {
      border-bottom: none; }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-upto-name-column {
      padding-right: 20px; }
      @media (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-upto-name-column .ncl-list-page-user-icon-horizontal {
          font-size: 18px; }
          .ncl-list-page-table .ncl-list-page-table-row .ncl-upto-name-column .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-name {
            font-size: inherit; } }
      @media (max-width: 991px) {
        .ncl-list-page-table .ncl-list-page-table-row .ncl-upto-name-column .ncl-default-checkbox label::before {
          font-size: 18px;
          max-width: 18px;
          max-height: 18px; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-upto-row-header-sm {
      -webkit-flex-basis: 15%;
              flex-basis: 15%; }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content {
      -webkit-flex-basis: 80%;
              flex-basis: 80%; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row {
        display: -webkit-flex;
        display: flex; }
        @media (max-width: 991px) {
          .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row {
            -webkit-flex-direction: column;
                    flex-direction: column; } }
        @media (min-width: 992px) {
          .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row {
            -webkit-align-items: center;
                    align-items: center; }
            .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item {
              -webkit-flex-basis: 16.66%;
                      flex-basis: 16.66%; }
            .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item-actions {
              -webkit-flex-basis: 45px;
                      flex-basis: 45px; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .approver-width-column {
      -webkit-flex-basis: 5%;
              flex-basis: 5%; }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-upto-row-header-sm {
          margin-bottom: 10px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-payroll-dashboard-row-item-actions {
          -webkit-justify-content: flex-end;
                  justify-content: flex-end;
          display: -webkit-flex;
          display: flex; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row {
      padding: 15px;
      background-color: #e9e9e9; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item {
        display: -webkit-flex;
        display: flex; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item.ncl-payroll-dashboard-row-item-actions {
          -webkit-justify-content: flex-end;
                  justify-content: flex-end; }
        @media (min-width: 992px) {
          .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item {
            width: 14.2%; }
            .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-list-page-table-row-unapproved-pto-content .ncl-payroll-dashboard-row .ncl-payroll-dashboard-row-item:not(:last-child) {
              padding-right: 15px; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-payroll-dashboard-column-action:not(:last-child) {
      margin-right: 15px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-payroll-dashboard-column-action {
        font-size: 18px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-upto-row .ncl-payroll-dashboard-column-action:not(:last-child) {
          margin-right: 20px; } }
    @media (max-width: 767px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-dependents-type {
        white-space: nowrap; } }
    .ncl-list-page-table .ncl-list-page-table-row .ncl-dependents-type .ncl-badge.status-badge {
      margin-right: 10px; }
    @media (max-width: 464px) {
      .ncl-list-page-table .ncl-list-page-table-row .ncl-dependents-type .ncl-tag.ncl-tag-green {
        margin-top: 5px; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-grid-table-cell-actions {
      -webkit-align-items: flex-start;
              align-items: flex-start; }
    @media (min-width: 992px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details {
        padding-left: 15px; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-name {
      font-size: 18px; }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-timestamp {
      margin-top: 5px; }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-info {
      margin-top: 15px; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-info .ncl-list-page-table-row-item-info-item {
        padding: 0px 10px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-info .ncl-list-page-table-row-item-info-item:not(:first-child) {
          border-left: 1px solid #ccc; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-info .ncl-list-page-table-row-item-info-item:first-child {
          padding-left: 0px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-list-page-table-work-compensation-row .ncl-list-page-table-row-details .ncl-list-page-table-row-item-info .ncl-list-page-table-row-item-info-item i.fa {
          margin-right: 5px; }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-pic {
        margin-right: 20px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-pic img {
          border-radius: 50%; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .ncl-list-page-copy-icon {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-end;
                justify-content: flex-end; }
        @media (min-width: 992px) {
          .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .ncl-list-page-copy-icon {
            display: none; } }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-name {
        color: #009999;
        font-size: 18px;
        padding-left: 0; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-name:hover {
          cursor: pointer; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description {
        color: #999;
        font-size: 14px; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description .user-verified {
          font-style: italic; }
          .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description .user-verified .fa-check-circle-o {
            color: #77e077; }
        .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description span {
          border-right: 1px solid #ccc;
          padding: 0px 5px; }
          .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description span:last-child {
            padding-right: 0px;
            border-right: none; }
          .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .user-icon .user-info .user-description span:first-child {
            padding-left: 0px; }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .ncl-employee-profile-status {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; } }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row .ncl-employee-profile-status .ncl-employee-profile-status-date {
        margin-right: 5px; } }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-employee-profile-row {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    @media (max-width: 991px) {
      .ncl-list-page-table .ncl-list-page-table-row.ncl-other-scheduled-earnings-row:first-child {
        padding-top: 0; } }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-other-scheduled-earnings-row .ncl-list-page-item {
      -webkit-flex-basis: 25%;
              flex-basis: 25%; }
      .ncl-list-page-table .ncl-list-page-table-row.ncl-other-scheduled-earnings-row .ncl-list-page-item .ncl-other-scheduled-amount-description {
        color: #999;
        font-size: 14px; }
    .ncl-list-page-table .ncl-list-page-table-row.ncl-holiday-table-row .ncl-list-page-item {
      -webkit-flex-basis: 50%;
              flex-basis: 50%; }
  .ncl-list-page-table .ncl-list-page-table-row:first-child .ncl-list-page-table-row:last-child {
    border-bottom: none; }
  .ncl-list-page-table.ncl-list-page-table-editable {
    background-color: #e9e9e9;
    padding: 30px; }
    @media (max-width: 991px) {
      .ncl-list-page-table.ncl-list-page-table-editable {
        padding: 20px; } }
    .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-header {
      text-transform: capitalize; }
    .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row {
      -webkit-justify-content: flex-start;
              justify-content: flex-start; }
      .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row:not(:last-child) {
        border-bottom: 1px solid #ccc; }
      @media (max-width: 991px) {
        .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row {
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row .ncl-list-page-table-row-item {
            padding-right: 0; }
            .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row .ncl-list-page-table-row-item.ncl-list-page-table-row-first-item {
              font-size: 18px; } }
      @media (max-width: 991px) and (max-width: 991px) {
        .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row .ncl-list-page-table-row-item.ncl-list-page-table-row-first-item {
          font-weight: 600; } }
      @media (max-width: 991px) {
            .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row .ncl-list-page-table-row-item .ncl-list-page-table-row-item-header-sm {
              margin-bottom: 10px;
              font-weight: 400; }
            .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row .ncl-list-page-table-row-item:not(:last-child) {
              margin-bottom: 20px; } }
      .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row:first-child {
        padding-top: 0px; }
      .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row:last-child {
        padding-bottom: 0px; }
    @media (min-width: 992px) {
      .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row-item:not(:last-child) {
        padding-right: 15px; }
      .ncl-list-page-table.ncl-list-page-table-editable .ncl-list-page-table-row-item:last-child {
        padding-bottom: 0px; } }
  @media (max-width: 991px) {
    .ncl-list-page-table.ncl-list-page-table-job-history .ncl-list-page-rowcount-results {
      margin-bottom: 10px; } }
  .ncl-list-page-table.ncl-list-page-table-job-history .ncl-list-page-table-header {
    margin-bottom: 20px;
    padding: 0px 20px; }
    .ncl-list-page-table.ncl-list-page-table-job-history .ncl-list-page-table-header .ncl-list-page-table-header-item {
      padding: 0px; }
  @media (min-width: 992px) {
    .ncl-list-page-table .approver-width-column {
      width: 5%; } }

.ncl-spinner {
  padding: 6px 12px;
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-basis: 100%;
          flex-basis: 100%; }

.st00 {
  fill: none; }

.st0 {
  fill: #339966; }

.st1 {
  fill: #ffffff; }

.st3 {
  fill: #009999; }
  .st3:hover {
    fill: #0c4f48; }

.st4 {
  fill: #cccccc; }

.st5 {
  fill: #555555; }

.st6 {
  fill: #a3712c; }

.st7 {
  fill: #4762b2; }

.st8 {
  fill: #3d3d3d; }

.st9 {
  fill: #3d4766; }

.st10 {
  fill: #578009; }

.st11 {
  fill: #c53c27; }

.st12 {
  fill: #7bc4d2; }

.st13 {
  fill: #ff9a36; }

.st14 {
  fill: #fb6b5b; }

.st15 {
  fill: #bf9c84; }

.st16 {
  fill: #cc9500; }

.st161 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 2.1073;
  stroke-miterlimit: 10; }

.st162 {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1.9524;
  stroke-miterlimit: 10; }

.st17 {
  fill: #c1a368; }

.st18 {
  fill: #997a24; }

.st19 {
  fill: #542b2b; }

.st20 {
  fill: #e1be6f; }

.st21 {
  fill: #396; }

.st22 {
  fill: #96e58a; }

.ncl-list-page-user-icon-horizontal {
  display: table-row; }
  .ncl-list-page-user-icon-horizontal .ncl-list-page-checkbox {
    padding-right: 20px; }
  .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-name {
        font-size: 20px;
        width: auto; } }
  .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-image {
    display: table-cell;
    margin-right: 10px; }
    .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-image img {
      height: 40px;
      width: 40px;
      border-radius: 24px; }
      @media (max-width: 767px) {
        .ncl-list-page-user-icon-horizontal .ncl-list-page-user-icon-image img {
          height: 30px;
          width: 30px; } }

/*Icons for PTo approve actions*/
.ncl-red {
  color: #fb6b5b; }

.ncl-green {
  color: #339966; }

.MultiSectionTableItem {
  clear: both;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e9e9e9; }
  @media (min-width: 992px) {
    .MultiSectionTableItem {
      display: -webkit-flex;
      display: flex; } }
  .MultiSectionTableItem li {
    list-style-type: none; }
  @media (max-width: 991px) {
    .MultiSectionTableItem .sub-item-container {
      clear: both; } }
  @media (max-width: 991px) {
    .MultiSectionTableItem:first-child {
      padding-top: 10px !important; } }

.MultiSectionTableItem:last-child {
  border-bottom: none;
  padding-bottom: 0; }

@media (max-width: 991px) {
  .leave-type {
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
    padding-bottom: 8px; } }

.pto-available-header {
  width: 15%; }

.pto-future-approved {
  width: 13%; }

.pto-employee {
  width: 19%; }

.unapproved-pto-row-header,
.unapproved-pto-row {
  width: 72%; }

.unapproved-pto-row-header-first-column {
  width: 19%; }

.pto-action {
  width: 13.6%; }

.pto-future-approved {
  width: 12%; }

.pto-available {
  width: 13%; }

.contact-all-approvers .primary-link-button {
  padding-top: 0 !important; }

.ncl-job-history-page-container .table-row {
  clear: both; }

.ncl-job-history-page-container .timestamp-column-header {
  text-align: right;
  padding-right: 0px; }

.ncl-job-history-page-container .timestamp-column-value {
  padding-right: 0px;
  text-align: right; }
  @media (min-width: 320px) and (max-width: 991px) {
    .ncl-job-history-page-container .timestamp-column-value {
      text-align: left; } }

.ncl-job-history-page-container .field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.ncl-job-history-page-container .field-header {
  color: #555;
  font-weight: 600;
  margin-right: 10px;
  min-width: 50px;
  /* DEVIATION : This was required to ensure that the label does not wrap in mobile view  */ }

.ncl-job-history-page-container .user-activity-information {
  font-weight: 600;
  margin-bottom: 10px; }

.ncl-job-history-page-container .ncl-job-history-table {
  padding: 0px;
  margin-bottom: 5px; }
  @media (max-width: 991px) {
    .ncl-job-history-page-container .ncl-job-history-table {
      margin-bottom: 10px; } }
  .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-more-link {
    margin-top: 10px; }
    .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-more-link svg.svg-inline--fa {
      margin-right: 10px; }
  .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-header {
    font-weight: 600;
    padding: 0px 20px;
    margin-bottom: 5px;
    font-size: 14px !important; }
  .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-row {
    background-color: #e9e9e9;
    padding: 10px 20px; }
    .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-row:not(:first-child) {
      margin-top: 5px; }
    @media (max-width: 991px) {
      .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-row .ncl-job-history-table-item {
        display: -webkit-flex;
        display: flex; }
        .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-row .ncl-job-history-table-item:not(:last-child) {
          margin-bottom: 5px; } }
    .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-row .ncl-job-history-table-item i.fa-pencil {
      font-size: 15px;
      color: #fbfbfb; }
  .ncl-job-history-page-container .ncl-job-history-table .ncl-job-history-table-edit-item {
    background-color: #e9e9e9;
    border-top: 1px solid #ccc;
    margin-top: 10px;
    /* margin: 0px -15px; */
    margin-right: -15px; }
  .ncl-job-history-page-container .ncl-job-history-table .tbody {
    display: table-row-group;
    background-color: #e9e9e9; }
    .ncl-job-history-page-container .ncl-job-history-table .tbody .tr {
      display: table-row; }
      .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td {
        display: table-cell;
        padding: 2px 12px 2px 15px; }
        @media (min-width: 320px) and (max-width: 991px) {
          .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td {
            padding: 2px 12px 4px 12px; }
            .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td .field-header {
              display: table-cell; }
            .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td .field-value {
              display: table-cell; } }
  .ncl-job-history-page-container .ncl-job-history-table .thead {
    display: table-header-group; }
    .ncl-job-history-page-container .ncl-job-history-table .thead .tr {
      display: table-row;
      padding-right: 20px;
      padding-left: 20px; }
      .ncl-job-history-page-container .ncl-job-history-table .thead .tr .th {
        display: table-cell;
        border: none !important;
        font-weight: 600;
        padding: 5px 12px 5px 15px !important; }
  .ncl-job-history-page-container .ncl-job-history-table .tbody {
    display: table-row-group;
    background-color: #e9e9e9; }
    .ncl-job-history-page-container .ncl-job-history-table .tbody .tr {
      display: table-row; }
      .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td {
        display: table-cell;
        padding: 2px 12px 2px 15px; }
        @media (min-width: 320px) and (max-width: 991px) {
          .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td {
            padding: 2px 12px 4px 12px; }
            .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td .field-header {
              display: table-cell; }
            .ncl-job-history-page-container .ncl-job-history-table .tbody .tr .td .field-value {
              display: table-cell; } }
  .ncl-job-history-page-container .ncl-job-history-table .table-header {
    font-weight: 700;
    padding: 0px 15px; }
  @media (max-width: 991px) {
    .ncl-job-history-page-container .ncl-job-history-table .table-header + .table-row {
      padding-top: 0px; } }
  .ncl-job-history-page-container .ncl-job-history-table .table-row {
    border-bottom: 1px solid #dbd6d6;
    clear: both;
    margin-right: 0px;
    margin-left: 0px;
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 20px; }
    .ncl-job-history-page-container .ncl-job-history-table .table-row div {
      padding-left: 0px;
      padding-right: 0px; }
    .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr .td {
      padding-left: 15px;
      padding-right: 15px; }
    @media (min-width: 992px) {
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr .td {
        padding-left: 15px;
        padding-right: 15px; }
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr:first-child .td {
        padding-top: 15px; }
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr:last-child .td {
        padding-bottom: 15px; } }
    @media (min-width: 321px) and (max-width: 991px) {
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr .td {
        min-height: 26px; }
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr .td:last-child {
        padding-bottom: 15px; }
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr:first-child .td:first-child {
        padding-top: 15px;
        min-height: 39px; }
      .ncl-job-history-page-container .ncl-job-history-table .table-row .table .tbody .tr:last-child .td:last-child {
        padding-bottom: 15px; } }
    .ncl-job-history-page-container .ncl-job-history-table .table-row .status-name {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 20px; }
      @media (min-width: 320px) and (max-width: 767px) {
        .ncl-job-history-page-container .ncl-job-history-table .table-row .status-name {
          margin-bottom: 5px;
          /* DEVIATION : 5px bottom margin for visual aspect*/ } }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-job-history-page-container .ncl-job-history-table .table-row .status-name {
          /* DEVIATION : 5px bottom margin for visual aspect*/ } }
    .ncl-job-history-page-container .ncl-job-history-table .table-row .edit_note {
      margin-top: 5px; }
  .ncl-job-history-page-container .ncl-job-history-table .table-row:last-child {
    border-bottom: none; }

@media (min-width: 320px) and (max-width: 767px) {
  .ncl-job-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

@media (min-width: 768px) and (max-width: 991px) {
  .ncl-job-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

.ncl-job-details-title {
  font-size: 15px;
  margin-bottom: 20px;
  font-weight: 600; }
  @media (min-width: 992px) {
    .ncl-job-details-title {
      height: 40px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 991px) {
    .ncl-job-details-title {
      margin-top: 10px;
      margin-bottom: 15px;
      font-weight: 600; } }
  @media (min-width: 320px) and (max-width: 767px) {
    .ncl-job-details-title {
      padding-top: 5px; } }

.ncl-job-history-row {
  padding: 20px;
  background: #f8f8f8;
  margin-bottom: 20px;
  border-bottom: 0 !important; }
  .ncl-job-history-row .ncl-timestamp-column-value {
    padding-right: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end; }
    @media (min-width: 992px) {
      .ncl-job-history-row .ncl-timestamp-column-value {
        height: 40px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-job-history-row .ncl-timestamp-column-value {
        text-align: left;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: flex-start;
                justify-content: flex-start; } }
  .ncl-job-history-row .ncl-job-history-page-container .table-row {
    clear: both; }
  .ncl-job-history-row .ncl-job-history-page-container .timestamp-column-header {
    text-align: right;
    padding-right: 0px; }
  .ncl-job-history-row .ncl-job-history-page-container .timestamp-column-value {
    padding-right: 0px;
    text-align: right; }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-job-history-row .ncl-job-history-page-container .timestamp-column-value {
        text-align: left; } }
  .ncl-job-history-row .ncl-job-history-page-container .field-description-row-mobile {
    background-color: #e9e9e9;
    padding: 6px 12px 6px 16px !important;
    margin-bottom: 5px; }
  .ncl-job-history-row .ncl-job-history-page-container .field-header {
    color: #555;
    font-weight: 600;
    margin-right: 10px;
    min-width: 50px;
    /* DEVIATION : This was required to ensure that the label does not wrap in mobile view  */ }
  .ncl-job-history-row .ncl-job-history-page-container .user-activity-information {
    font-weight: 600;
    margin-bottom: 10px; }
  .ncl-job-history-row .ncl-job-history-page-container .table {
    display: table;
    padding: 0px;
    border-collapse: separate;
    width: 100%;
    max-width: 100%; }
    .ncl-job-history-row .ncl-job-history-page-container .table .result-rowcount {
      clear: both;
      margin-bottom: 15px; }
    .ncl-job-history-row .ncl-job-history-page-container .table .thead {
      display: table-header-group; }
      .ncl-job-history-row .ncl-job-history-page-container .table .thead .tr {
        display: table-row; }
        .ncl-job-history-row .ncl-job-history-page-container .table .thead .tr .th {
          display: table-cell;
          border: none !important;
          font-weight: 600;
          padding: 10px 20px 10px 20px !important; }
    .ncl-job-history-row .ncl-job-history-page-container .table .tbody {
      display: table-row-group;
      background-color: #e9e9e9; }
      .ncl-job-history-row .ncl-job-history-page-container .table .tbody .tr {
        display: table-row;
        padding-left: 14px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .tbody .tr .td {
          display: table-cell;
          padding: 2px 12px 2px 12px; }
          @media (min-width: 320px) and (max-width: 991px) {
            .ncl-job-history-row .ncl-job-history-page-container .table .tbody .tr .td {
              padding: 2px 12px 4px 12px; }
              .ncl-job-history-row .ncl-job-history-page-container .table .tbody .tr .td .field-header {
                display: table-cell; }
              .ncl-job-history-row .ncl-job-history-page-container .table .tbody .tr .td .field-value {
                display: table-cell; } }
    .ncl-job-history-row .ncl-job-history-page-container .table .table-header {
      font-weight: 700; }
    @media (max-width: 991px) {
      .ncl-job-history-row .ncl-job-history-page-container .table .table-header + .table-row {
        padding-top: 0px; } }
    .ncl-job-history-row .ncl-job-history-page-container .table .table-row {
      border-bottom: 1px solid #dbd6d6;
      clear: both;
      margin-right: 0px;
      margin-left: 0px;
      padding-top: 20px;
      padding-left: 0px;
      padding-right: 0px;
      padding-bottom: 20px; }
      .ncl-job-history-row .ncl-job-history-page-container .table .table-row div {
        padding-left: 0px;
        padding-right: 0px; }
      .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr .td {
        padding-left: 15px;
        padding-right: 15px; }
      @media (min-width: 992px) {
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr .td {
          padding-left: 15px;
          padding-right: 15px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr:first-child .td {
          padding-top: 15px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr:last-child .td {
          padding-bottom: 15px; } }
      @media (min-width: 321px) and (max-width: 991px) {
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr .td {
          min-height: 26px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr .td:last-child {
          padding-bottom: 15px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr:first-child .td:first-child {
          padding-top: 15px;
          min-height: 39px; }
        .ncl-job-history-row .ncl-job-history-page-container .table .table-row .table .tbody .tr:last-child .td:last-child {
          padding-bottom: 15px; } }
      .ncl-job-history-row .ncl-job-history-page-container .table .table-row .status-name {
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 20px; }
        @media (min-width: 320px) and (max-width: 767px) {
          .ncl-job-history-row .ncl-job-history-page-container .table .table-row .status-name {
            margin-bottom: 5px;
            /* DEVIATION : 5px bottom margin for visual aspect*/ } }
        @media (min-width: 768px) and (max-width: 991px) {
          .ncl-job-history-row .ncl-job-history-page-container .table .table-row .status-name {
            /* DEVIATION : 5px bottom margin for visual aspect*/ } }
      .ncl-job-history-row .ncl-job-history-page-container .table .table-row .edit_note {
        margin-top: 5px; }
    .ncl-job-history-row .ncl-job-history-page-container .table .table-row:last-child {
      border-bottom: none; }
  @media (min-width: 320px) and (max-width: 767px) {
    .ncl-job-history-row .ncl-job-history-page-container .table:last-child {
      margin-bottom: 5px;
      /* DEVIATION : 5px bottom margin for visual aspect*/ } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-job-history-row .ncl-job-history-page-container .table:last-child {
      margin-bottom: 5px;
      /* DEVIATION : 5px bottom margin for visual aspect*/ } }

.ncl-user-icon-horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-user-icon-horizontal .user-icon-name {
    color: #555;
    font-size: 15px;
    font-weight: 400;
    padding-right: 20px;
    padding-right: 20px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-user-icon-horizontal .user-icon-name {
        font-size: 20px !important;
        width: auto; } }
  .ncl-user-icon-horizontal .user-icon-image {
    height: 38px;
    margin-right: 15px;
    width: 38px; }
    @media (max-width: 767px) {
      .ncl-user-icon-horizontal .user-icon-image {
        width: 30px;
        height: 30px;
        margin-right: 10px; } }
    .ncl-user-icon-horizontal .user-icon-image img {
      height: 37px;
      border-radius: 24px;
      margin-right: 15px; }
      @media (max-width: 767px) {
        .ncl-user-icon-horizontal .user-icon-image img {
          width: 30px;
          height: 30px; } }

.ncl-activity-history-page-container .export-excel-button {
  float: right; }

.ncl-activity-history-page-container .ncl-navigation-button-container {
  clear: both;
  margin-left: 0px;
  margin-right: 0px;
  margin-bottom: 20px; }

.ncl-activity-history-page-container .ncl-activity-history-table-row {
  clear: both;
  padding-bottom: 20px;
  padding-top: 20px;
  border-bottom: 1px solid #e9e9e9; }
  .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-status-name {
    font-size: 15px;
    font-weight: 600; }
    @media (min-width: 992px) {
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-status-name {
        height: 38px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
        .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-status-name .ncl-activity-history-status-name-content {
          margin-left: 5px; } }
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-status-name svg.svg-inline--fa {
      margin-right: 5px; }
    @media (max-width: 991px) {
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-status-name {
        margin-top: 10px;
        margin-bottom: 15px;
        /* DEVIATION : 5px bottom margin for visual aspect*/ } }
  .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .header {
    font-weight: 600;
    padding: 5px 12px 5px 12px !important; }
  .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .header-sm {
    font-weight: 600; }
    @media (max-width: 991px) {
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .header-sm {
        float: left; } }
  @media (max-width: 991px) {
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .header-field-value-sm {
      padding-left: 50px; } }
  .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .ncl-activity-history-log-header {
    padding: 15px 15px 0px 15px !important;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 14px; }
  .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content {
    clear: both; }
    @media (max-width: 991px) {
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content {
        margin-bottom: 10px; } }
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .ncl-activity-history-log-row {
      background-color: #e9e9e9;
      padding: 15px 15px 15px 15px !important; }
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .ncl-activity-history-log-row .ncl-activity-history-log-row-item {
        padding-right: 15px; }
        @media (max-width: 991px) {
          .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .ncl-activity-history-log-row .ncl-activity-history-log-row-item {
            display: -webkit-flex;
            display: flex; }
            .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .ncl-activity-history-log-row .ncl-activity-history-log-row-item:not(:last-child) {
              margin-bottom: 5px; } }
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .row-sm {
      padding-bottom: 15px; }
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .row-sm:last-child {
      padding-bottom: 0px; }
    .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .seperator hr {
      height: 2px;
      background-color: #fff;
      margin: 0px -15px 15px -15px; }
    @media (max-width: 991px) {
      .ncl-activity-history-page-container .ncl-activity-history-table-row .ncl-activity-history-table .content .field-sm {
        padding-bottom: 6px; } }

.ncl-activity-history-page-container .ncl-result-rowcount {
  clear: both;
  margin-bottom: 15px; }

.ncl-activity-history-page-container .table-row {
  clear: both; }

.ncl-activity-history-page-container .ncl-timestamp-column-header {
  padding-right: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  @media (max-width: 991px) {
    .ncl-activity-history-page-container .ncl-timestamp-column-header {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }

.ncl-activity-history-page-container .ncl-timestamp-column-value {
  padding-right: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  @media (min-width: 992px) {
    .ncl-activity-history-page-container .ncl-timestamp-column-value {
      height: 38px;
      -webkit-align-items: center;
              align-items: center; } }
  @media (min-width: 320px) and (max-width: 991px) {
    .ncl-activity-history-page-container .ncl-timestamp-column-value {
      text-align: left;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-start;
              justify-content: flex-start; } }

.ncl-activity-history-page-container .field-description-row-mobile {
  background-color: #e9e9e9;
  padding: 6px 12px 6px 16px !important;
  margin-bottom: 5px; }

.ncl-activity-history-page-container .field-header {
  color: #555;
  font-weight: 600;
  margin-right: 10px;
  min-width: 50px;
  /* DEVIATION : This was required to ensure that the label does not wrap in mobile view  */ }
  @media (max-width: 991px) {
    .ncl-activity-history-page-container .field-header {
      white-space: nowrap; } }

.ncl-activity-history-page-container .ncl-user-activity-information {
  font-weight: 600;
  margin-bottom: 10px; }

.ncl-activity-history-page-container .table {
  display: -webkit-flex;
  display: flex;
  padding: 0px;
  border-collapse: separate;
  border-spacing: 0px 2px;
  margin-bottom: 0px;
  width: 100%;
  max-width: 100%; }
  .ncl-activity-history-page-container .table .thead {
    display: table-header-group; }
    .ncl-activity-history-page-container .table .thead .tr {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .ncl-activity-history-page-container .table .thead .tr .th {
        display: table-cell;
        border: none !important;
        font-weight: 600; }
  .ncl-activity-history-page-container .table .tbody {
    display: table-row-group;
    background-color: #e9e9e9; }
    .ncl-activity-history-page-container .table .tbody .tr {
      display: -webkit-flex;
      display: flex; }
      .ncl-activity-history-page-container .table .tbody .tr .td {
        display: -webkit-flex;
        display: flex; }
        @media (min-width: 320px) and (max-width: 991px) {
          .ncl-activity-history-page-container .table .tbody .tr .td {
            padding: 2px 12px 4px 12px; }
            .ncl-activity-history-page-container .table .tbody .tr .td .field-header {
              display: table-cell; }
            .ncl-activity-history-page-container .table .tbody .tr .td .field-value {
              display: table-cell; } }
  .ncl-activity-history-page-container .table .table-header {
    font-weight: 700; }
  @media (max-width: 991px) {
    .ncl-activity-history-page-container .table .table-header + .table-row {
      padding-top: 0px; } }
  .ncl-activity-history-page-container .table .ncl-activity-history-table-row:last-child {
    border-bottom: none; }

@media (min-width: 320px) and (max-width: 767px) {
  .ncl-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

@media (min-width: 768px) and (max-width: 991px) {
  .ncl-activity-history-page-container .table:last-child {
    margin-bottom: 5px;
    /* DEVIATION : 5px bottom margin for visual aspect*/ } }

.ncl-activity-history-page-container::before {
  display: table;
  content: " "; }

.ncl-activity-history-page-container::after {
  display: table;
  content: " ";
  clear: both; }

.modal#activity-history {
  padding-left: 0px !important; }
  .modal#activity-history .modal-dialog {
    width: 700px; }
    @media (max-width: 767px) {
      .modal#activity-history .modal-dialog {
        margin: 20px;
        width: auto !important; } }
    .modal#activity-history .modal-dialog .modal-content {
      border-radius: 0px;
      box-shadow: none; }
      .modal#activity-history .modal-dialog .modal-content hr {
        margin-top: 0px;
        margin-bottom: 20px !important; }
      @media (min-width: 768px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 30px; } }
      @media (max-width: 767px) {
        .modal#activity-history .modal-dialog .modal-content {
          padding: 20px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-header {
        border-bottom: none;
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .modal-title-description {
          font-size: 12px;
          margin-bottom: 20px; }
        .modal#activity-history .modal-dialog .modal-content .modal-header .ncl-close-icon {
          cursor: pointer;
          text-align: right; }
          @media (min-width: 768px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .ncl-close-icon {
              margin-top: -15px;
              margin-left: 15px; } }
          @media (max-width: 767px) {
            .modal#activity-history .modal-dialog .modal-content .modal-header .ncl-close-icon {
              margin-top: 0px;
              margin-left: 0px; } }
          .modal#activity-history .modal-dialog .modal-content .modal-header .ncl-close-icon i {
            margin-top: 0px;
            margin-right: 0px; }
      .modal#activity-history .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        .modal#activity-history .modal-dialog .modal-content .modal-body .ncl-activity-history-modal-row {
          margin-bottom: 20px; }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-body div:last-child .ncl-activity-history-modal-row:last-child {
            margin-bottom: 0px; } }
      .modal#activity-history .modal-dialog .modal-content .modal-footer {
        padding: 20px 0px 0px 0px; }
        @media (max-width: 767px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: center; } }
        @media (min-width: 768px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            text-align: left; } }
        @media (min-width: 992px) {
          .modal#activity-history .modal-dialog .modal-content .modal-footer {
            display: none; } }

.ncl-employee-info-entity-table {
  background-color: #e9e9e9;
  padding: 20px;
  clear: both;
  margin-bottom: 20px; }
  @media (max-width: 767px) {
    .ncl-employee-info-entity-table {
      display: none; } }
  .ncl-employee-info-entity-table .ncl-entity-info-item-header {
    font-weight: 600;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end; }
  .ncl-employee-info-entity-table .ncl-entity-info-item {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-employee-info-entity-table .ncl-entity-info-item .ncl-entity-column {
      padding-right: 30px; }
  .ncl-employee-info-entity-table .footer {
    padding-top: 15px;
    color: #999; }

.ncl-page-container, .ncl-list-page-container, .ncl-entity-page-container, .ncl-payroll-dashboard-container {
  height: 100%;
  position: relative;
  padding: 30px 55px;
  color: #555;
  font-family: inherit; }
  @media (min-width: 320px) and (max-width: 767px) {
    .ncl-page-container, .ncl-list-page-container, .ncl-entity-page-container, .ncl-payroll-dashboard-container {
      padding: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-page-container, .ncl-list-page-container, .ncl-entity-page-container, .ncl-payroll-dashboard-container {
      padding: 20px; } }

.ncl-list-page-container .ncl-toaster {
  margin-bottom: 20px; }

.ncl-entity-page-container .ncl-entity-page-content .ncl-entity-page-content-switch-wrapper .ncl-summary-box {
  margin-bottom: 20px; }

@media (min-width: 992px) {
  .ncl-entity-page-container .ncl-entity-page-content .ncl-entity-page-content-switch-wrapper {
    padding-right: 20px; } }

@media (max-width: 991px) {
  .ncl-entity-page-container .ncl-entity-page-content {
    clear: both; } }

@media (max-width: 767px) {
  .ncl-entity-page-container .ncl-entity-page-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
    .ncl-entity-page-container .ncl-entity-page-content .__reverse {
      -webkit-flex-direction: column-reverse;
              flex-direction: column-reverse; } }

.ncl-entity-page-container .ncl-entity-page-content .ncl-entity-step-content {
  padding-top: 20px; }
  @media (min-width: 992px) {
    .ncl-entity-page-container .ncl-entity-page-content .ncl-entity-step-content .ncl-entity-page-content-switch-wrapper {
      padding-right: 20px; } }

@media (max-width: 767px) {
  .ncl-entity-page-container .ncl-button-toolbar {
    padding-bottom: 20px; } }

.ncl-entity-page-container .ncl-toaster {
  margin-bottom: 20px; }

.ncl-entity-page-container .ncl-title-band {
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-entity-page-container .ncl-title-band.__no-margin-sm {
      margin-bottom: 0; } }

.ncl-entity-page-container .ncl-step-title-container {
  padding-bottom: 20px;
  border-bottom: 1px solid #ccc; }

@media (min-width: 992px) {
  .ncl-entity-page-container .ncl-progress-tracker-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    margin-bottom: 20px; } }

.ncl-employee-feature-container {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  padding: 40px;
  padding-top: 72px; }
  @media (max-width: 991px) {
    .ncl-employee-feature-container {
      padding: 0; } }
  .ncl-employee-feature-container h3 {
    margin-top: 30px; }
    @media (max-width: 991px) {
      .ncl-employee-feature-container h3 {
        margin-left: -5px; } }

@media (max-width: 992px) {
  .ncl-profile-nav-container {
    padding: 0; } }

@media (min-width: 992px) {
  .ncl-profile-nav-container {
    display: -webkit-flex;
    display: flex; } }

.ncl-custom-fields-container {
  display: -webkit-flex;
  display: flex;
  background-color: #e9e9e9; }
  @media (max-width: 991px) {
    .ncl-custom-fields-container {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.ncl-progress-page-container {
  padding: 70px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 991px) {
    .ncl-progress-page-container {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  @media (min-width: 992px) {
    .ncl-progress-page-container .ncl-progress-tracker-checkpoints-list {
      margin-left: 100px; } }
  @media (max-width: 991px) {
    .ncl-progress-page-container .ncl-progress-tracker-checkpoints-list {
      margin-top: 30px; } }

.ncl-sortable-list-item._locked:hover {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 512 512' width='20px' height='20px' %3E%3Cpath fill='%23555' d='M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z'/%3E%3C/svg%3E"), pointer; }

.ncl-sortable-list .ncl-sortable-list-header {
  display: -webkit-flex;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  text-transform: uppercase;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  padding-left: 30px;
  padding-right: 30px; }
  .ncl-sortable-list .ncl-sortable-list-header .ncl-sortable-list-header-items {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-end;
            align-items: flex-end; }

.ncl-sortable-list .ncl-sortable-list-item:not(:first-child) {
  margin-top: 5px; }

.ncl-sortable-list-item {
  font-size: 15px;
  border: 1px solid #999;
  background-color: #fff;
  border-radius: 4px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 30px;
  padding-right: 30px; }
  @media (min-width: 768px) {
    .ncl-sortable-list-item {
      height: 44px; } }
  @media (max-width: 767px) {
    .ncl-sortable-list-item {
      padding: 10px 15px; } }
  .ncl-sortable-list-item:hover {
    cursor: move; }
  .ncl-sortable-list-item i {
    color: #7b7b7b; }

.ncl-complex-checkbox-list {
  padding: 0;
  margin: 0;
  margin-bottom: 20px; }
  .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item {
    display: -webkit-flex;
    display: flex;
    font-size: 15px; }
    .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-default-checkbox label::before {
      margin-right: 0px;
      height: 15px; }
      @media (max-width: 991px) {
        .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-default-checkbox label::before {
          font-size: 18px; } }
    .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-complex-checkbox-list-item-label {
      font-weight: 600; }
      @media (max-width: 767px) {
        .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-complex-checkbox-list-item-label {
          margin-bottom: 10px; }
          .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-complex-checkbox-list-item-label .ncl-complex-checkbox-list-item-description {
            font-weight: 400;
            margin-top: 10px; } }
      @media (max-width: 991px) {
        .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-complex-checkbox-list-item-label {
          font-size: 18px; } }
      @media (max-width: 767px) {
        .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item .ncl-complex-checkbox-list-item-label .ncl-form-field {
          margin-top: 10px;
          font-weight: 400; } }
    .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item:not(:first-child) {
      margin-top: 15px; }
  @media (min-width: 768px) {
    .ncl-complex-checkbox-list .ncl-complex-checkbox-list-item-description {
      margin-right: 10px; } }

.ncl-card-vertical {
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0 #cccccc73; }
  @media (min-width: 992px) {
    .ncl-card-vertical {
      width: 275px;
      height: 370px; } }
  @media (max-width: 991px) {
    .ncl-card-vertical {
      padding: 18px; } }
  @media (min-width: 992px) {
    .ncl-card-vertical.__wide {
      width: 425px; } }
  .ncl-card-vertical.__default {
    margin-bottom: 25px; }
    @media (min-width: 992px) {
      .ncl-card-vertical.__default {
        margin-right: 25px; } }
  .ncl-card-vertical .ncl-card-vertical-header {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-card-vertical .ncl-card-vertical-header .ncl-card-title {
      padding-top: 0;
      margin-left: 15px; }
  .ncl-card-vertical .ncl-card-title {
    padding-top: 25px;
    font-size: 20px;
    color: #333; }
    @media (max-width: 991px) {
      .ncl-card-vertical .ncl-card-title {
        font-size: 20px; } }
  .ncl-card-vertical .ncl-card-description {
    color: #777;
    font-size: 14px;
    padding-top: 20px; }
  .ncl-card-vertical .ncl-card-body {
    margin-top: 20px;
    border-top: 1px solid #777; }
    .ncl-card-vertical .ncl-card-body .ncl-card-list {
      list-style-type: none;
      padding: 0;
      margin-bottom: 0;
      padding-top: 20px; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-list-item:not(:first-child) {
        padding-top: 15px; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-list-item a {
        font-weight: 400; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-item:not(:first-child) {
        border-top: 1px solid #ccc;
        margin-top: 20px;
        padding-top: 20px; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-item .ncl-card-item-title {
        margin-left: 15px;
        font-size: 25px; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-item .ncl-card-item-description {
        color: #777;
        font-size: 15px;
        padding-top: 20px; }
      .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-item .ncl-card-item-link {
        margin-top: 20px;
        font-size: 18px;
        font-size: 15px; }
        .ncl-card-vertical .ncl-card-body .ncl-card-list .ncl-card-item .ncl-card-item-link a {
          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
                  align-items: center; }

.ncl-card-horizontal {
  padding: 25px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0 #cccccc;
  width: 360px; }
  .ncl-card-horizontal.__default {
    margin-right: 25px;
    margin-bottom: 25px; }
  .ncl-card-horizontal .ncl-card-title {
    font-size: 25px; }
  .ncl-card-horizontal .ncl-card-description {
    padding-top: 10px;
    color: #777; }
  .ncl-card-horizontal .ncl-card-body {
    padding-top: 20px; }
    .ncl-card-horizontal .ncl-card-body .ncl-card-item-link a {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .ncl-card-horizontal .ncl-card-body .ncl-card-list {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 0; }
      .ncl-card-horizontal .ncl-card-body .ncl-card-list .ncl-card-list-item {
        margin-bottom: 5px; }
        .ncl-card-horizontal .ncl-card-body .ncl-card-list .ncl-card-list-item a {
          font-weight: 600; }

.ncl-card-horizontal-sm {
  padding: 20px 15px;
  border-radius: 10px;
  background-color: #fff; }
  .ncl-card-horizontal-sm.__active {
    box-shadow: 2px 2px 6px 0 #cccccc; }
  @media (min-width: 992px) {
    .ncl-card-horizontal-sm {
      width: 200px;
      height: 106px; } }
  @media (max-width: 991px) {
    .ncl-card-horizontal-sm {
      display: -webkit-flex;
      display: flex;
      padding: 10px 15px;
      -webkit-align-items: center;
              align-items: center;
      min-height: 60px; } }
  .ncl-card-horizontal-sm.__default {
    margin-bottom: 25px; }
  .ncl-card-horizontal-sm .ncl-card-title {
    font-size: 15px; }
    .ncl-card-horizontal-sm .ncl-card-title:not(:last-child) {
      margin-bottom: 10px; }
      @media (max-width: 991px) {
        .ncl-card-horizontal-sm .ncl-card-title:not(:last-child) {
          margin-bottom: 5px; } }
  .ncl-card-horizontal-sm .ncl-card-description {
    font-size: 13px;
    padding-top: 3px;
    color: #999;
    font-style: italic; }
    @media (max-width: 991px) {
      .ncl-card-horizontal-sm .ncl-card-description {
        padding-top: 0px; } }
  @media (min-width: 992px) {
    .ncl-card-horizontal-sm .ncl-card-toggle-wrapper {
      margin-top: 10px; } }
  @media (max-width: 991px) {
    .ncl-card-horizontal-sm .ncl-card-toggle-wrapper {
      margin-right: 20px; } }
  @media (min-width: 992px) {
    .ncl-card-horizontal-sm .ncl-card-body {
      margin-top: 10px; } }

.ncl-card-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-bottom: -25px;
  margin-left: -25px; }
  @media (max-width: 991px) {
    .ncl-card-wrapper {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-card-wrapper .ncl-card-vertical,
  .ncl-card-wrapper .ncl-card-horizontal {
    margin-bottom: 25px;
    margin-left: 25px; }

.ncl-filter-mobile .ncl-filter-mobile-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-filter-mobile .ncl-filter-mobile-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-filter-mobile .ncl-filter-mobile-header .ncl-close-icon {
        left: 10px; } }
  .ncl-filter-mobile .ncl-filter-mobile-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-filter-mobile .ncl-filter-mobile-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-filter-mobile .ncl-filter-mobile-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-filter-mobile .ncl-filter-mobile-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-filter-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  min-height: 100vh;
  z-index: 1050; }
  .ncl-filter-mobile .ncl-filter-mobile-header .ncl-filter-mobile-links {
    position: absolute;
    right: 20px;
    display: -webkit-flex;
    display: flex; }
    @media (max-width: 767px) {
      .ncl-filter-mobile .ncl-filter-mobile-header .ncl-filter-mobile-links {
        right: 10px; } }
    .ncl-filter-mobile .ncl-filter-mobile-header .ncl-filter-mobile-links .ncl-filter-mobile-link {
      font-size: 16px; }
      .ncl-filter-mobile .ncl-filter-mobile-header .ncl-filter-mobile-links .ncl-filter-mobile-link:not(:first-child) {
        margin-left: 20px; }
  .ncl-filter-mobile .ncl-filter-mobile-footer {
    position: fixed;
    padding: 15px 10px;
    height: 82px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    box-shadow: 0 -2px 20px 2px #e9e9e9;
    bottom: 0;
    left: 0;
    right: 0; }
    .ncl-filter-mobile .ncl-filter-mobile-footer .ncl-button {
      min-height: 40px; }
      @media (max-width: 767px) {
        .ncl-filter-mobile .ncl-filter-mobile-footer .ncl-button {
          width: 100%; } }

.ncl-filter-accordion .ncl-accordion-title {
  position: relative; }
  .ncl-filter-accordion .ncl-accordion-title .ncl-filter-badge {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center; }

.ncl-filter-accordion .ncl-dropdown-search-field {
  margin-bottom: 10px; }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-filter-accordion .ncl-dropdown-search-field {
      padding: 0px 20px;
      padding-top: 20px; } }
  @media (max-width: 767px) {
    .ncl-filter-accordion .ncl-dropdown-search-field {
      padding: 0px 10px;
      padding-top: 10px; } }
  .ncl-filter-accordion .ncl-dropdown-search-field input {
    width: 100%; }

.ncl-filter-mobile-custom-panel-bar {
  border-top: 1px solid #ccc;
  padding: 10px 15px;
  padding-top: 20px;
  height: 40px;
  font-size: 18px;
  color: #555; }
  .ncl-filter-mobile-custom-panel-bar .ncl-default-checkbox .svg-inline--fa {
    margin-right: 10px; }

.ncl-sort-mobile .ncl-sort-mobile-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-sort-mobile .ncl-sort-mobile-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-sort-mobile .ncl-sort-mobile-header .ncl-close-icon {
        left: 10px; } }
  .ncl-sort-mobile .ncl-sort-mobile-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-sort-mobile .ncl-sort-mobile-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-sort-mobile .ncl-sort-mobile-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-sort-mobile .ncl-sort-mobile-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-sort-mobile {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  min-height: 100vh;
  z-index: 1050; }
  .ncl-sort-mobile .ncl-sort-row {
    color: #555;
    border-bottom: 1px solid #e9e9e9;
    height: 44px;
    font-size: 16px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    padding-left: 20px; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-sort-mobile .ncl-sort-row {
        padding-left: 20px; } }
    @media (max-width: 767px) {
      .ncl-sort-mobile .ncl-sort-row {
        padding-left: 10px; } }
    .ncl-sort-mobile .ncl-sort-row.__active {
      background-color: #ccffff;
      font-weight: 600; }

.ncl-filter-container {
  background-color: #e6e6e6;
  margin-bottom: 20px;
  clear: both;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch; }
  .ncl-filter-container .advanced-filter-links {
    font-size: 15px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    white-space: nowrap; }
    .ncl-filter-container .advanced-filter-links a {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      margin-right: 20px; }
      .ncl-filter-container .advanced-filter-links a i.fa {
        margin-right: 5px; }
      .ncl-filter-container .advanced-filter-links a:last-child {
        margin-right: 0; }
  @media (min-width: 992px) {
    .ncl-filter-container {
      padding: 6px 15px; } }
  .ncl-filter-container .ncl-filter-group {
    -webkit-flex-basis: 86%;
            flex-basis: 86%;
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding-left: 74.19px; }
    .ncl-filter-container .ncl-filter-group .ncl-filter-row {
      margin-top: 13px;
      display: -webkit-flex;
      display: flex; }
      .ncl-filter-container .ncl-filter-group .ncl-filter-row:first-child {
        margin-top: 0px; }

.ncl-filter-dropdown {
  min-width: 160px;
  max-width: 300px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  padding-right: 15px;
  position: relative; }
  .ncl-filter-dropdown .ncl-dropdown-no-data-section {
    width: 100%; }

.ncl-more-filter-link {
  white-space: nowrap;
  color: #009999;
  font-weight: 400;
  height: 34px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-filter-showing-label {
  position: absolute;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 1px;
  color: #555;
  padding-right: 15px;
  display: -webkit-flex;
  display: flex;
  height: 34px;
  -webkit-align-items: center;
          align-items: center; }

@media (min-width: 320px) and (max-width: 991px) {
  .ncl-sort-filter-container {
    padding: 8px 12px;
    background-color: #e9e9e9;
    height: 44px;
    margin-bottom: 20px; }
    .ncl-sort-filter-container button {
      padding-top: 5px;
      padding-right: 10px;
      padding-bottom: 5px;
      padding-left: 10px;
      color: #009999;
      font-weight: 600;
      border: 1px solid #009999;
      background-color: #ffffff;
      width: 50%; }
    .ncl-sort-filter-container button:first-child {
      border-top-left-radius: 2.5px;
      border-bottom-left-radius: 2.5px;
      border-right: 0px; }
    .ncl-sort-filter-container button:last-child {
      border-top-right-radius: 2.5px;
      border-bottom-right-radius: 2.5px; } }

.dropdown-with-search {
  max-width: 820px;
  margin-top: 5px !important;
  border-radius: 0px !important;
  box-shadow: none !important; }

.no-margin {
  margin-left: 0 !important;
  margin-right: 0 !important; }

.no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important; }

.daterangepicker_calendar_button .input-group-btn button {
  height: unset; }

.ncl-side-nav {
  max-height: 100vh;
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  -webkit-align-self: flex-start;
          align-self: flex-start;
  background-color: #e9e9e9;
  -webkit-flex: 0 0 290px;
          flex: 0 0 290px; }
  .ncl-side-nav .ncl-side-nav-back-link {
    padding: 20px 25px;
    margin-top: 45px; }
  .ncl-side-nav .ncl-side-nav-card-title {
    padding: 0px 25px;
    padding-bottom: 15px;
    font-size: 21px; }
  .ncl-side-nav .ncl-side-nav-panel-container {
    border-bottom: 1px solid #ccc; }
    .ncl-side-nav .ncl-side-nav-panel-container a .panel-header {
      font-size: 15px;
      font-weight: 400;
      padding: 20px 25px 15px 25px;
      color: #333;
      background-color: none !important;
      border: none;
      cursor: pointer; }
      .ncl-side-nav .ncl-side-nav-panel-container a .panel-header.__active-panel-header {
        background-color: #abdeab;
        font-weight: 600; }
      .ncl-side-nav .ncl-side-nav-panel-container a .panel-header i {
        float: right; }
    .ncl-side-nav .ncl-side-nav-panel-container a:last-child .panel-header {
      margin-bottom: 0; }
    .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel {
      border: none;
      margin-bottom: 0; }
      .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body {
        border: none;
        padding: 0;
        background-color: #e9e9e9; }
        .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body-container {
          display: -webkit-flex;
          display: flex;
          background-color: #abdeab; }
          .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body-container .scroll-bar {
            margin-left: 29px;
            margin-top: 10px;
            margin-bottom: 20px;
            -webkit-transition: -webkit-transform 0.2s;
            transition: -webkit-transform 0.2s;
            transition: transform 0.2s;
            transition: transform 0.2s, -webkit-transform 0.2s;
            display: -webkit-flex;
            display: flex;
            -webkit-justify-content: center;
                    justify-content: center; }
            .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body-container .scroll-bar .scroll-track {
              width: 2px;
              background-color: #7bad8e; }
            .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body-container .scroll-bar .scroll-handle {
              position: absolute;
              width: 8px;
              background-color: #0c4f48;
              height: 30px;
              border-radius: 10px;
              -webkit-transform: translate(0px, 0px);
                      transform: translate(0px, 0px); }
              .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body-container .scroll-bar .scroll-handle:hover {
                cursor: pointer; }
        .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body ul.entity-list {
          margin-bottom: 15px;
          padding: 0;
          list-style-type: none;
          font-size: 14px; }
          .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body ul.entity-list li.entity-list-item {
            padding: 10px 25px;
            text-transform: uppercase; }
            .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body ul.entity-list li.entity-list-item:hover {
              cursor: pointer; }
            .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body ul.entity-list li.entity-list-item a {
              color: #333; }
            .ncl-side-nav .ncl-side-nav-panel-container .ncl-side-nav-panel .panel-body ul.entity-list li.entity-list-item.active-item a {
              font-weight: 600; }

.ncl-side-nav-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  background-color: #f4f4f4;
  z-index: 1050;
  padding: 45px 30px;
  overflow-y: auto; }
  @media (max-width: 767px) {
    .ncl-side-nav-overlay {
      padding: 45px 10px; } }
  .ncl-side-nav-overlay .ncl-side-nav-overlay-close-icon {
    font-size: 25px;
    color: #999;
    position: absolute;
    left: 10px;
    top: 10px; }
  .ncl-side-nav-overlay .ncl-card-wrapper .ncl-card-vertical,
  .ncl-side-nav-overlay .ncl-card-wrapper .ncl-card-horizontal {
    margin-bottom: 5px; }
  .ncl-side-nav-overlay .ncl-card-vertical {
    padding: 20px; }

.ncl-footer-search-overlay .ncl-options-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-footer-search-overlay .ncl-options-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-footer-search-overlay .ncl-options-header .ncl-close-icon {
        left: 10px; } }
  .ncl-footer-search-overlay .ncl-options-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-footer-search-overlay .ncl-options-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-footer-search-overlay .ncl-options-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-footer-search-overlay .ncl-options-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-footer-search-overlay {
  position: fixed;
  left: 0;
  right: 0;
  height: 100vh;
  top: 0;
  background-color: #fff;
  color: #555;
  z-index: 1000; }
  .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #3c424f;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0; }
    .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm input {
      height: 100%;
      background-color: #3c424f;
      color: #fff;
      border: none !important;
      width: 100%;
      padding: 20px; }
      .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm input::-webkit-input-placeholder {
        color: #ccc; }
      .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm input:-ms-input-placeholder {
        color: #ccc; }
      .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm input::-ms-input-placeholder {
        color: #ccc; }
      .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm input::placeholder {
        color: #ccc; }
    .ncl-footer-search-overlay .ncl-sticky-footer-search-section-sm svg {
      color: #fff;
      right: 20px;
      position: absolute; }

.ncl-sticky-footer {
  bottom: 0;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 65px;
  background-color: #1f2532; }
  @media (min-width: 992px) {
    .ncl-sticky-footer {
      padding: 0px 40px; } }
  @media (min-width: 992px) {
    .ncl-sticky-footer {
      position: -webkit-sticky;
      position: sticky; } }
  @media (max-width: 767px) {
    .ncl-sticky-footer {
      height: 60px;
      position: relative; } }
  .ncl-sticky-footer .ncl-left-link-section {
    display: -webkit-flex;
    display: flex; }
    @media (min-width: 992px) {
      .ncl-sticky-footer .ncl-left-link-section {
        position: absolute;
        left: 40px;
        bottom: 0;
        top: 0; } }
    @media (max-width: 991px) {
      .ncl-sticky-footer .ncl-left-link-section {
        -webkit-flex-basis: 25%;
                flex-basis: 25%;
        -webkit-justify-content: center;
                justify-content: center; } }
    .ncl-sticky-footer .ncl-left-link-section .ncl-left-link {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      font-size: 12px;
      font-family: "Open Sans", sans-serif;
      color: #aab2c4;
      -webkit-transition: color 0.25s;
      transition: color 0.25s; }
      @media (max-width: 991px) {
        .ncl-sticky-footer .ncl-left-link-section .ncl-left-link {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: center;
                  justify-content: center; }
          .ncl-sticky-footer .ncl-left-link-section .ncl-left-link .ncl-sticky-footer-icon-name {
            margin-top: 10px;
            font-size: 9px;
            text-transform: uppercase; } }
      .ncl-sticky-footer .ncl-left-link-section .ncl-left-link .ncl-icon-name {
        font-size: 10px;
        margin-bottom: 2px;
        font-weight: 700;
        letter-spacing: 0.5px; }
        @media (max-width: 991px) {
          .ncl-sticky-footer .ncl-left-link-section .ncl-left-link .ncl-icon-name {
            font-size: 9px;
            font-weight: 600; } }
      .ncl-sticky-footer .ncl-left-link-section .ncl-left-link .ncl-left-link-icon {
        font-size: 18px;
        margin-right: 15px; }
        @media (max-width: 991px) {
          .ncl-sticky-footer .ncl-left-link-section .ncl-left-link .ncl-left-link-icon {
            margin-right: 0;
            font-size: 16px; } }
      .ncl-sticky-footer .ncl-left-link-section .ncl-left-link:hover {
        cursor: pointer; }
  .ncl-sticky-footer .ncl-sticky-footer-icons-section {
    position: relative;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    height: inherit;
    font-size: 18px;
    min-width: 130px; }
    @media (max-width: 991px) {
      .ncl-sticky-footer .ncl-sticky-footer-icons-section {
        -webkit-flex-basis: 50%;
                flex-basis: 50%; } }
    .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-overlay-trigger {
      height: 100%;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-flex-basis: 50%;
              flex-basis: 50%; }
    .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon {
      color: #aab2c4;
      height: 100%;
      width: 65px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-transition: background-color 0.5s;
      transition: background-color 0.5s; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon i {
        margin-right: 0; }
      @media (max-width: 991px) {
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon {
          -webkit-flex-direction: column;
                  flex-direction: column;
          -webkit-justify-content: center;
                  justify-content: center; } }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon:hover {
        background-color: #3c424f;
        cursor: pointer; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon .ncl-sticky-footer-icon-name {
        color: #aab2c4;
        margin-top: 10px;
        font-size: 9px;
        text-transform: uppercase;
        font-family: "Open Sans", sans-serif; }
        @media (max-width: 991px) {
          .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-icon .ncl-sticky-footer-icon-name {
            font-size: 9px;
            font-weight: 600; } }
    .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      background-color: #3c424f;
      height: 100%; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input {
        width: 0;
        height: 100%;
        background-color: #3c424f;
        color: #fff;
        border: none !important;
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        opacity: 0; }
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input::-webkit-input-placeholder {
          color: #ccc; }
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input:-ms-input-placeholder {
          color: #ccc; }
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input::-ms-input-placeholder {
          color: #ccc; }
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input::placeholder {
          color: #ccc; }
        .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section input:focus {
          box-shadow: none !important;
          border: none !important;
          width: 300px;
          height: 100%;
          padding: 20px;
          opacity: 1; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-search-section i {
        margin-right: 15px;
        cursor: pointer; }
    .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-clear-link {
      font-size: 15px;
      font-weight: 400;
      position: absolute;
      left: 160px;
      color: #9095a0;
      white-space: nowrap; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-clear-link svg.svg-inline--fa.fa-trash-alt {
        margin-right: 5px; }
      .ncl-sticky-footer .ncl-sticky-footer-icons-section .ncl-sticky-footer-clear-link:hover {
        cursor: pointer; }
  .ncl-sticky-footer .ncl-right-link-section {
    display: -webkit-flex;
    display: flex;
    font-family: "Open Sans", sans-serif; }
    @media (min-width: 992px) {
      .ncl-sticky-footer .ncl-right-link-section {
        position: absolute;
        right: 40px;
        bottom: 0;
        top: 0; } }
    @media (max-width: 991px) {
      .ncl-sticky-footer .ncl-right-link-section {
        -webkit-flex-basis: 25%;
                flex-basis: 25%;
        -webkit-justify-content: center;
                justify-content: center; } }
    .ncl-sticky-footer .ncl-right-link-section .ncl-right-link {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      text-align: right;
      color: #aab2c4;
      -webkit-transition: color 0.25s;
      transition: color 0.25s;
      font-size: 12px; }
      @media (max-width: 991px) {
        .ncl-sticky-footer .ncl-right-link-section .ncl-right-link {
          -webkit-flex-direction: column-reverse;
                  flex-direction: column-reverse;
          -webkit-justify-content: center;
                  justify-content: center; }
          .ncl-sticky-footer .ncl-right-link-section .ncl-right-link .ncl-sticky-footer-icon-name {
            color: #aab2c4;
            margin-top: 10px;
            font-size: 9px;
            text-transform: uppercase; } }
      .ncl-sticky-footer .ncl-right-link-section .ncl-right-link:hover {
        cursor: pointer; }
      .ncl-sticky-footer .ncl-right-link-section .ncl-right-link .ncl-icon-name {
        font-size: 10px;
        margin-bottom: 2px;
        font-weight: 700;
        letter-spacing: 0.5px; }
        @media (max-width: 991px) {
          .ncl-sticky-footer .ncl-right-link-section .ncl-right-link .ncl-icon-name {
            font-size: 9px;
            font-weight: 600; } }
      .ncl-sticky-footer .ncl-right-link-section .ncl-right-link .ncl-right-link-icon {
        font-size: 18px;
        margin-left: 15px; }
        @media (max-width: 991px) {
          .ncl-sticky-footer .ncl-right-link-section .ncl-right-link .ncl-right-link-icon {
            margin-left: 0;
            font-size: 30px;
            font-size: 16px; } }

.ncl-footer .ncl-save-button {
  margin-right: 40px;
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end; }
  .ncl-footer .ncl-save-button button {
    box-shadow: 2px 0px 9px 0px rgba(0, 0, 0, 0.35); }
  @media (max-width: 767px) {
    .ncl-footer .ncl-save-button {
      margin-bottom: 15px;
      margin-right: 10px; } }

.ncl-sticky-footer-default {
  display: -webkit-flex;
  display: flex;
  z-index: 12;
  box-shadow: 0px -2px 20px 2px #e9e9e9;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0; }
  @media (min-width: 768px) {
    .ncl-sticky-footer-default {
      padding-left: 60px;
      height: 60px;
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 767px) {
    .ncl-sticky-footer-default {
      padding: 20px 10px;
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-sticky-footer-default .ncl-footer-warning {
    font-style: italic;
    font-weight: 600; }
    .ncl-sticky-footer-default .ncl-footer-warning svg.svg-inline--fa {
      margin-right: 5px; }
      .ncl-sticky-footer-default .ncl-footer-warning svg.svg-inline--fa.fa-exclamation-triangle {
        color: #ff9a36; }
    @media (min-width: 768px) {
      .ncl-sticky-footer-default .ncl-footer-warning {
        margin-left: 30px; } }
    @media (max-width: 767px) {
      .ncl-sticky-footer-default .ncl-footer-warning {
        margin-top: 10px; } }

.ncl-page-header.__default-page-header {
  padding-bottom: 20px; }

.ncl-page-header .ncl-page-header-link {
  margin-bottom: 15px; }

@media (min-width: 992px) {
  .ncl-page-header .ncl-page-header-title-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: flex-start;
            align-items: flex-start; } }

.ncl-page-header .ncl-page-header-title-container .ncl-page-header-title {
  font-size: 25px;
  font-weight: 400;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #555; }
  .ncl-page-header .ncl-page-header-title-container .ncl-page-header-title-icon {
    margin-right: 10px;
    display: -webkit-inline-flex;
    display: inline-flex; }
  @media (max-width: 767px) {
    .ncl-page-header .ncl-page-header-title-container .ncl-page-header-title {
      float: none;
      float: initial; }
      .ncl-page-header .ncl-page-header-title-container .ncl-page-header-title.__has-faicon {
        display: block; } }
  .ncl-page-header .ncl-page-header-title-container .ncl-page-header-title .ncl-page-header-title-edit-icon {
    margin-left: 15px;
    color: #7b7b7b;
    font-size: 18px; }
    @media (max-width: 767px) {
      .ncl-page-header .ncl-page-header-title-container .ncl-page-header-title .ncl-page-header-title-edit-icon {
        font-size: 15px; } }

.ncl-page-header .ncl-page-header-title-container .ncl-overlay-change-title {
  font-size: 25px;
  font-weight: 600;
  color: #555; }

.ncl-page-header .ncl-page-header-description-container {
  line-height: 1.3;
  padding-top: 15px; }
  .ncl-page-header .ncl-page-header-description-container .ncl-page-description {
    font-size: 15px;
    line-height: 1.2; }

@media (max-width: 991px) {
  .ncl-page-header .ncl-button-toolbar {
    padding-top: 20px; } }

@media (max-width: 991px) {
  .ncl-page-header.ncl-employee-list-header .ncl-page-header-title-container .ncl-page-header-title {
    -webkit-justify-content: space-between;
            justify-content: space-between; } }

@media (max-width: 767px) {
  .ncl-page-header.ncl-employee-list-header .ncl-quick-links {
    padding-top: 20px; } }

.ncl-utility-header-container {
  display: -webkit-flex;
  display: flex; }
  @media (min-width: 992px) {
    .ncl-utility-header-container {
      margin-bottom: 10px; } }
  .ncl-utility-header-container .ncl-utility-header {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -1px;
    padding: 10px 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    @media (min-width: 992px) {
      .ncl-utility-header-container .ncl-utility-header {
        border-right: 1px solid #dedede; } }
    .ncl-utility-header-container .ncl-utility-header i {
      font-size: 40px;
      margin-right: 15px; }
  .ncl-utility-header-container .ncl-utility-description {
    font-size: 15px;
    padding: 10px 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }

@media (max-width: 990px) {
  .ncl-utility-header-container .ncl-utility-description {
    padding: 20px 10px; } }

@media (min-width: 768px) {
  .ncl-personal-information {
    padding-right: 55px;
    padding-bottom: 20px; } }

.ncl-personal-information .ncl-emp-title {
  font-size: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (min-width: 768px) {
    .ncl-personal-information .ncl-emp-title {
      margin-bottom: 15px; } }
  @media (max-width: 767px) {
    .ncl-personal-information .ncl-emp-title {
      font-size: 20px; } }

.ncl-personal-information .ncl-emp-designation {
  font-weight: 600;
  font-size: 15px;
  text-transform: uppercase; }
  @media (min-width: 768px) {
    .ncl-personal-information .ncl-emp-designation {
      font-size: 20px;
      padding-right: 30px;
      border-right: 1px solid #ccc;
      margin-right: 30px; } }

.ncl-personal-information .ncl-emp-description {
  display: -webkit-flex;
  display: flex; }
  @media (min-width: 768px) {
    .ncl-personal-information .ncl-emp-description {
      -webkit-align-items: center;
              align-items: center; } }
  @media (max-width: 767px) {
    .ncl-personal-information .ncl-emp-description {
      -webkit-flex-direction: column;
              flex-direction: column;
      border-bottom: 1px solid #ccc; } }
  .ncl-personal-information .ncl-emp-description .ncl-emp-description-bar {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    @media (max-width: 767px) {
      .ncl-personal-information .ncl-emp-description .ncl-emp-description-bar .ncl-emp-designation {
        font-size: 15px; } }
  .ncl-personal-information .ncl-emp-description .ncl-emp-status-wrapper {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 20px;
    margin-bottom: 20px; }
  .ncl-personal-information .ncl-emp-description .ncl-emp-status {
    margin-right: 15px; }
  .ncl-personal-information .ncl-emp-description .ncl-emp-access {
    color: #999;
    font-size: 14px; }
    @media (max-width: 767px) {
      .ncl-personal-information .ncl-emp-description .ncl-emp-access {
        font-size: 13px; } }

.ncl-personal-information .ncl-employee-title-bar-sm {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-personal-information .ncl-employee-title-bar-sm .profile-image {
    position: relative; }

.profile-image {
  position: absolute;
  padding: 3px;
  border-radius: 50%;
  border: 2px solid #339966;
  z-index: 1; }
  .profile-image.__default {
    margin-right: 20px; }
  @media (min-width: 768px) {
    .profile-image {
      overflow: hidden; } }
  .profile-image img {
    border-radius: 50%;
    height: 200px;
    width: 200px; }
    @media (max-width: 767px) {
      .profile-image img {
        height: 80px;
        width: 80px; } }
  .profile-image .edit-link {
    color: #fff;
    text-align: center;
    position: absolute; }
    @media (min-width: 768px) {
      .profile-image .edit-link svg.svg-inline--fa.fa-pencil-alt {
        margin-right: 5px; } }
    @media (min-width: 768px) {
      .profile-image .edit-link {
        background-color: rgba(51, 51, 51, 0.5);
        padding: 5px;
        bottom: -2px;
        left: 0;
        right: 0;
        font-size: 13px; } }
    @media (max-width: 767px) {
      .profile-image .edit-link {
        background-color: #767676;
        height: 22px;
        width: 22px;
        border-radius: 50%;
        right: 0;
        bottom: 0; } }
    .profile-image .edit-link a {
      color: #fff; }
      @media (max-width: 767px) {
        .profile-image .edit-link a {
          font-size: 12px; } }

.ncl-h2 .section-title {
  font-weight: 600;
  color: #339966;
  font-size: 18px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-h2 .section-title-employee-profile {
  font-weight: 600;
  color: #339966;
  font-size: 18px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-h2 .section-title-accordion {
  font-weight: 600;
  color: #339966;
  font-size: 20px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }

.ncl-h2 .sub-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-weight: 600;
  color: #555;
  font-size: 18px; }

.ncl-h2 .header-icon {
  margin-right: 10px;
  display: -webkit-inline-flex;
  display: inline-flex; }

.ncl-h2.__default {
  margin-bottom: 20px; }

.ncl-definition {
  font-size: 15px;
  max-width: 260px; }
  @media (min-width: 768px) {
    .ncl-definition {
      margin-right: 50px;
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .ncl-definition {
      width: 100%;
      max-width: none; } }
  @media (max-width: 767px) {
    .ncl-definition {
      margin-bottom: 25px; }
      .ncl-definition:last-child {
        margin-bottom: 0px; } }
  .ncl-definition .ncl-definition-title {
    font-weight: 600; }
    .ncl-definition .ncl-definition-title a svg.svg-inline--fa {
      margin-left: 8px; }
  .ncl-definition .ncl-definition-content {
    padding-top: 15px;
    font-size: inherit;
    margin: 0px !important; }

.ncl-definition-group .ncl-definition-group-title {
  border-bottom: 1px solid #ccc;
  display: -webkit-flex;
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 10px;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 16px; }
  .ncl-definition-group .ncl-definition-group-title .ncl-definition-group-title-icon {
    margin-right: 10px; }

.ncl-definition-group .ncl-definition-group-body {
  display: -webkit-flex;
  display: flex; }
  @media (max-width: 767px) {
    .ncl-definition-group .ncl-definition-group-body {
      -webkit-flex-direction: column;
              flex-direction: column; } }

.ncl-step-title-container .ncl-step-title {
  font-weight: 600;
  font-size: 18px;
  color: #555;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-step-title-container .ncl-step-title .ncl-step-number {
    background-color: #555;
    color: #fff;
    border-radius: 100px;
    font-weight: 600;
    height: 30px;
    margin-right: 15px;
    width: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 100px;
    font-size: 18px; }

.ncl-step-title-container .ncl-step-description {
  margin-top: 20px; }

.ncl-title-band {
  height: 45px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  background-color: #339966;
  font-size: 18px;
  color: #fff;
  padding: 0px 20px; }
  .ncl-title-band.__default {
    margin-bottom: 20px; }

.ncl-well {
  font-size: 15px;
  text-align: center;
  background-color: #e9e9e9;
  padding: 30px; }
  @media (max-width: 767px) {
    .ncl-well {
      padding: 15px; } }
  .ncl-well .ncl-form-tooltip .ncl-well {
    background-color: #fff; }
  .ncl-well.__medium {
    padding: 20px; }
    @media (max-width: 767px) {
      .ncl-well.__medium {
        padding: 10px; } }
  .ncl-well.__small {
    padding: 15px; }
    @media (max-width: 767px) {
      .ncl-well.__small {
        padding: 10px; } }

section {
  max-width: 450px;
  margin: 50px auto; }
  section div {
    max-width: 255px;
    word-wrap: break-word;
    margin-bottom: 12px;
    line-height: 24px; }
    section div:after {
      content: "";
      display: table;
      clear: both; }

.ncl-chat-bubble {
  font-family: #e5e5ea;
  font-size: 15px;
  font-weight: 400;
  position: relative;
  padding: 10px 20px;
  background: #e5e5ea;
  border-radius: 20px;
  color: #555;
  max-width: 230px; }
  .ncl-chat-bubble:before {
    content: "";
    position: absolute;
    z-index: 2;
    bottom: -2px;
    left: -7px;
    height: 20px;
    border-left: 20px solid #e5e5ea;
    border-bottom-right-radius: 16px 14px;
    -webkit-transform: translate(0, -2px);
            transform: translate(0, -2px); }
  .ncl-chat-bubble:after {
    content: "";
    position: absolute;
    z-index: 3;
    bottom: -2px;
    left: 18px;
    width: 12px;
    height: 20px;
    background: #fff;
    border-bottom-right-radius: 10px;
    -webkit-transform: translate(-30px, -2px);
            transform: translate(-30px, -2px); }

.ncl-mobile-frame-wrapper {
  display: inline-block;
  padding: 20px 60px; }
  @media (max-width: 991px) {
    .ncl-mobile-frame-wrapper {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center; } }
  .ncl-mobile-frame-wrapper .preview-text {
    text-align: center;
    font-size: 12px; }
  .ncl-mobile-frame-wrapper .ncl-mobile-frame {
    background: url(/static/media/CellPhone.80374e15.svg);
    background-repeat: no-repeat;
    width: 297px;
    height: 310px;
    margin-top: 10px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }

.ncl-no-data-container {
  padding: 30px;
  font-family: "Source Sans Pro", sans-serif;
  color: #555;
  text-align: center;
  border: 1px solid #ccc; }
  @media (max-width: 767px) {
    .ncl-no-data-container {
      padding: 20px 10px; } }
  .ncl-no-data-container .ncl-no-data-header {
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    padding-top: 15px; }
    @media (max-width: 991px) {
      .ncl-no-data-container .ncl-no-data-header {
        padding-top: 10px; } }
  .ncl-no-data-container .ncl-no-data-body {
    padding-top: 5px;
    line-height: 23px;
    font-size: 15px; }
  .ncl-no-data-container .ncl-no-data-container-footer {
    line-height: 23px;
    font-size: 15px; }
  .ncl-no-data-container .ncl-primary-link-button {
    text-transform: none;
    padding: 0px;
    cursor: pointer; }
  .ncl-no-data-container.ncl-no-data-well-container {
    border: 1px dashed #ccc;
    background-color: #f4f4f4;
    padding: 30px;
    text-align: center; }
    .ncl-no-data-container.ncl-no-data-well-container .ncl-no-data-well-header {
      padding: 20px 10px;
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      padding-top: 5px; }
      @media (max-width: 991px) {
        .ncl-no-data-container.ncl-no-data-well-container .ncl-no-data-well-header {
          padding-top: 0px; } }
    .ncl-no-data-container.ncl-no-data-well-container .ncl-no-data-container-body {
      line-height: 23px;
      font-size: 15px; }
    .ncl-no-data-container.ncl-no-data-well-container .ncl-no-data-container-footer {
      line-height: 23px;
      font-size: 15px; }
    .ncl-no-data-container.ncl-no-data-well-container .ncl-primary-link-button {
      text-transform: none;
      padding: 0px;
      cursor: pointer; }

.ncl-formula-entity {
  display: -webkit-flex;
  display: flex; }
  .ncl-formula-entity .ncl-formula-entity-icon {
    color: #ffffff;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    background-color: #339966;
    height: 50px;
    width: 50px;
    font-size: 20px; }
  .ncl-formula-entity .ncl-formula-entity-content {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #e9e9e9;
    padding: 10px 15px; }
    @media (min-width: 375px) {
      .ncl-formula-entity .ncl-formula-entity-content {
        min-width: 250px;
        max-width: 100%; } }
    @media (max-width: 767px) {
      .ncl-formula-entity .ncl-formula-entity-content {
        -webkit-flex-basis: 100%;
                flex-basis: 100%; } }
    .ncl-formula-entity .ncl-formula-entity-content.__inverse {
      background-color: #fff; }

.ncl-summary-calculator .ncl-summary-container-title, .ncl-summary-box--skeleton .ncl-summary-box-title, .ncl-summary-box .ncl-summary-container-title {
  color: #339966;
  font-size: 18px;
  font-weight: 600; }

.ncl-summary-calculator .ncl-summary-calculator-sub-title {
  font-size: 15px;
  margin-top: 10px; }

.ncl-summary-calculator .ncl-formula-entity {
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-top: 20px; }

.ncl-summary-box .ncl-summary-box-description {
  font-size: 15px;
  margin-top: 10px;
  font-weight: 600;
  padding-bottom: 30px; }
  @media (max-width: 767px) {
    .ncl-summary-box .ncl-summary-box-description {
      padding-bottom: 15px; } }

.ncl-summary-box .ncl-summary-box-content {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center; }
  @media (max-width: 767px) {
    .ncl-summary-box .ncl-summary-box-content {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-image {
    width: 35%; }
    @media (max-width: 767px) {
      .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-image {
        width: 100%; } }
  .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper {
    text-align: left; }
    @media (max-width: 767px) {
      .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper {
        margin-top: 20px; } }
    @media (min-width: 768px) {
      .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper {
        padding-left: 40px; } }
    .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper .ncl-summary-box-section:not(:first-child) {
      margin-top: 20px; }
    .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper .ncl-summary-box-section .ncl-summary-box-section-title {
      font-weight: 600; }
    .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper .ncl-summary-box-section .ncl-summary-box-section-body {
      margin-top: 15px;
      font-size: 14px; }
      .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper .ncl-summary-box-section .ncl-summary-box-section-body .ncl-summary-box-section-item {
        display: -webkit-flex;
        display: flex; }
        .ncl-summary-box .ncl-summary-box-content .ncl-summary-box-section-wrapper .ncl-summary-box-section .ncl-summary-box-section-body .ncl-summary-box-section-item:not(:first-child) {
          margin-top: 5px; }

.ncl-in-page-data-container .ncl-summary-container-title, .ncl-in-page-data-container .ncl-header .ncl-summary-container-title {
  color: #339966;
  font-size: 18px;
  font-weight: 600; }

.ncl-in-page-data-container {
  background-color: #e9e9e9; }
  .ncl-in-page-data-container .ncl-container-box {
    background: #fff;
    padding: 20px;
    font-size: 15px;
    margin-top: 20px; }
    @media (max-width: 767px) {
      .ncl-in-page-data-container .ncl-container-box {
        margin-top: 15px; } }
  .ncl-in-page-data-container .ncl-header {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    margin: 20px 20px 10px 20px;
    font-weight: 600; }
  .ncl-in-page-data-container .ncl-container-box-header {
    display: -webkit-flex;
    display: flex;
    padding-bottom: 15px;
    -webkit-justify-content: center;
            justify-content: center;
    font-weight: 600; }
  .ncl-in-page-data-container .ncl-container-box-row {
    padding: 15px 0px;
    border-top: 1px solid #ccc; }
    @media (max-width: 767px) {
      .ncl-in-page-data-container .ncl-container-box-row .ncl-container-box-row-item:not(:last-child) {
        margin-bottom: 5px; } }
    .ncl-in-page-data-container .ncl-container-box-row .ncl-container-box-row-item-header-sm {
      font-weight: 600;
      margin-right: 5px; }
    .ncl-in-page-data-container .ncl-container-box-row:last-child {
      padding-bottom: 0px; }
    @media (max-width: 767px) {
      .ncl-in-page-data-container .ncl-container-box-row {
        text-align: left;
        border-top: none; }
        .ncl-in-page-data-container .ncl-container-box-row:first-child {
          padding-top: 0; }
        .ncl-in-page-data-container .ncl-container-box-row:not(:first-child) {
          border-top: 1px solid #ccc; } }
  .ncl-in-page-data-container .ncl-description-text {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    font-size: 15px;
    font-weight: 600;
    margin-top: 10px; }
  .ncl-in-page-data-container .ncl-form-tooltip .ncl-well {
    background-color: #fff;
    margin-top: 20px; }

.ncl-pay-check {
  padding: 20px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 2px 2px 6px 0 #cccccc;
  position: relative; }
  .ncl-pay-check .ncl-pay-check-upload-btn {
    position: absolute;
    right: 20px;
    top: 20px; }
    .ncl-pay-check .ncl-pay-check-upload-btn .ncl-button {
      padding: 0;
      width: 50px;
      height: 50px; }
  .ncl-pay-check .ncl-paycheck-summary {
    padding-top: 15px;
    padding-right: 60px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap; }
    @media (min-width: 1200px) {
      .ncl-pay-check .ncl-paycheck-summary {
        -webkit-justify-content: space-between;
                justify-content: space-between; } }
    @media (min-width: 992px) {
      .ncl-pay-check .ncl-paycheck-summary {
        -webkit-align-items: flex-start;
                align-items: flex-start; } }
    @media (min-width: 768px) {
      .ncl-pay-check .ncl-paycheck-summary {
        padding-bottom: 15px; } }
    .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-take-home-pay {
      padding-right: 30px;
      -webkit-flex-grow: 1;
              flex-grow: 1; }
      @media (max-width: 767px) {
        .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-take-home-pay {
          -webkit-flex-basis: 100%;
                  flex-basis: 100%; } }
      .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-take-home-pay .ncl-take-home-payitem {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
      .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-take-home-pay .ncl-paycheck-pay-amount {
        font-size: 43px;
        color: #4ba9a9;
        font-weight: 900; }
    .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-summary-items {
      display: -webkit-flex;
      display: flex; }
      @media (max-width: 991px) {
        .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-summary-items {
          margin-top: 15px;
          -webkit-flex-wrap: wrap;
                  flex-wrap: wrap; } }
      @media (min-width: 992px) {
        .ncl-pay-check .ncl-paycheck-summary .ncl-paycheck-summary-items {
          border-left: 1px solid #ccc; } }
    @media (max-width: 991px) {
      .ncl-pay-check .ncl-paycheck-summary .ncl-payroll-calendar-summary-item {
        margin-top: 0px;
        -webkit-flex-basis: 160px;
                flex-basis: 160px;
        -webkit-align-self: flex-start;
                align-self: flex-start; } }
  @media (max-width: 767px) {
    .ncl-pay-check .ncl-payroll-calendar-summary-item {
      margin-top: 15px;
      -webkit-flex-basis: 50%;
              flex-basis: 50%;
      -webkit-flex-grow: 1;
              flex-grow: 1; } }
  .ncl-pay-check .ncl-well {
    margin-top: 10px; }
    .ncl-pay-check .ncl-well .ncl-paycheck-table-name {
      font-weight: 600;
      font-size: 15px;
      color: #333;
      text-transform: uppercase;
      text-align: left;
      margin-bottom: 5px; }
      @media (max-width: 991px) {
        .ncl-pay-check .ncl-well .ncl-paycheck-table-name {
          font-size: 16px;
          margin-bottom: 10px; } }
    .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row {
      display: -webkit-flex;
      display: flex; }
      @media (max-width: 991px) {
        .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row {
          -webkit-flex-direction: column;
                  flex-direction: column; }
          .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row:not(:first-child) {
            padding-top: 15px;
            border-top: 1px solid #ccc; } }
      .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row:not(:last-child) {
        margin-bottom: 15px; }
      .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item {
        text-align: right; }
        @media (max-width: 991px) {
          .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item {
            text-align: left;
            font-size: 14px; }
            .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item:not(:last-child) {
              margin-bottom: 5px; } }
        .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item .ncl-paycheck-table-description-item {
          color: #999;
          margin-left: 5px; }
        .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item:first-child {
          font-size: 14px;
          text-align: left; }
        @media (max-width: 991px) {
          .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row .ncl-paycheck-table-item.__first-column {
            font-size: 16px; } }
      .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__header {
        font-weight: 600; }
        @media (min-width: 992px) {
          .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__header:not(:last-child) {
            text-transform: uppercase; }
            .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__header:not(:last-child) .ncl-paycheck-table-item {
              font-size: 12px; }
              .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__header:not(:last-child) .ncl-paycheck-table-item:first-child {
                font-size: 15px;
                text-align: left; } }
        @media (max-width: 991px) {
          .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__header .ncl-paycheck-table-item.__first-column {
            font-size: 16px; } }
      .ncl-pay-check .ncl-well .ncl-paycheck-table .ncl-paycheck-table-row.__hr {
        border-top: 1px solid #ccc;
        padding-top: 15px; }


.modal-body-container {
  font-family: "Source Sans Pro", sans-serif;
  color: #555; }
  .modal-body-container .modal-body-header {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px; }
  .modal-body-container .modal-body-content {
    font-size: 15px;
    line-height: 1.4; }
    .modal-body-container .modal-body-content .primary-link-button {
      text-transform: none;
      cursor: pointer; }
    .modal-body-container .modal-body-content .content-section {
      margin-bottom: 10px; }
    .modal-body-container .modal-body-content .content-section-item {
      margin-bottom: 5px !important; }
    .modal-body-container .modal-body-content .list-section > ul {
      padding-top: 5px;
      padding-left: 17px; }
      .modal-body-container .modal-body-content .list-section > ul li {
        margin-bottom: 5px; }
        .modal-body-container .modal-body-content .list-section > ul li:last-child {
          margin-bottom: 0px; }
    .modal-body-container .modal-body-content .modal-body-gray-section {
      background-color: rgba(233, 233, 233, 0.5);
      border: 1px solid #ccc;
      margin-bottom: 20px;
      margin-top: 10px;
      padding: 15px;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
      .modal-body-container .modal-body-content .modal-body-gray-section .primary-link-button {
        font-weight: 400;
        padding: 0px !important;
        cursor: pointer; }
      .modal-body-container .modal-body-content .modal-body-gray-section:last-child {
        margin-bottom: 10px; }
      .modal-body-container .modal-body-content .modal-body-gray-section.inside-accordion:last-child {
        margin-bottom: 0px; }
    .modal-body-container .modal-body-content .alert-infomation-section {
      padding-top: 20px;
      border-top: 1px solid #ccc; }
    .modal-body-container .modal-body-content .accordion-title {
      font-size: 15px;
      font-weight: normal !important; }
  .modal-body-container .secondary-header {
    font-size: 15px;
    font-weight: 600; }
  .modal-body-container .warning-icon {
    background-color: #fb6b5b !important; }
  .modal-body-container .outline-button {
    background-color: #fff; }
    @media (max-width: 767px) {
      .modal-body-container .outline-button {
        width: unset !important; } }
  .modal-body-container .fa-exclamation-circle {
    color: #fb6b5b; }
  .modal-body-container .fa-angle-right {
    font-size: 16px; }
  .modal-body-container .fa-angle-down {
    font-size: 16px; }

.two-line-modal-content {
  font-size: 18px; }

.modal-content-button {
  padding: 8px 20px 10px 25px; }
  .modal-content-button i {
    margin-right: 0px; }

@media (min-width: 992px) {
  .outline-button:hover {
    background-color: #f0eeee !important; } }

.modal-footer .float-left {
  float: none; }
  @media (min-width: 768px) {
    .modal-footer .float-left {
      float: left; } }

.ncl-payroll-calendar-summary-container {
  padding-top: 15px;
  border: 1px solid #ccc;
  padding-bottom: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  @media (max-width: 991px) {
    .ncl-payroll-calendar-summary-container {
      padding-left: 20px;
      padding-top: 0; } }
  @media (max-width: 767px) {
    .ncl-payroll-calendar-summary-container {
      padding-left: 15px; } }

.ncl-payroll-calendar-summary-item {
  font-family: "Source Sans Pro", sans-serif;
  border-left: 1px solid #ccc;
  padding-left: 15px;
  padding-right: 20px;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-basis: 20%;
          flex-basis: 20%; }
  @media (max-width: 1199px) {
    .ncl-payroll-calendar-summary-item {
      -webkit-flex-basis: 183px;
              flex-basis: 183px; } }
  @media (max-width: 991px) {
    .ncl-payroll-calendar-summary-item {
      margin-top: 15px; } }
  @media (max-width: 767px) {
    .ncl-payroll-calendar-summary-item {
      -webkit-flex-basis: 50%;
              flex-basis: 50%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-justify-content: center;
              justify-content: center;
      padding-right: 0px;
      padding-left: 10px; } }
  @media (min-width: 992px) {
    .ncl-payroll-calendar-summary-item:first-child {
      border: 0; } }
  .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-label {
    font-size: 11px;
    font-weight: 600;
    color: #555;
    text-transform: uppercase; }
  .ncl-payroll-calendar-summary-item .summary-item-label {
    font-size: 11px;
    font-weight: 600;
    color: #555;
    text-transform: uppercase; }
  .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-value {
      font-size: 18px;
      color: #555;
      -webkit-flex-shrink: 0;
              flex-shrink: 0; }
    .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-rvalue {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center; }
    .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon {
      padding-top: 3px;
      padding-left: 5px;
      cursor: pointer;
      font-size: 15px; }
      @media (min-width: 768px) and (max-width: 991px) {
        .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon {
          font-size: 18px; }
          .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon svg.fa-pencil-alt {
            font-size: 18px; } }
      @media (min-width: 320px) and (max-width: 767px) {
        .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon {
          padding-top: 0px; }
          .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon svg.fa-pencil-alt {
            font-size: 15px; } }
      .ncl-payroll-calendar-summary-item .ncl-payroll-summary-item-content .ncl-payroll-summary-item-icon .svg-inline--fa.fa-info-circle {
        color: #009999; }
  .ncl-payroll-calendar-summary-item .summary-item-value {
    font-size: 18px;
    color: #555; }
  .ncl-payroll-calendar-summary-item .summary-item-dollarIcon {
    font-size: 15px;
    margin-right: 2px; }
  .ncl-payroll-calendar-summary-item .summary-item-groupIcon {
    padding-top: 4px;
    padding-left: 5px;
    padding-right: 5px; }
    @media (min-width: 767px) and (max-width: 846px) {
      .ncl-payroll-calendar-summary-item .summary-item-groupIcon {
        float: none; } }
  .ncl-payroll-calendar-summary-item .summary-item-customIcon {
    padding-top: 2px; }
  .ncl-payroll-calendar-summary-item .summary-item-icon {
    padding-top: 3px;
    padding-left: 5px;
    cursor: pointer;
    font-size: 15px; }

.timestamp-column-header {
  text-align: right;
  padding-right: 0px; }

.timestamp-column-content {
  text-align: right;
  padding-right: 0px;
  font-size: 15px; }

.yellow-icon {
  color: #e6e600; }

.orange-icon {
  color: #fb6b5b !important; }

.gray-icon {
  color: #555555 !important; }

.dot-icon {
  border-radius: 0% !important;
  color: #555555 !important;
  margin-top: 2px !important; }

.gray-dot-icon {
  background-color: #f4f4f4;
  border-radius: 0% !important;
  color: #555555 !important;
  margin-top: 2px !important; }

.cancel-request-icon {
  color: #fb6b5b !important; }

.convert-text {
  text-transform: lowercase; }

.product-name-container {
  display: -webkit-flex;
  display: flex;
  margin-left: 25px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  .product-name-container .product-name {
    font-weight: 600 !important;
    font-size: 15px !important;
    color: #555;
    display: table-cell;
    vertical-align: middle; }
    @media (max-width: 767px) {
      .product-name-container .product-name {
        font-size: 20px !important; } }
  @media (max-width: 767px) {
    .product-name-container {
      margin-left: 0px;
      font-size: 20px !important; } }

.user-name {
  display: table-cell;
  vertical-align: middle;
  padding-left: 15px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .user-name {
      padding-left: 10px; } }

.ncl-notification-table-item-container .notification-table-item {
  clear: both;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center; }
  @media (min-width: 320px) and (max-width: 991px) {
    .ncl-notification-table-item-container .notification-table-item {
      padding-top: 10px; }
      .ncl-notification-table-item-container .notification-table-item .notification-table-item:first-child {
        padding-top: 0px !important; }
      .ncl-notification-table-item-container .notification-table-item .ncl-user-icon-horizontal {
        padding: 0px; } }
  .ncl-notification-table-item-container .notification-table-item .holiday-image {
    width: 50px; }
  .ncl-notification-table-item-container .notification-table-item .align-center {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center; }

@media (min-width: 320px) and (max-width: 991px) {
  .ncl-notification-table-item-container .ncl-user-icon-horizontal {
    padding: 0px;
    margin-bottom: 15px; }
    .ncl-notification-table-item-container .ncl-user-icon-horizontal .product-name-container {
      margin-left: 0px; }
    .ncl-notification-table-item-container .ncl-user-icon-horizontal .notification-table-item-details {
      margin-bottom: 5px; }
      .ncl-notification-table-item-container .ncl-user-icon-horizontal .notification-table-item-details .item-buttons {
        margin-left: 0px; } }

.ncl-notification-table-item-container .notification-table-item-details {
  margin-top: 5px; }
  .ncl-notification-table-item-container .notification-table-item-details .item-buttons {
    display: -webkit-flex;
    display: flex;
    margin-left: 25px; }
    .ncl-notification-table-item-container .notification-table-item-details .item-buttons .details-link {
      padding-right: 20px; }
      .ncl-notification-table-item-container .notification-table-item-details .item-buttons .details-link .primary-link-button {
        padding-top: 5px !important;
        padding-bottom: 0px !important; }
      .ncl-notification-table-item-container .notification-table-item-details .item-buttons .details-link .button-icon {
        padding-left: 5px;
        font-size: 12px; }
    .ncl-notification-table-item-container .notification-table-item-details .item-buttons .job-history-link {
      padding-top: 5px !important;
      padding-bottom: 0px !important;
      padding-left: 20px;
      padding-right: 20px;
      border-left: 1px solid #dbd6d6; }
    @media (max-width: 767px) {
      .ncl-notification-table-item-container .notification-table-item-details .item-buttons {
        margin-left: 0px; } }
  @media (max-width: 767px) {
    .ncl-notification-table-item-container .notification-table-item-details {
      padding-top: 5px;
      padding-bottom: 10px; } }

@media (min-width: 320px) and (max-width: 767px) {
  .ncl-notification-table-item-container .notification-status-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .ncl-notification-table-item-container .notification-user-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; }
  .ncl-notification-table-item-container .notification-timestamp-container {
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px; } }

#view-job-history-company-link {
  font-size: 25px; }

.ncl-notification-table-item-container:first-child {
  padding-top: 20px; }

.ncl-display-toggle-content {
  margin-left: 75px; }
  @media (min-width: 767px) {
    .ncl-display-toggle-content {
      margin-top: 20px; } }

@media (min-width: 767px) {
  .ncl-display-toggle-exception-content {
    margin-top: 20px; } }

.ncl-display-toggle-exception-content .display-toggle-exception-item {
  margin-bottom: 15px; }
  .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all {
    padding: 0px 15px 15px 15px;
    display: -webkit-flex;
    display: flex; }
    .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit {
      padding-left: 20px;
      color: #555;
      font-weight: 400; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit a {
        color: #009999;
        text-transform: none;
        cursor: pointer; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-select-all .exception-item-submit a.disabled {
        color: #ccc;
        font-size: 15px;
        font-weight: 400;
        cursor: not-allowed; }
  .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container {
    border: 1px solid #ccc;
    background: #fff; }
    .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      padding: 20px 15px;
      border-bottom: 1px solid #eee; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items:nth-child(odd) {
        background-color: #fefefe; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items:last-child {
        border-bottom: none; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-items label {
        margin-bottom: 0px; }
    .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content {
      margin-left: 15px; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-issue {
        padding-bottom: 10px;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; }
      .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection {
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        margin-bottom: 10px; }
        .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection:last-child {
          margin-bottom: 0px; }
        .ncl-display-toggle-exception-content .display-toggle-exception-item .exception-item-container .exception-item-content .content-expection i {
          font-size: 5px;
          width: 14px; }

.display-toggle-content {
  background-color: #f4f4f4;
  padding: 30px; }
  @media (min-width: 320px) and (max-width: 767px) {
    .display-toggle-content {
      padding: 15px; } }
  .display-toggle-content .display-toggle-content-title {
    font-size: 18px;
    font-weight: 600; }
  .display-toggle-content .display-toggle-content-item {
    display: -webkit-flex;
    display: flex;
    font-size: 15px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-top: 20px; }
    @media (max-width: 991px) {
      .display-toggle-content .display-toggle-content-item {
        -webkit-flex-direction: column;
                flex-direction: column; } }
    .display-toggle-content .display-toggle-content-item .display-toggle-content-right {
      text-align: right;
      -webkit-align-content: flex-end;
              align-content: flex-end;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      color: #009999; }
      @media (max-width: 991px) {
        .display-toggle-content .display-toggle-content-item .display-toggle-content-right {
          -webkit-flex-direction: column;
                  flex-direction: column;
          margin-top: 10px;
          text-align: left; } }
  .display-toggle-content .display-toggle-content-item:last-child {
    border-bottom: none;
    padding-bottom: 0px; }

.ncl-notification-table-item-container:last-child {
  border-bottom: none; }

.cancel-request-link-container {
  padding-left: 20px;
  border-left: 1px solid #dbd6d6; }

.cancel-request-link {
  color: #fb6b5b !important;
  background: none;
  border: none;
  font-size: 15px;
  font-weight: 400;
  padding: 5px 0px 0px 0px;
  text-transform: capitalize; }
  @media (max-width: 767px) {
    .cancel-request-link {
      padding: 0px; } }

.cancel-request-link:hover {
  color: #fb6b5b !important; }

.list-header-container {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 10px; }
  .list-header-container .list-header-job-title {
    font-size: 25px; }

.notification-user-icon-horizontal {
  padding: 16px 0px; }
  .notification-user-icon-horizontal .notification-user-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .notification-user-icon-horizontal .notification-user-name {
        padding-left: 10px; } }
  .notification-user-icon-horizontal .notification-user-image {
    display: table-cell;
    height: 38px;
    width: 38px; }
    .notification-user-icon-horizontal .notification-user-image img {
      height: 37px;
      border-radius: 24px; }
      @media (min-width: 992px) {
        .notification-user-icon-horizontal .notification-user-image img {
          margin-left: 15px; } }

.listpage-user-image {
  display: table-cell;
  vertical-align: middle;
  border-radius: 50%;
  border: 1px solid white;
  padding: 5px; }
  @media (min-width: 320px) and (max-width: 991px) {
    .listpage-user-image {
      padding-right: 20px; } }

.listpage-user-icon-horizontal {
  display: table-row;
  padding: 16px 0px; }
  .listpage-user-icon-horizontal .listpage-user-icon-name {
    color: #555;
    display: table-cell;
    font-size: 15px;
    font-weight: 400;
    vertical-align: middle; }
    @media (min-width: 320px) and (max-width: 991px) {
      .listpage-user-icon-horizontal .listpage-user-icon-name {
        padding-left: 3px; } }
  .listpage-user-icon-horizontal .listpage-user-icon-image {
    display: table-cell;
    vertical-align: middle;
    border-radius: 50%;
    border: 1px solid white;
    padding: 5px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .listpage-user-icon-horizontal .listpage-user-icon-image {
        padding-right: 20px; } }
    .listpage-user-icon-horizontal .listpage-user-icon-image img {
      border-radius: 24px; }

.ncl-job-history-container {
  margin-top: 30px; }

.back-button-link .primary-link-button {
  padding-top: 0px !important;
  padding-bottom: 30px !important; }
  @media (max-width: 767px) {
    .back-button-link .primary-link-button {
      padding-top: 15px !important;
      padding-bottom: 15px !important; } }

.status-column-content {
  font-size: 15px; }

.ncl-quick-links .ncl-quick-link-item-sm, .ncl-quick-links .ncl-quick-link-item-sm-bold, .ncl-payroll-calendar-links .ncl-quick-link-item-sm {
  height: inherit;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding: 0px 15px; }
  .ncl-quick-links .ncl-quick-link-item-sm:not(:first-child), .ncl-quick-links .ncl-quick-link-item-sm-bold:not(:first-child), .ncl-payroll-calendar-links .ncl-quick-link-item-sm:not(:first-child) {
    border-left: 1px solid #ccc; }
  .ncl-quick-links .ncl-quick-link-item-sm:first-child, .ncl-quick-links .ncl-quick-link-item-sm-bold:first-child, .ncl-payroll-calendar-links .ncl-quick-link-item-sm:first-child {
    padding-left: 0px; }

.ncl-quick-links {
  display: -webkit-flex;
  display: flex;
  font-size: 15px; }
  .ncl-quick-links .ncl-quick-links-content {
    display: -webkit-flex;
    display: flex; }
  .ncl-quick-links .ncl-quick-link-item {
    height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    padding: 0px 40px; }
    .ncl-quick-links .ncl-quick-link-item a svg.svg-inline--fa,
    .ncl-quick-links .ncl-quick-link-item a i.fa {
      margin-right: 5px; }
    .ncl-quick-links .ncl-quick-link-item:not(:first-child) {
      border-left: 1px solid #009999; }
    .ncl-quick-links .ncl-quick-link-item:first-child {
      padding-left: 0px; }
  .ncl-quick-links .ncl-quick-link-item-sm {
    padding: 0px 10px; }
    .ncl-quick-links .ncl-quick-link-item-sm a.ncl-link svg.svg-inline--fa,
    .ncl-quick-links .ncl-quick-link-item-sm a.ncl-link i.fa {
      margin-right: 5px; }
    .ncl-quick-links .ncl-quick-link-item-sm a.ncl-link.ncl-link-secondary {
      color: #ccc; }
    .ncl-quick-links .ncl-quick-link-item-sm-bold a.ncl-link svg.svg-inline--fa,
    .ncl-quick-links .ncl-quick-link-item-sm-bold a.ncl-link i.fa {
      margin-right: 5px; }
    .ncl-quick-links .ncl-quick-link-item-sm-bold a.ncl-link.ncl-link-primary {
      color: #555;
      font-weight: 600; }
    .ncl-quick-links .ncl-quick-link-item-sm-bold a.ncl-link.ncl-link-secondary {
      color: #009999; }
  .ncl-quick-links.__basic .ncl-quick-links-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
    @media (max-width: 767px) {
      .ncl-quick-links.__basic .ncl-quick-links-content {
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: flex-start;
                align-items: flex-start; }
        .ncl-quick-links.__basic .ncl-quick-links-content .ncl-quick-link-item {
          border-left: 1px solid #009999;
          padding-left: 10px; }
          .ncl-quick-links.__basic .ncl-quick-links-content .ncl-quick-link-item:not(:first-child) {
            margin-top: 15px; } }

.ncl-payroll-calendar-links {
  display: -webkit-flex;
  display: flex;
  font-size: 15px; }
  .ncl-payroll-calendar-links.__default {
    margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-payroll-calendar-links {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  @media (min-width: 992px) {
    .ncl-payroll-calendar-links {
      -webkit-align-items: center;
              align-items: center; } }
  @media (min-width: 992px) {
    .ncl-payroll-calendar-links .ncl-quick-links-title {
      margin-right: 20px; } }
  @media (max-width: 991px) {
    .ncl-payroll-calendar-links .ncl-quick-links-title {
      margin-bottom: 10px; } }
  .ncl-payroll-calendar-links .ncl-quick-links-content {
    display: -webkit-flex;
    display: flex; }
  .ncl-payroll-calendar-links .ncl-quick-link-item-sm {
    height: 30px; }
    .ncl-payroll-calendar-links .ncl-quick-link-item-sm-icon {
      margin-right: 5px; }

.ncl-navbar-list {
  display: -webkit-flex;
  display: flex;
  list-style-type: none;
  display: flex;
  -webkit-align-items: stretch;
          align-items: stretch;
  margin-bottom: 0;
  padding-left: 0px; }
  .ncl-navbar-list .ncl-navbar-item {
    padding: 0px 12.5px;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    box-sizing: border-box;
    height: 50px;
    text-transform: uppercase; }
    .ncl-navbar-list .ncl-navbar-item:hover {
      cursor: pointer;
      background-color: #e9e9e9; }
    .ncl-navbar-list .ncl-navbar-item a {
      all: none;
      color: inherit; }

.ncl-nav-tab-container, .ncl-nav-tab-step-process-container {
  font-size: 15px; }
  @media (max-width: 991px) {
    .ncl-nav-tab-container ul.nav.nav-tabs, .ncl-nav-tab-step-process-container ul.nav.nav-tabs {
      margin-bottom: -10px;
      padding-bottom: 10px; } }
  .ncl-nav-tab-container ul.nav.nav-tabs li, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    float: left;
    min-height: 44px; }
    @media (min-width: 992px) {
      .ncl-nav-tab-container ul.nav.nav-tabs li, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li {
        min-height: 45px; }
        .ncl-nav-tab-container ul.nav.nav-tabs li:not(:first-child), .ncl-nav-tab-step-process-container ul.nav.nav-tabs li:not(:first-child) {
          margin-left: 5px; } }
    .ncl-nav-tab-container ul.nav.nav-tabs li a, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li a {
      border-radius: 0;
      color: #555;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      min-height: inherit;
      padding: 0px 15px;
      margin: 0;
      border: 1px solid #ccc; }
      @media (max-width: 991px) {
        .ncl-nav-tab-container ul.nav.nav-tabs li a, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li a {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          border-radius: 2.5px;
          border: 1px solid #999; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-nav-tab-container ul.nav.nav-tabs li, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li {
        width: 50%;
        margin-bottom: 10px; }
        .ncl-nav-tab-container ul.nav.nav-tabs li:nth-child(odd), .ncl-nav-tab-step-process-container ul.nav.nav-tabs li:nth-child(odd) {
          padding-right: 10px; } }
    @media (max-width: 767px) {
      .ncl-nav-tab-container ul.nav.nav-tabs li, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li {
        width: 100%;
        margin-bottom: 10px; } }
  .ncl-nav-tab-container ul.nav.nav-tabs li.active, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active {
    width: inherit;
    background-color: transparent !important;
    font-weight: 600; }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-nav-tab-container ul.nav.nav-tabs li.active, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active {
        width: 50%; } }
    @media (max-width: 767px) {
      .ncl-nav-tab-container ul.nav.nav-tabs li.active, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active {
        width: 100%; } }
    .ncl-nav-tab-container ul.nav.nav-tabs li.active > a, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active > a {
      border-top: 3px solid #339966;
      border-bottom-color: #fff; }
      @media (min-width: 320px) and (max-width: 991px) {
        .ncl-nav-tab-container ul.nav.nav-tabs li.active > a, .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active > a {
          border-top: none;
          border: 1px solid #00cccc;
          border-radius: 2.5px;
          background-color: #ccffff; } }
  @media (min-width: 992px) {
    .ncl-nav-tab-container .tab-content, .ncl-nav-tab-step-process-container .tab-content {
      padding: 30px 0px 20px 0px; } }
  @media (max-width: 991px) {
    .ncl-nav-tab-container .tab-content, .ncl-nav-tab-step-process-container .tab-content {
      padding: 30px 30px 30px 0px; } }
  .ncl-nav-tab-container .tab-pane, .ncl-nav-tab-step-process-container .tab-pane {
    width: inherit;
    background-color: inherit; }

.ncl-tab-content-wrapper {
  padding: 20px;
  position: relative; }
  .ncl-tab-content-wrapper .active {
    width: inherit;
    background: inherit; }

.ncl-nav-tab-step-process-container ul.nav.nav-tabs {
  border-bottom: none; }
  @media (max-width: 991px) {
    .ncl-nav-tab-step-process-container ul.nav.nav-tabs {
      margin-bottom: -10px;
      padding-bottom: 0px; } }
  @media (min-width: 992px) {
    .ncl-nav-tab-step-process-container ul.nav.nav-tabs li:not(:first-child) {
      margin-left: 30px; } }
  .ncl-nav-tab-step-process-container ul.nav.nav-tabs li a {
    border: none;
    border-bottom: 1px solid #999;
    font-weight: 600;
    color: #999;
    padding: 0px;
    padding-bottom: 10px; }
    @media (max-width: 991px) {
      .ncl-nav-tab-step-process-container ul.nav.nav-tabs li a {
        -webkit-justify-content: flex-start;
                justify-content: flex-start; } }
    .ncl-nav-tab-step-process-container ul.nav.nav-tabs li a:hover {
      background-color: #fff; }
  .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active a {
    border: none;
    border-bottom: 3px solid #339966;
    color: #333;
    padding: 0px;
    padding-bottom: 10px; }
    @media (max-width: 991px) {
      .ncl-nav-tab-step-process-container ul.nav.nav-tabs li.active a {
        background-color: inherit; } }

.ncl-nav-tab-step-process-container .tab-content {
  padding: 30px 30px 30px 0px; }

.ncl-nav-tab-container-scrollable .nav.nav-tabs {
  border-bottom: none; }

.ncl-nav-tab-container-scrollable .ncl-nav-tab-pills {
  display: -webkit-flex;
  display: flex;
  position: relative;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-nav-tab-container-scrollable .ncl-nav-tab-pills .ncl-scrollable-tab-arrow {
    z-index: 2;
    top: 18.5px;
    width: 30px;
    height: 30px;
    display: -webkit-inline-flex;
    display: inline-flex;
    background-color: #fff;
    box-shadow: rgba(162, 162, 162, 0.69) 1px 1px 4px;
    position: absolute;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    border-radius: 50%; }
    .ncl-nav-tab-container-scrollable .ncl-nav-tab-pills .ncl-scrollable-tab-arrow.__disabled {
      background-color: #e9e9e980;
      border: 1px solid #ccc;
      box-shadow: none; }

@media (min-width: 992px) {
  .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper {
    padding-left: 30px;
    margin-left: 15px;
    padding-right: 30px;
    margin-right: 15px; } }

@media (max-width: 991px) {
  .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper {
    overflow-x: auto; } }

.ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list {
  display: -webkit-flex;
  display: flex;
  list-style-type: none;
  margin-bottom: 0; }
  .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper {
    -webkit-flex-shrink: 0;
            flex-shrink: 0;
    position: relative;
    cursor: pointer;
    padding-right: 10px; }
    .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper:not(:first-child) div {
      margin-left: 10px; }
    .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper:last-child {
      padding-right: 60px; }
    .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper .ncl-tab-badge {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
      position: relative;
      right: -9px;
      top: 10px; }
    .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper .ncl-tab {
      border: 1px solid;
      padding: 5px 20px;
      border-radius: 20px;
      border-color: #009999;
      color: #009999;
      background-color: #fff;
      font-size: 14px;
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      text-align: center;
      -webkit-transition: background-color 0.2s;
      transition: background-color 0.2s; }
      @media (max-width: 991px) {
        .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper .ncl-tab {
          height: 44px; } }
      .ncl-nav-tab-container-scrollable .ncl-tab-list-wrapper .ncl-tab-list .ncl-tab-wrapper .ncl-tab.active {
        background-color: #009999;
        color: #fff;
        border-color: #009999; }

.ncl-nav-tab-container-scrollable .tab-content {
  margin-top: 20px; }
  .ncl-nav-tab-container-scrollable .tab-content .tab-pane.active {
    width: inherit;
    background-color: inherit; }

.ncl-tab-container {
  font-size: 15px; }
  @media (max-width: 991px) {
    .ncl-tab-container ul.nav.nav-tabs {
      border-bottom: 1px solid #ccc;
      margin-bottom: -10px;
      padding-bottom: 10px; } }
  .ncl-tab-container ul.nav.nav-tabs li {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    float: left;
    min-height: 44px; }
    @media (min-width: 992px) {
      .ncl-tab-container ul.nav.nav-tabs li {
        min-height: 45px; }
        .ncl-tab-container ul.nav.nav-tabs li:not(:first-child) {
          margin-left: 5px; } }
    .ncl-tab-container ul.nav.nav-tabs li a {
      border-radius: 0;
      color: #555;
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      min-height: inherit;
      padding: 0px 15px;
      margin: 0;
      border: 1px solid #ccc; }
      @media (max-width: 991px) {
        .ncl-tab-container ul.nav.nav-tabs li a {
          -webkit-flex-grow: 1;
                  flex-grow: 1;
          border-radius: 2.5px;
          border: 1px solid #999; } }
    @media (min-width: 768px) and (max-width: 991px) {
      .ncl-tab-container ul.nav.nav-tabs li {
        width: 50%;
        margin-bottom: 10px; }
        .ncl-tab-container ul.nav.nav-tabs li:nth-child(odd) {
          padding-right: 10px; }
        .ncl-tab-container ul.nav.nav-tabs li:nth-child(even) {
          padding-left: 5px; } }
    @media (max-width: 767px) {
      .ncl-tab-container ul.nav.nav-tabs li {
        width: 100%;
        margin-bottom: 10px; } }
  .ncl-tab-container ul.nav.nav-tabs li.active {
    width: inherit;
    background-color: transparent !important;
    font-weight: 600; }
    @media (max-width: 991px) {
      .ncl-tab-container ul.nav.nav-tabs li.active {
        width: 50%; } }
    @media (max-width: 767px) {
      .ncl-tab-container ul.nav.nav-tabs li.active {
        width: 100%; } }
    .ncl-tab-container ul.nav.nav-tabs li.active > a {
      border-top: 3px solid #339966;
      border-bottom-color: #fff; }
      @media (min-width: 320px) and (max-width: 991px) {
        .ncl-tab-container ul.nav.nav-tabs li.active > a {
          border-top: none;
          border: 1px solid #00cccc;
          border-radius: 2.5px;
          background-color: #ccffff; } }
  @media (min-width: 992px) {
    .ncl-tab-container .tab-content {
      padding: 30px;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc; } }
  @media (max-width: 991px) {
    .ncl-tab-container .tab-content {
      padding: 30px 30px 30px 0px; } }
  .ncl-tab-container .tab-pane {
    width: inherit;
    background-color: inherit; }

.ncl-modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000; }
  .ncl-modal-backdrop.fade {
    opacity: 0; }
  .ncl-modal-backdrop.in {
    opacity: 0.5; }

.ncl-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0; }
  @media (max-width: 991px) {
    .ncl-modal {
      overflow-y: auto; } }
  .ncl-modal .ncl-modal-title {
    font-size: 20px;
    margin-bottom: 15px;
    font-weight: 600; }
    @media (max-width: 767px) {
      .ncl-modal .ncl-modal-title {
        font-size: 18px; } }
  .ncl-modal .ncl-modal-dialog {
    position: relative;
    width: auto;
    padding: 10px;
    -webkit-justify-content: center;
            justify-content: center; }
    @media (min-width: 992px) {
      .ncl-modal .ncl-modal-dialog {
        width: 600px;
        margin: 30px auto; } }
    @media (max-width: 991px) {
      .ncl-modal .ncl-modal-dialog {
        min-height: 100vh;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center; } }
    @media (min-width: 992px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-cancel-modal {
        width: 675px; } }
    @media (max-width: 991px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-cancel-modal {
        width: auto; } }
    @media (min-width: 992px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal {
        width: 958px; } }
    @media (max-width: 991px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal {
        width: 100%;
        margin: 0; }
        .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-modal-content {
          -webkit-flex-basis: 100%;
                  flex-basis: 100%;
          min-height: inherit;
          overflow-y: auto; } }
    @media (max-width: 767px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal {
        padding: 0; } }
    .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-add-modal-title {
      font-size: 20px;
      margin-bottom: 30px;
      font-weight: 600; }
      @media (max-width: 767px) {
        .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-add-modal-title {
          font-size: 18px; } }
    .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content {
      padding: 0;
      padding-top: 20px; }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion:not(:first-child) {
        margin-bottom: 0; }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion:first-child {
        margin-top: 10px; }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion:last-child {
        margin-bottom: 20px; }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion .ncl-collapsible-content {
        padding-bottom: 15px; }
        @media (max-width: 767px) {
          .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion .ncl-collapsible-content {
            padding-left: 0;
            padding-right: 0; } }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-add-modal .ncl-nav-tab-container .tab-content .ncl-accordion-group .ncl-accordion:last-child .collapse {
        -webkit-transition: margin-bottom;
        transition: margin-bottom; }
    @media (max-width: 991px) {
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal {
        width: 100%;
        height: 100vh;
        margin: 0;
        padding: 0; } }
    .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content {
      height: inherit;
      padding: 0; }
      @media (max-width: 991px) {
        .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content {
          -webkit-flex-basis: 100%;
                  flex-basis: 100%; } }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header {
        height: 45px;
        border-bottom: 1px solid #ccc;
        display: -webkit-flex;
        display: flex;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: center;
                justify-content: center; }
        @media (min-width: 768px) and (max-width: 991px) {
          .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header {
            padding-left: 20px;
            padding-right: 20px; } }
        .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button {
          font-size: 18px;
          position: absolute;
          top: inherit;
          left: 15px;
          z-index: 1; }
          @media (min-width: 768px) and (max-width: 991px) {
            .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button {
              left: 20px; } }
          @media (max-width: 767px) {
            .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button {
              left: 10px; } }
          .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button:hover {
            cursor: pointer; }
          .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button i {
            cursor: pointer;
            margin-top: 0px;
            margin-right: 0px; }
      .ncl-modal .ncl-modal-dialog.ncl-modal-ncl-mobile-modal .ncl-modal-content .ncl-modal-footer {
        padding: 20px;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #fff;
        box-shadow: 0 -2px 20px 2px #e9e9e9; }
    .ncl-modal .ncl-modal-dialog .ncl-modal-content {
      position: relative;
      background-color: #fff;
      -webkit-background-clip: padding-box;
      padding: 30px 30px 40px 30px;
      background-clip: padding-box;
      border: 1px solid #999;
      border-radius: 0px;
      box-shadow: none;
      outline: 0; }
      @media (max-width: 767px) {
        .ncl-modal .ncl-modal-dialog .ncl-modal-content {
          padding: 20px 10px 30px 10px; } }
      .ncl-modal .ncl-modal-dialog .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button {
        font-size: 20px;
        position: absolute;
        top: 30px;
        right: 30px;
        z-index: 1; }
        .ncl-modal .ncl-modal-dialog .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button:hover {
          cursor: pointer; }
        @media (max-width: 767px) {
          .ncl-modal .ncl-modal-dialog .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button {
            font-size: 18px !important;
            top: 20px;
            right: 10px; } }
        .ncl-modal .ncl-modal-dialog .ncl-modal-content .ncl-modal-header .ncl-modal-header-close-button i {
          cursor: pointer;
          margin-top: 0px;
          margin-right: 0px; }

.ncl-full-screen-modal .ncl-full-screen-modal-header {
  border-bottom: 1px solid #ccc;
  height: 44px;
  padding-bottom: 15px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  padding: 10px;
  position: relative; }
  .ncl-full-screen-modal .ncl-full-screen-modal-header .ncl-close-icon {
    font-size: 18px;
    position: absolute;
    left: 20px; }
    @media (max-width: 767px) {
      .ncl-full-screen-modal .ncl-full-screen-modal-header .ncl-close-icon {
        left: 10px; } }
  .ncl-full-screen-modal .ncl-full-screen-modal-header .ncl-options-title {
    font-size: 16px;
    font-weight: 400;
    text-align: center; }
  .ncl-full-screen-modal .ncl-full-screen-modal-header .ncl-options-clear-link {
    position: absolute;
    right: 20px;
    font-size: 16px; }
    @media (max-width: 767px) {
      .ncl-full-screen-modal .ncl-full-screen-modal-header .ncl-options-clear-link {
        right: 10px; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .ncl-full-screen-modal .ncl-full-screen-modal-header {
      padding-left: 20px;
      padding-right: 20px; } }

.ncl-full-screen-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  min-height: 100vh;
  z-index: 2000; }
  .ncl-full-screen-modal .ncl-full-screen-modal-body {
    padding: 20px; }
    @media (max-width: 767px) {
      .ncl-full-screen-modal .ncl-full-screen-modal-body {
        padding: 20px 10px; } }
    .ncl-full-screen-modal .ncl-full-screen-modal-body.__remove-padding {
      padding: 0;
      max-height: 100vh;
      overflow-y: auto; }
  .ncl-full-screen-modal .ncl-full-screen-modal-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px 10px;
    background-color: #fff;
    box-shadow: 0 -2px 20px 2px #e9e9e9; }
    @media (min-width: 768px) {
      .ncl-full-screen-modal .ncl-full-screen-modal-footer {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center; } }
    @media (max-width: 991px) {
      .ncl-full-screen-modal .ncl-full-screen-modal-footer .ncl-button-toolbar._column {
        -webkit-flex-direction: column;
                flex-direction: column; }
        .ncl-full-screen-modal .ncl-full-screen-modal-footer .ncl-button-toolbar._column > .ncl-button-toolbar-link-group:not(:first-child) {
          margin-top: 20px;
          margin-left: 0px; }
      .ncl-full-screen-modal .ncl-full-screen-modal-footer .ncl-button-toolbar-link-group {
        -webkit-justify-content: center;
                justify-content: center; }
        .ncl-full-screen-modal .ncl-full-screen-modal-footer .ncl-button-toolbar-link-group .ncl-button {
          margin-left: 0; } }

.ncl-overlay-outline-container .ncl-overlay-button {
  height: 25px;
  font-size: 15px;
  min-height: 25px;
  padding: 0 15px; }
  .ncl-overlay-outline-container .ncl-overlay-button svg {
    font-size: 14px; }

.ncl-overlay-outline-container .ncl-overlay-outline {
  border: 1px solid #009999;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  margin-left: -60px;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 3px;
  z-index: 1060; }

.ncl-outline-overlay-options .ncl-outline-overlay-option {
  padding: 10px 20px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #009999; }
  @media (max-width: 767px) {
    .ncl-outline-overlay-options .ncl-outline-overlay-option {
      font-size: 18px;
      padding: 10px 20px 10px 0px; } }
  .ncl-outline-overlay-options .ncl-outline-overlay-option:hover {
    cursor: pointer;
    background: #efefef; }
  .ncl-outline-overlay-options .ncl-outline-overlay-option svg {
    margin-right: 10px; }

.ncl-toaster, .ncl-toaster-sticky {
  font-size: 15px;
  padding: 5px 15px;
  font-weight: 400;
  min-height: 35px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  display: -webkit-flex;
  display: flex;
  position: relative;
  text-align: center; }
  .ncl-toaster.__has-dismiss-icon, .__has-dismiss-icon.ncl-toaster-sticky {
    padding-right: 40.31px; }
  @media (min-width: 992px) {
    .ncl-toaster.__default-toaster, .__default-toaster.ncl-toaster-sticky {
      margin-bottom: 20px; } }
  @media (max-width: 991px) {
    .ncl-toaster.__default-toaster, .__default-toaster.ncl-toaster-sticky {
      margin-bottom: 20px; } }
  .ncl-toaster .ncl-toaster-content, .ncl-toaster-sticky .ncl-toaster-content {
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center; }
  .ncl-toaster-dismiss-icon {
    position: absolute;
    right: 15px;
    top: 0;
    bottom: 0;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center; }
    .ncl-toaster-dismiss-icon:hover {
      cursor: pointer; }
      @media (max-width: 991px) {
        .ncl-toaster-dismiss-icon:hover {
          padding-bottom: 15px; } }
  .ncl-toaster.toaster-info, .toaster-info.ncl-toaster-sticky {
    background-color: #eef6e7;
    color: #555;
    font-weight: 600; }
    .ncl-toaster.toaster-info .svg-inline--fa, .toaster-info.ncl-toaster-sticky .svg-inline--fa {
      color: #555; }
  .ncl-toaster.toaster-success, .toaster-success.ncl-toaster-sticky {
    background-color: #ccffcc;
    border: 1px solid #26a977;
    color: #339966; }
    .ncl-toaster.toaster-success .svg-inline--fa, .toaster-success.ncl-toaster-sticky .svg-inline--fa {
      color: #339966; }
  .ncl-toaster.toaster-warning, .toaster-warning.ncl-toaster-sticky {
    background-color: #ffffcc;
    border: 1px solid #cccc00;
    color: #8a6d3b; }
    .ncl-toaster.toaster-warning .svg-inline--fa, .toaster-warning.ncl-toaster-sticky .svg-inline--fa {
      color: #8a6d3b; }
  .ncl-toaster.toaster-danger, .toaster-danger.ncl-toaster-sticky, .ncl-toaster.toaster-error, .toaster-error.ncl-toaster-sticky {
    color: #fff;
    background-color: #fb6b5b;
    border: 1px solid #990000; }
    .ncl-toaster.toaster-danger .svg-inline--fa, .toaster-danger.ncl-toaster-sticky .svg-inline--fa, .ncl-toaster.toaster-error .svg-inline--fa, .toaster-error.ncl-toaster-sticky .svg-inline--fa {
      color: #fff; }
  .ncl-toaster.toaster-generic, .toaster-generic.ncl-toaster-sticky {
    background-color: #d2ecec;
    color: #025471;
    border: 1px solid #0077a1; }
    .ncl-toaster.toaster-generic .svg-inline--fa, .toaster-generic.ncl-toaster-sticky .svg-inline--fa {
      color: #025471; }

.ncl-toaster-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 30px;
  z-index: 2;
  clear: both;
  box-shadow: 1px 2px 7px 0 rgba(0, 0, 0, 0.25); }

/**
TODO : Check if the below style can be removed and moved to list page container
*/

.ncl-toaster-with-list .display-toggle-content .display-toggle-content-item {
  padding-top: 0; }
  .ncl-toaster-with-list .display-toggle-content .display-toggle-content-item:not(:first-child) {
    padding-top: 20px; }

.ncl-user-icon-horizontal-details .ncl-user-icon-employee-name, .ncl-user-icon-horizontal-details-heavy .ncl-user-icon-employee-name {
  color: #555;
  font-size: 18px;
  font-weight: 600; }
  @media (min-width: 320px) and (max-width: 991px) {
    .ncl-user-icon-horizontal-details .ncl-user-icon-employee-name, .ncl-user-icon-horizontal-details-heavy .ncl-user-icon-employee-name {
      font-size: 20px !important;
      width: auto;
      text-transform: capitalize; } }

.ncl-user-icon-horizontal-details .ncl-user-horizontal-details-icon-image img, .ncl-user-icon-horizontal-details-heavy .ncl-user-horizontal-details-icon-image img {
  border-radius: 50%;
  margin-right: 15px; }

.ncl-user-icon-horizontal-details .ncl-user-hire-date, .ncl-user-icon-horizontal-details-heavy .ncl-user-hire-date {
  font-weight: 400;
  color: #555;
  margin-top: 5px;
  font-size: 15px;
  text-transform: capitalize; }
  @media (min-width: 320px) and (max-width: 991px) {
    .ncl-user-icon-horizontal-details .ncl-user-hire-date, .ncl-user-icon-horizontal-details-heavy .ncl-user-hire-date {
      margin-top: 10px; } }

.ncl-user-icon-horizontal-details-heavy .ncl-user-horizontal-details-icon-image img {
  border-radius: 50%;
  margin-right: 20px; }

.ncl-user-icon-horizontal-details-heavy .ncl-user-icon-employee-name {
  color: #009999;
  font-weight: 400; }

.ncl-user-icon-horizontal-details-heavy .ncl-user-info {
  margin-top: 10px; }
  @media (min-width: 992px) {
    .ncl-user-icon-horizontal-details-heavy .ncl-user-info {
      margin-top: 2px; } }

.ncl-user-icon-horizontal-details-heavy .ncl-user-designation {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  padding-right: 10px;
  margin-right: 10px;
  border-right: 1px solid #ccc; }

.ncl-user-icon-horizontal-details-heavy .ncl-user-id {
  font-size: 14px;
  color: #999; }

.ncl-user-icon-horizontal-details-heavy .ncl-user-email {
  font-size: 14px;
  color: #999; }

.ncl-user-icon-horizontal-details-heavy .ncl-user-edit-icon {
  margin-left: 10px;
  color: #7b7b7b;
  font-size: 15px;
  cursor: pointer; }
  @media (max-width: 991px) {
    .ncl-user-icon-horizontal-details-heavy .ncl-user-edit-icon {
      font-size: 18px; } }

.ncl-user-icon-horizontal-details-heavy.__editable .ncl-user-icon-employee-name {
  color: #555; }

.ncl-user-icon-horizontal {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-user-icon-horizontal .ncl-user-icon-name {
    color: #555;
    font-size: 15px;
    font-weight: 400;
    margin-left: 15px; }
    @media (max-width: 991px) {
      .ncl-user-icon-horizontal .ncl-user-icon-name {
        margin-left: 10px;
        font-size: 20px; } }
  .ncl-user-icon-horizontal .ncl-user-icon-image img {
    border-radius: 50%;
    height: 40px;
    width: 40px; }
    @media (max-width: 991px) {
      .ncl-user-icon-horizontal .ncl-user-icon-image img {
        height: 30px;
        width: 30px; } }

.ncl-image-only img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: #66cc66 1px; }
  @media (max-width: 991px) {
    .ncl-image-only img {
      width: 40px;
      height: 40px; } }

.ncl-user-icon-vertical {
  border: #66cc66;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-user-icon-vertical .img {
    display: table-cell;
    border-radius: 50%; }
  .ncl-user-icon-vertical .ncl-user-vertical-icon-employee-name {
    color: #555;
    display: -webkit-flex;
    display: flex;
    font-size: 15px;
    font-weight: 600;
    vertical-align: middle;
    text-transform: capitalize;
    margin-bottom: 7px;
    margin-top: 15px; }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-user-icon-vertical .ncl-user-vertical-icon-employee-name {
        font-size: 20px !important;
        text-transform: capitalize; } }
  .ncl-user-icon-vertical .ncl-user-position-title {
    font-weight: 400;
    color: #555;
    font-size: 15px;
    text-transform: capitalize; }

.ncl-user-icon-horizontal-right {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center; }
  .ncl-user-icon-horizontal-right .ncl-user-icon-name {
    color: #555;
    font-size: 15px;
    font-weight: 400; }
    @media (min-width: 320px) and (max-width: 991px) {
      .ncl-user-icon-horizontal-right .ncl-user-icon-name {
        font-size: 20px; } }
  .ncl-user-icon-horizontal-right .ncl-user-icon-image {
    margin-left: 15px; }
    @media (max-width: 991px) {
      .ncl-user-icon-horizontal-right .ncl-user-icon-image {
        margin-left: 10px; } }
    .ncl-user-icon-horizontal-right .ncl-user-icon-image img {
      border-radius: 50%;
      height: 40px;
      width: 40px; }
      @media (max-width: 991px) {
        .ncl-user-icon-horizontal-right .ncl-user-icon-image img {
          height: 30px;
          width: 30px; } }

.tree-list {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #555;
  list-style-type: none; }
  .tree-list-item {
    padding-left: 35px; }
  .tree-list .indent-container {
    float: left; }
    .tree-list .indent-container .indent {
      float: left;
      width: 16px;
      border-bottom: 1px dashed #999;
      height: 8px;
      margin-right: 3px; }
      .tree-list .indent-container .indent:first-child {
        border-left: 1px dashed #999; }

.ncl-employee-header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  @media (max-width: 991px) {
    .ncl-employee-header {
      border-top: 1px solid #ccc;
      border-bottom: 1px solid #ccc; } }
  .ncl-employee-header.__bottom-hr {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px; }
    @media (min-width: 992px) {
      .ncl-employee-header.__bottom-hr {
        padding-bottom: 20px; } }
  .ncl-employee-header .ncl-employee-header-title-container {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    font-size: 25px;
    font-weight: 600;
    color: #555;
    font-size: 16px; }
    @media (max-width: 991px) {
      .ncl-employee-header .ncl-employee-header-title-container {
        font-size: 20px; } }
    @media (max-width: 767px) {
      .ncl-employee-header .ncl-employee-header-title-container {
        font-size: 16px; } }
    .ncl-employee-header .ncl-employee-header-title-container .ncl-employee-header-hamburger {
      background-color: #99cc99;
      color: #555;
      display: -webkit-inline-flex;
      display: inline-flex;
      -webkit-justify-content: center;
              justify-content: center;
      -webkit-align-items: center;
              align-items: center;
      font-size: 15px;
      width: 55px;
      height: 55px;
      margin-right: 15px; }
      @media (max-width: 991px) {
        .ncl-employee-header .ncl-employee-header-title-container .ncl-employee-header-hamburger {
          font-size: 18px; } }

.ncl-employee-description-header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .ncl-employee-description-header {
      -webkit-flex-direction: column;
              flex-direction: column; } }
  .ncl-employee-description-header .ncl-employee-description {
    -webkit-flex-basis: 65%;
            flex-basis: 65%; }
  @media (max-width: 991px) {
    .ncl-employee-description-header .ncl-employee-description-header-button-group {
      padding-top: 20px; } }

.ncl-profile-snapshot {
  display: -webkit-flex;
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
  overflow-y: auto; }
  @media (max-width: 767px) {
    .ncl-profile-snapshot {
      padding-top: 20px;
      padding-bottom: 20px; } }
  .ncl-profile-snapshot .ncl-profile-snapshot-item-wrapper:not(:first-child) {
    padding-left: 80px; }
    @media (max-width: 767px) {
      .ncl-profile-snapshot .ncl-profile-snapshot-item-wrapper:not(:first-child) {
        padding-left: 35px; } }
  .ncl-profile-snapshot .ncl-profile-snapshot-item {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-shrink: 0;
            flex-shrink: 0; }
    .ncl-profile-snapshot .ncl-profile-snapshot-item .ncl-profile-snapshot-content {
      margin-left: 15px; }
      .ncl-profile-snapshot .ncl-profile-snapshot-item .ncl-profile-snapshot-content .ncl-profile-snapshot-title {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 11px;
        margin-bottom: 2px; }
      .ncl-profile-snapshot .ncl-profile-snapshot-item .ncl-profile-snapshot-content .ncl-profile-snapshot-value {
        font-size: 18px; }

.react-toggle {
  touch-action: pan-x;

  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-tap-highlight-color: transparent;
}

.react-toggle-screenreader-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.react-toggle--disabled {
  cursor: not-allowed;
  opacity: 0.5;
  -webkit-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.react-toggle-track {
  width: 50px;
  height: 24px;
  padding: 0;
  border-radius: 30px;
  background-color: #4D4D4D;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #000000;
}

.react-toggle--checked .react-toggle-track {
  background-color: #19AB27;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #128D15;
}

.react-toggle-track-check {
  position: absolute;
  width: 14px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  left: 8px;
  opacity: 0;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-check {
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle-track-x {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 0px;
  bottom: 0px;
  margin-top: auto;
  margin-bottom: auto;
  line-height: 0;
  right: 10px;
  opacity: 1;
  -webkit-transition: opacity 0.25s ease;
  transition: opacity 0.25s ease;
}

.react-toggle--checked .react-toggle-track-x {
  opacity: 0;
}

.react-toggle-thumb {
  -webkit-transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  position: absolute;
  top: 1px;
  left: 1px;
  width: 22px;
  height: 22px;
  border: 1px solid #4D4D4D;
  border-radius: 50%;
  background-color: #FAFAFA;
  box-sizing: border-box;

  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.react-toggle--checked .react-toggle-thumb {
  left: 27px;
  border-color: #19AB27;
}

.react-toggle--focus .react-toggle-thumb {
  box-shadow: 0px 0px 2px 3px #0099E0;
}

.react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
  box-shadow: 0px 0px 5px 5px #0099E0;
}

.rc-drawer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.rc-drawer-sidebar {
  z-index: 2;
  position: absolute;
  transition: -webkit-transform 0.3s ease-out;
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  will-change: transform;
  overflow-y: auto;
}
.rc-drawer-draghandle {
  z-index: 1;
  position: absolute;
  background-color: rgba(50, 50, 50, 0.1);
}
.rc-drawer-overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s ease-out;
  transition: opacity 0.3s ease-out;
  background-color: rgba(0, 0, 0, 0.3);
}
.rc-drawer-content {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  -webkit-transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out;
  transition: left 0.3s ease-out, right 0.3s ease-out, top 0.3s ease-out, bottom 0.3s ease-out;
}
.rc-drawer.rc-drawer-left .rc-drawer-sidebar,
.rc-drawer.rc-drawer-right .rc-drawer-sidebar,
.rc-drawer.rc-drawer-left .rc-drawer-draghandle,
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  top: 0;
  bottom: 0;
}
.rc-drawer.rc-drawer-left .rc-drawer-draghandle,
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  width: 20px;
  height: 100%;
}
.rc-drawer.rc-drawer-top .rc-drawer-sidebar,
.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar,
.rc-drawer.rc-drawer-top .rc-drawer-draghandle,
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  left: 0;
  right: 0;
}
.rc-drawer.rc-drawer-top .rc-drawer-draghandle,
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  width: 100%;
  height: 20px;
}
.rc-drawer.rc-drawer-left .rc-drawer-sidebar {
  left: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-left .rc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-left .rc-drawer-draghandle {
  left: 0;
}
.rc-drawer.rc-drawer-right .rc-drawer-sidebar {
  right: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-right .rc-drawer-sidebar {
  box-shadow: -2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-right .rc-drawer-draghandle {
  right: 0;
}
.rc-drawer.rc-drawer-top .rc-drawer-sidebar {
  top: 0;
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-top .rc-drawer-sidebar {
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-top .rc-drawer-draghandle {
  top: 0;
}
.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar {
  bottom: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}
.rc-drawer-open.rc-drawer.rc-drawer-bottom .rc-drawer-sidebar {
  box-shadow: 2px -2px 4px rgba(0, 0, 0, 0.15);
}
.rc-drawer.rc-drawer-bottom .rc-drawer-draghandle {
  bottom: 0;
}

